import { setCompany } from "app/store/slices/global";
import { Company } from "core/company/entities/Company";
import { getCompanyByCode } from "core/company/repository/companyRepo";
import { useCallback } from "react";
import { getCookie } from "utils/cookie";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

export interface UseCompany {
  get: () => void;
  config: (key: string) => any;
  data?: Company;
}

export const useCompany = (): UseCompany => {
  const company = useAppSelector((state) => state.global.company);
  const dispatch = useAppDispatch();
  let loading = false;

  const byCode = () => {
    if (["loading"].includes(company.status)) return;
    if (loading) return;
    loading = true;

    dispatch(setCompany({ ...company, status: "loading" }));

    const code = getCookie("companyId");

    if (!code) {
      dispatch(
        setCompany({
          ...company,
          status: "failed",
          error: "company code is null",
        })
      );
      return;
    }

    getCompanyByCode(code)
      .then((response) => {
        dispatch(
          setCompany({
            ...company,
            status: "succeeded",
            data: response,
          })
        );
      })
      .catch((error) => {
        dispatch(setCompany({ ...company, status: "failed", error }));
      })
      .finally(() => {
        loading = false;
      });
  };

  const config = useCallback(
    (key: string) => {
      return company.data?.configs?.[key]?.value;
    },
    [company.data]
  );

  return {
    get: byCode,
    config,
    data: company.data,
  };
};
