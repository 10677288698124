import PlusIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { Avatar, Chip, Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons } from "app/i18n/types";
import StationService from "app/service/station.service";
import { userInfo } from "app/utils/jwt";
import { FoodologyCountry } from "core/country/entities/Country";
import { Station } from "core/productions/entities/Station";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import { getInitialsWords } from "utils/general";
import {
  LocalStorageItemExtra,
  removeLocalStorage,
  setLocalStorage,
} from "utils/localStorage";
import SelectStationContent from "./SelectStation/SelectStationContent";
import SelectStationHeader from "./SelectStation/SelectStationHeader";

interface Props {
  onUpdate: (station: Station | null) => void;
  previousSelection: Station | null;
}

const StationFilter = (props: Props): React.ReactElement => {
  const { onUpdate, previousSelection } = props;
  const { t } = useTranslation();

  const [stations, setStations] = useState<Station[]>([]);

  const [openSelectStation, setOpenSelectStation] = useState<boolean>(false);

  const authorization = useAppSelector(
    (state) => state.session.data?.authorization
  );

  useMount(() => {
    if (!authorization) return;
    const info = userInfo(authorization);
    StationService.byCountry(info?.country as FoodologyCountry)
      .then((stations) => setStations(stations))
      .catch(() => setStations([]));
  });

  return (
    <>
      {previousSelection && (
        <Chip
          key={previousSelection.id}
          avatar={
            <Avatar src={previousSelection.logoUrl}>
              {getInitialsWords(previousSelection.name)}
            </Avatar>
          }
          onClick={() => setOpenSelectStation(true)}
          onDelete={() => {
            removeLocalStorage(LocalStorageItemExtra.productionStation);
            onUpdate(null);
          }}
          label={previousSelection.name}
          variant="outlined"
          sx={{
            fontSize: 16,
            color: "#232323",
            backgroundColor: "#ffffff",
          }}
          deleteIcon={<ClearIcon sx={{ color: "#000000", fill: "#000000" }} />}
        />
      )}

      {!previousSelection && (
        <Button
          variant="outlined"
          size="small"
          startIcon={<PlusIcon />}
          onClick={() => setOpenSelectStation(true)}
        >
          {t(commons.SELECT_STATION)}
        </Button>
      )}
      <Dialog
        open={!!openSelectStation}
        fullWidth
        PaperProps={{ sx: { background: "#FEFBFB" } }}
        maxWidth="md"
        onClose={() => setOpenSelectStation(false)}
      >
        <SelectStationHeader onClose={() => setOpenSelectStation(false)} />
        <SelectStationContent
          loading={false}
          onFilter={(station: Station | null) => {
            if (station) {
              setLocalStorage(LocalStorageItemExtra.productionStation, station);
            } else {
              removeLocalStorage(LocalStorageItemExtra.productionStation);
            }
            onUpdate(station);
          }}
          onClose={() => setOpenSelectStation(false)}
          stations={stations}
          previousSelection={previousSelection}
        ></SelectStationContent>
      </Dialog>
    </>
  );
};

export default StationFilter;
