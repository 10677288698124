import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { productionManagement } from "app/i18n/types";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
};
const SelectStationHeader = ({ onClose }: Props): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <DialogTitle
      component="div"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pt: 3,
        pb: "10px",
        flexDirection: "row",
      }}
    >
      <Typography
        variant="h6"
        fontSize="16px"
        lineHeight="20px"
        fontWeight={600}
        color="#011A5B"
      >
        {t(productionManagement.SELECT_STATION)}
      </Typography>
      <CloseIcon
        sx={{
          color: "#011A5B",
          cursor: "pointer",
          transition: "opacity .3s",
          zIndex: 10,
          opacity: 1,
          "&:hover": {
            opacity: 0.5,
          },
        }}
        aria-label="close"
        onClick={onClose}
      />
    </DialogTitle>
  );
};

export default SelectStationHeader;
