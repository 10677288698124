import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { Box, Chip, CircularProgress, Grid, Tab, Tabs } from "@mui/material";
import AdjustmentErrors from "app/components/BusinessCentralIntegration/AdjustmentErrors";
import BatchErrors from "app/components/BusinessCentralIntegration/BatchErrors";
import ConciliationErrors from "app/components/BusinessCentralIntegration/ConciliationErrors";
import ProductErrors from "app/components/BusinessCentralIntegration/ProductErrors";
import PurchaseOrderErrors from "app/components/BusinessCentralIntegration/PurchaseOrderErrors";
import PurchaseOrderModifyErrors from "app/components/BusinessCentralIntegration/PurchaseOrderModifyErrors";
import PurchaseOrderReceiptErrors from "app/components/BusinessCentralIntegration/PurchaseOrderReceiptErrors";
import SaleErrors from "app/components/BusinessCentralIntegration/SaleErrors";
import TransferOrderErrors from "app/components/BusinessCentralIntegration/TransferOrderErrors";
import TransferReceiptErrors from "app/components/BusinessCentralIntegration/TransferReceiptErrors";
import VendorErrors from "app/components/BusinessCentralIntegration/VendorErrors";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader/PageHeader";
import { TabPanel } from "app/components/PageTabs";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import {
  bcIntegrations,
  commons,
  kitchenInventoryManagement,
} from "app/i18n/types";
import {
  allIntegrationErrors,
  bcExecuteUrl,
} from "app/store/slices/bcIntegration/thunks";
import { SummaryResponse } from "core/BusinessCentralIntegration/entities/BusinessCentralIntegration";
import React, { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

interface Props {}

function indexProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function newTab(index: number, label: string, quantity: number) {
  return (
    <Tab
      icon={<Chip label={quantity} />}
      iconPosition="start"
      sx={{ textAlign: "left", alignSelf: "flex-start" }}
      label={label}
      {...indexProps(index)}
    />
  );
}

const BusinessCentralIntegration: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingExecuteUrl, setLoadingExecuteUrl] = useState<boolean>(false);
  const [summaryResponse, setSummaryResponse] =
    useState<SummaryResponse | null>(null);
  const urls = [
    "product-journal/resend-adjustments",
    "product-journal/post-batches",
    "purchase-order/pending-orders/send",
    "purchase-order/process-pending",
    "transfer/process-errors",
    "transfer/reception/process-errors",
    "sales/errors",
    "purchase-order/pending-conciliation/send",
    "purchase-order/pending-modify/send",
    "vendor/pending/send",
    "product/pending/send",
  ];
  const archiveUrls = [
    "product-journal/archive-adjustments/{id}",
    "product-journal/archive-batch/{id}",
    "purchase-order/archive-order/{id}",
    "purchase-order/archive-receipt/{id}",
    "transfer/archive-process/{id}",
    "transfer/archive-reception/{id}",
    "sales/archive/{id}",
    "purchase-order/archive-conciliation/{id}",
    "purchase-order/archive-modify/{id}",
    "vendor/archive/{id}",
    "product/archive/{id}",
  ];

  const getIntegrationErrors = () => {
    setLoading(true);
    dispatch(allIntegrationErrors())
      .then((errorSummary) => {
        setSummaryResponse(errorSummary.payload as SummaryResponse);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const executeUrl = useCallback(
    async (list: string[], id?: string) => {
      setLoadingExecuteUrl(true);
      let url = list[value];
      if (id) {
        url = url.replace("{id}", id);
      }
      await dispatch(bcExecuteUrl({ url }));
      setLoadingExecuteUrl(false);
      getIntegrationErrors();
    },
    [value]
  );

  const retry = async () => {
    await executeUrl(urls);
  };

  const archive = async (id: string) => {
    await executeUrl(archiveUrls, id);
  };

  useMount(() => {
    getIntegrationErrors();
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.BC_INTEGRATION_MODULE)}
        rightArea={[
          {
            children: t(commons.UPDATE),
            onClick: getIntegrationErrors,
            variant: "outlined",
            size: "large",
            disabled: loading || loadingExecuteUrl,
            startIcon: loading ? (
              <CircularProgress sx={{ paddingRight: "5px" }} size={20} />
            ) : (
              <RestartAltOutlinedIcon
                sx={{ height: "18", paddingRight: "5px" }}
              />
            ),
          },
          {
            children: t(commons.RETRY),
            onClick: retry,
            variant: "contained",
            size: "large",
            disabled: loading || loadingExecuteUrl,
            startIcon: loadingExecuteUrl ? (
              <CircularProgress sx={{ paddingRight: "5px" }} size={20} />
            ) : (
              <FactCheckOutlinedIcon
                sx={{ height: "18", paddingRight: "5px" }}
              />
            ),
          },
        ]}
      />

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={3}>
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            sx={{
              borderRight: 1,
              borderColor: "divider",
            }}
          >
            {newTab(
              0,
              t(bcIntegrations.INVENTORY_ADJUSTMENTS),
              summaryResponse?.journals.length ?? 0
            )}

            {newTab(
              1,
              t(bcIntegrations.BATCHES),
              summaryResponse?.batches.length ?? 0
            )}

            {newTab(
              2,
              t(bcIntegrations.PURCHASE_ORDERS),
              summaryResponse?.purchaseOrders.length ?? 0
            )}

            {newTab(
              3,
              t(bcIntegrations.PURCHASE_ORDERS_RECEIPT),
              summaryResponse?.purchaseOrderReceipts.length ?? 0
            )}
            {newTab(
              4,
              t(bcIntegrations.TRANSFER),
              summaryResponse?.transfers.length ?? 0
            )}
            {newTab(
              5,
              t(bcIntegrations.TRANSFER_RECEIPT),
              summaryResponse?.transferOrderReceipts.length ?? 0
            )}
            {newTab(
              6,
              t(bcIntegrations.SALES),
              summaryResponse?.sales.length ?? 0
            )}
            {newTab(
              7,
              t(bcIntegrations.CONCILIATION),
              summaryResponse?.conciliations.length ?? 0
            )}
            {newTab(
              8,
              t(bcIntegrations.PURCHASE_ORDER_MODIFY),
              summaryResponse?.purchaseOrderModify.length ?? 0
            )}
            {newTab(
              9,
              t(bcIntegrations.VENDORS),
              summaryResponse?.vendor.length ?? 0
            )}
            {newTab(
              10,
              t(bcIntegrations.PRODUCTS),
              summaryResponse?.products.length ?? 0
            )}
          </Tabs>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box width="100%">
            <TabPanel value={value} index={0}>
              <AdjustmentErrors
                journals={summaryResponse?.journals ?? []}
                loading={loading}
                archive={archive}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <BatchErrors
                batches={summaryResponse?.batches ?? []}
                loading={loading}
                archive={archive}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PurchaseOrderErrors
                purchaseErrors={summaryResponse?.purchaseOrders ?? []}
                loading={loading}
                archive={archive}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <PurchaseOrderReceiptErrors
                errors={summaryResponse?.purchaseOrderReceipts ?? []}
                loading={loading}
                archive={archive}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <TransferOrderErrors
                transferErrors={summaryResponse?.transfers ?? []}
                loading={loading}
                archive={archive}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <TransferReceiptErrors
                transferReceiptErrors={
                  summaryResponse?.transferOrderReceipts ?? []
                }
                loading={loading}
                archive={archive}
              />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <SaleErrors
                saleErrors={summaryResponse?.sales ?? []}
                loading={loading}
                archive={archive}
              />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <ConciliationErrors
                conciliations={summaryResponse?.conciliations ?? []}
                loading={loading}
                archive={archive}
              />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <PurchaseOrderModifyErrors
                errors={summaryResponse?.purchaseOrderModify ?? []}
                loading={loading}
                archive={archive}
              />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <VendorErrors
                errors={summaryResponse?.vendor ?? []}
                loading={loading}
                archive={archive}
              />
            </TabPanel>
            <TabPanel value={value} index={10}>
              <ProductErrors
                errors={summaryResponse?.products ?? []}
                loading={loading}
                archive={archive}
              />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </PageBox>
  );
};
export default BusinessCentralIntegration;
