import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "app/hooks/useAppSelector";
import { ProductionPlan } from "core/productions/entities/Plan";
import React from "react";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import { Actions } from "utils/modal";
import { canModifyProductionAndPlan, TypeOfRole } from "utils/role";
import { ProductionDialogProps } from "../Manager/useHandleProductions";
import ProductionScheduleCardDeleteButton from "./DeleteButton";

interface Props {
  id: number;
  index: number;
  productionPlan: ProductionPlan;
  openAddProductionDialog: (props: ProductionDialogProps) => void;
  disabledAction: boolean;
  removeCard: () => void;
  openInfoDialog: () => void;
  isToday: boolean;
  reloadDay: () => void;
  onlyView?: boolean;
}

const ProductionScheduleCard = (props: Props): React.ReactElement => {
  const {
    productionPlan,
    openAddProductionDialog,
    id,
    index,
    disabledAction,
    removeCard,
    openInfoDialog,
    isToday,
    reloadDay,
    onlyView,
  } = props;

  const user = useAppSelector((state) => state.session.user.data);
  const userCanModify: boolean = canModifyProductionAndPlan(
    user?.role as TypeOfRole
  );

  const edit = (onlyQuantity: boolean = false) => {
    openAddProductionDialog({
      action: Actions.edit,
      currentPlan: {
        id: productionPlan.id,
        sku: productionPlan.sku,
        name: productionPlan.name,
        quantity: productionPlan.quantity,
        unit: productionPlan.measureUnit,
        scheduledDate: productionPlan.scheduledDate,
        timeSlot: productionPlan.timeSlot,
        userAssigned: productionPlan.assignedUserId,
        userAssignedName: productionPlan.assignedUserName,
      },
      onlyQuantity,
    });
  };

  const getBorderBottom = (status: string) => {
    const result: { borderBottom?: string } = { borderBottom: undefined };
    if (status === "IN_PROCESS") {
      result.borderBottom = "#3C81DB solid 4px";
    }
    if (status === "FINISHED") {
      result.borderBottom = "#4CAF50 solid 4px";
    }
    if (status === "SCHEDULED") {
      result.borderBottom = "#FF9800 solid 4px";
    }
    if (status === "ARCHIVED") {
      result.borderBottom = "gray solid 4px";
    }
    return result;
  };

  const borderBottom = getBorderBottom(productionPlan.productionStatus ?? "");
  const showRightActions =
    (!disabledAction || isToday || userCanModify) &&
    productionPlan.productionStatus !== "FINISHED";

  return (
    <Draggable
      key={`ProductionScheduleCard${id}`}
      draggableId={id.toString()}
      index={index}
      isDragDisabled={
        disabledAction || !!productionPlan.productionId || !!onlyView
      }
    >
      {(provided: DraggableProvided) => (
        <Paper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          elevation={1}
          sx={{ width: 224, p: 1, height: "100%", ...borderBottom }}
          onDoubleClick={openInfoDialog}
        >
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", gap: 0.5 }}>
              {!disabledAction && !onlyView && (
                <DragIndicatorOutlinedIcon
                  sx={{
                    cursor: !disabledAction ? "pointer" : undefined,
                    color: disabledAction ? "#00000042" : undefined,
                    mt: 0.5,
                  }}
                />
              )}
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {productionPlan.name}
                </Typography>
                <Typography variant="body2">
                  {productionPlan.quantity} {productionPlan.measureUnit}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {productionPlan.assignedUserName}
                </Typography>
              </Box>
            </Box>
            {showRightActions && !onlyView && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => edit(!!productionPlan.productionId)}
                >
                  <BorderColorOutlinedIcon fontSize="small" />
                </IconButton>

                <ProductionScheduleCardDeleteButton
                  productionPlan={productionPlan}
                  reloadDay={reloadDay}
                  removeCard={removeCard}
                />
              </Box>
            )}
          </Box>
        </Paper>
      )}
    </Draggable>
  );
};

export default React.memo(ProductionScheduleCard);
