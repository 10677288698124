import Box from "@mui/material/Box";
import { forwardRef, useMemo } from "react";

import { ProductionLabelResponse } from "core/productions/entities/Productions";

import { getCompanyData } from "app/utils/company";
import dayjs from "dayjs";
import Barcode from "react-barcode";
import { useTranslation } from "react-i18next";
import { commons, productionDialog } from "../../../../i18n/types";

interface ProductionOrderLabelTemplateProps {
  labels: ProductionLabelResponse[];
}

const ProductionLabelPrintTemplate = forwardRef(
  (
    props: ProductionOrderLabelTemplateProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const { labels } = props;

    const { t } = useTranslation();
    const { logo, name } = useMemo(() => getCompanyData(), []);

    const conservation = (conservation: string) => {
      const index = ["Freezing", "Refrigeration", "RoomTemperature"].findIndex(
        (val) => val === conservation
      );
      if (index !== -1) {
        return t(`commons.${conservation}`);
      } else {
        return conservation;
      }
    };

    return (
      <Box
        sx={{
          display: "none",
          displayPrint: "block",
          height: "auto",
          paddingLeft: "1mm",
          paddingRight: "4mm",
        }}
        ref={ref}
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
            @media all {
              .page-break {
                display: none;
              }
            }
            @media print {
              @page {
                size: 50mm 100mm landscape;
              }

              .page-break {
                margin-top: 3rem;
                display: block;
                page-break-before: auto;
              }
            }    
            table, th, td{
              font-size: 12px;
              height: 5px;
              line-height: 13px;
            }
            `,
          }}
        ></style>
        {labels.map((label) => (
          <div key={`Label${label.id}`}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "60px",
              }}
            >
              <img src={logo} alt="Foodology SAS" width="50%" />
              <Barcode
                value={`PROD-${label.id.toString()}`}
                displayValue={false}
                height={50}
                width={1.5}
              />
            </div>
            <table
              cellSpacing={0}
              cellPadding={0}
              border={0}
              style={{
                width: "100%",
                borderCollapse: "collapse",
                padding: "0",
                margin: "0",
              }}
            >
              <tr>
                <td>
                  <strong>{t(commons.SKU)}:</strong> {label.sku}
                </td>
                <td>
                  <strong>{t(productionDialog.WEIGHT)}</strong>{" "}
                  {`${label.quantity} ${label.measureUnit}`}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <strong>{t(productionDialog.TECHNICAL_NAME)}:</strong>{" "}
                  {label.technicalName}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <strong>{t(productionDialog.NAME)}:</strong>{" "}
                  {label.productName}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{t(productionDialog.BATCH)}:</strong> {label.batch}
                </td>
                <td style={{ textAlign: "center" }}>
                  {t(productionDialog.WEEK)}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{t(productionDialog.PRODUCTION_DATE)}:</strong>{" "}
                  {dayjs(label.finishedAt).format("YYYY-MM-DD hh:mm A")}
                </td>
                <td
                  rowSpan={4}
                  style={{
                    fontSize: "40px",
                    textAlign: "center",
                    verticalAlign: "center",
                  }}
                >
                  {label.week}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    {t(productionDialog.EXPIRATION_DATE)}:{" "}
                    {label.expirationDate ? label.expirationDate : ""}
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{t(productionDialog.CONSERVATION)}:</strong>{" "}
                  {conservation(label.conservation)}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{t(productionDialog.MAXIMUM_DURATION)}:</strong>{" "}
                  {label.maxDuration !== ""
                    ? `${label.maxDuration} ${t(productionDialog.DAYS)}`
                    : ""}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{ textAlign: "center", textTransform: "uppercase" }}
                >
                  <strong>{t(commons.INGREDIENTS)}:</strong>
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                    textTransform: "uppercase",
                    height: "60px",
                  }}
                >
                  {label.ingredients}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <strong>{t(productionDialog.RESPONSIBLE)}:</strong>{" "}
                  {label.userName}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <strong>{t(productionDialog.MANUFACTURER)}:</strong> {name}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <strong>{t(productionDialog.ADDRESS)}:</strong>{" "}
                  {label.address}
                </td>
              </tr>
            </table>
            <div className="page-break" />
          </div>
        ))}
      </Box>
    );
  }
);

export default ProductionLabelPrintTemplate;
