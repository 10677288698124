import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "config/app";
import { BusinesCentralIntegrationHTTPRepository } from "core/BusinessCentralIntegration/repository/http/businessCentralIntegration";
import { CanGetErrorSummaryByKitchenUseCase } from "core/BusinessCentralIntegration/useCases/canGetErrorSummaryByKitchenUseCase";
import { CanGetErrorSummaryUseCase } from "core/BusinessCentralIntegration/useCases/canGetErrorSummaryOrderUseCase";
import { CanIntegratorUrlExecuteUseCase } from "core/BusinessCentralIntegration/useCases/canRetryIntegration";

export const byKitchen = createAsyncThunk(
  "bcIntegration/getErrorSummary",
  async (payload: { kitchenId: string }) => {
    const httpRepository = new BusinesCentralIntegrationHTTPRepository(
      appConfig
    );
    const getErrorSummaryUseCase = new CanGetErrorSummaryByKitchenUseCase(
      httpRepository
    );
    const errorSummary = await getErrorSummaryUseCase.execute(
      payload.kitchenId
    );
    return errorSummary;
  }
);

export const allIntegrationErrors = createAsyncThunk(
  "bcIntegration/allIntegrationErrors",
  async () => {
    const httpRepository = new BusinesCentralIntegrationHTTPRepository(
      appConfig
    );
    const getErrorSummaryUseCase = new CanGetErrorSummaryUseCase(
      httpRepository
    );
    const summary = await getErrorSummaryUseCase.execute();
    return summary;
  }
);

export const bcExecuteUrl = createAsyncThunk(
  "bcIntegration/bcExecuteUrl",
  async (payload: { url: string }) => {
    const httpRepository = new BusinesCentralIntegrationHTTPRepository(
      appConfig
    );
    const retryUseCase = new CanIntegratorUrlExecuteUseCase(httpRepository);
    return retryUseCase.execute(payload.url);
  }
);
