import CheckIcon from "@mui/icons-material/Check";
import { Box, Card, CardContent, CardMedia } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Station } from "core/productions/entities/Station";
import React from "react";

interface Props {
  station: Station;
  selectedStation: Station | null;
  onClick: (station: Station | null) => void;
}
const SelectStationCard = ({
  station,
  onClick,
  selectedStation,
}: Props): React.ReactElement => {
  const isCheck = selectedStation?.slug === station.slug;
  return (
    <Card
      onClick={() => {
        if (isCheck) {
          onClick(null);
        } else {
          onClick(station);
        }
      }}
      sx={{
        width: "100%",
        backgroundColor: isCheck ? "rgba(1, 26, 91, 0.15)" : "#fff",
        "&:hover": {
          opacity: 0.5,
          ".MuiTypography-body1": {
            opacity: 0.5,
          },
        },
      }}
    >
      <CardMedia
        sx={{ height: 80, opacity: isCheck ? 0.2 : 1, cursor: "pointer" }}
        image={station.logoUrl}
        title={station.name}
      >
        <Box position="relative">
          {isCheck && (
            <CheckIcon
              sx={{
                position: "absolute",
                color: "#ffffff",
                top: 40,
                left: "50%",
                zIndex: 999,
                transform: "translateX(-50%)",
              }}
            />
          )}
        </Box>
      </CardMedia>
      <CardContent
        sx={{
          py: "16px !important",
          px: "16px",
          textTransform: "capitalize",
          gap: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        <Typography
          variant="body1"
          lineHeight="18px"
          letterSpacing="0.15px"
          sx={{
            fontWeight: "bold",
            color: "#232323",
            fontSize: 16,
          }}
        >
          {station.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SelectStationCard;
