import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KitchenSlice } from "core/kitchens/entities/Kitchen";
import {
  getLocalStorageString,
  removeLocalStorage,
  setLocalStorage,
} from "utils/localStorage";
import { getListProducts } from "../dailyAdjustments/thunks";
import {
  Alert,
  CitiesState,
  CompanyState,
  CountriesState,
  defaultData,
  defaultDataSingle,
  initialState,
  KitchensState,
} from "./state";

const getKitchen = (
  list: KitchenSlice[],
  kitchenId?: string
): KitchenSlice | undefined => {
  const selectedKitchenId =
    kitchenId ?? getLocalStorageString("selectedKitchenId");
  return list.find((el) => el.kitchenId === selectedKitchenId);
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    showAlert: (state, alert: PayloadAction<Alert>) => {
      state.alert = { ...alert.payload, show: true };
    },
    showSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = "";
      state.canSearchOnTopBar = true;
    },
    hideSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = "";
      state.canSearchOnTopBar = false;
    },
    setSearchOnTopBar: (state, action: PayloadAction<string>) => {
      state.textSearchOnTopBar = action.payload;
    },
    clearSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = "";
    },
    setSearchOnFullDialog: (state, action: PayloadAction<string>) => {
      state.textSearchOnFullDialog = action.payload;
    },
    clearSearchOnFullDialog: (state) => {
      state.textSearchOnFullDialog = "";
    },
    showSearchOnFullDialog: (state) => {
      state.textSearchOnFullDialog = "";
      state.canSearchOnFullDialog = true;
    },
    hideSearchOnFullDialog: (state) => {
      state.textSearchOnFullDialog = "";
      state.canSearchOnFullDialog = false;
    },
    hideAlert: (state) => {
      state.alert = { show: false };
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    clearTitle: (state) => {
      state.title = null;
    },
    setSelectedKitchen: (state, action: PayloadAction<string | undefined>) => {
      const kitchenId = action.payload;
      if (kitchenId) {
        setLocalStorage("selectedKitchenId", kitchenId);
        state.selectedKitchen = getKitchen(state.kitchens.data, kitchenId);
      } else {
        removeLocalStorage("selectedKitchenId");
        state.selectedKitchen = undefined;
      }
    },

    setKitchens: (state, action: PayloadAction<KitchensState>) => {
      state.kitchens = action.payload;
    },
    clearKitchens: (state) => {
      state.kitchens = defaultData as KitchensState;
    },
    setCities: (state, action: PayloadAction<CitiesState>) => {
      state.cities = action.payload;
    },
    clearCities: (state) => {
      state.cities = defaultData as CitiesState;
    },
    setCountries: (state, action: PayloadAction<CountriesState>) => {
      state.countries = action.payload;
    },
    clearCountries: (state) => {
      state.countries = defaultData as CountriesState;
    },
    setCompany: (state, action: PayloadAction<CompanyState>) => {
      state.company = action.payload;
    },
    clearCompany: (state) => {
      state.company = defaultDataSingle as CompanyState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getListProducts.pending, (state) => {
        state.products.status = "loading";
      })
      .addCase(getListProducts.fulfilled, (state, action) => {
        state.products.status = "succeeded";
        state.products.data = action.payload.productsForKitchen;
      })
      .addCase(getListProducts.rejected, (state, action) => {
        state.products.status = "failed";
        state.products.error = action.error.message ?? null;
      });
  },
});

export const {
  showAlert,
  hideAlert,
  setTitle,
  clearTitle,
  setSelectedKitchen,
  showSearchOnTopBar,
  hideSearchOnTopBar,
  setSearchOnTopBar,
  clearSearchOnTopBar,
  setSearchOnFullDialog,
  clearSearchOnFullDialog,
  showSearchOnFullDialog,
  hideSearchOnFullDialog,
  setKitchens,
  clearKitchens,
  setCities,
  clearCities,
  setCountries,
  clearCountries,
  setCompany,
  clearCompany,
} = globalSlice.actions;
export const { reducer } = globalSlice;

export default globalSlice;
