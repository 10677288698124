import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { commons } from "app/i18n/types";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onUpdate: (timeSlot: string) => void;
}

const TimeSlotFilterTwo = (props: Props): React.ReactElement => {
  const { onUpdate } = props;
  const { t } = useTranslation();

  const all = t(commons.ALL);

  const options = [all, "AM", "M", "PM"];

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    onUpdate(options[index]);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        variant="outlined"
        onClick={handleToggle}
        sx={{
          backgroundColor: "#ffffff",
        }}
        endIcon={<ArrowDropDownIcon />}
      >
        {options[selectedIndex]}
      </Button>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(_) => handleMenuItemClick(index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default TimeSlotFilterTwo;
