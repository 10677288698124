import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import GreenSwitch from "app/components/GreenSwitch";
import { useAllCities } from "app/hooks/useAllCities";
import { commons, physicalCountModule, supplyManagement } from "app/i18n/types";
import { City } from "core/city/entities/City";
import { KitchenSlice } from "core/kitchens/entities/Kitchen";
import { Supply } from "core/supplies/entities/Supply";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import KitchensAutocomplete from "./KitchensAutocomplete";

interface Props {
  open: boolean;
  onClose: () => void;
  selectedSupplies: Array<Supply>;
  selectedCountry: string;
  kitchens: Array<KitchenSlice> | null;
  onContinue: (selectedKitchens: Array<string>, activeStatus: boolean) => void;
  loading: boolean;
}

const SuppliesManagementUpdateStateDialog: FunctionComponent<Props> = (
  props
) => {
  const {
    open,
    onClose,
    selectedSupplies,
    kitchens,
    onContinue,
    loading,
    selectedCountry,
  } = props;

  const { t } = useTranslation();

  const useCIty = useAllCities();
  const citiesByCountry = useCIty.byCountry(selectedCountry);

  const [selectedCities, setSelectedCities] = useState<Array<City>>([]);
  const [selectedKitchens, setSelectedKitchens] = useState<Array<KitchenSlice>>(
    []
  );

  const [activeStatusCheckbox, setActiveStatusCheckbox] =
    useState<boolean>(true);

  const handleActiveStatusCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveStatusCheckbox(event.target.checked);
  };

  const citiesOptions = useMemo(() => {
    return [
      {
        id: "ALL",
        name: t(physicalCountModule.SELECT_ALL),
        code: "ALL",
        country: "ALL",
        slug: "",
        logoUrl: "",
        timeZone: "",
      },
      ...citiesByCountry,
    ];
  }, [citiesByCountry]);

  const kitchensOptions = useMemo(() => {
    const kitchenOpts =
      kitchens
        ?.filter((kitchen) =>
          selectedCities.map((el) => el.code).includes(kitchen.city)
        )
        .sort((a, _b) => (a.type === "PRODUCTION_CENTER" ? -1 : 1)) ?? [];

    const allKitchensOption: KitchenSlice = {
      id: "ALL",
      kitchenId: "ALL",
      name: t(physicalCountModule.SELECT_ALL),
      country: "ALL",
      city: "ALL",
      address: "",
      locationCode: "",
      type: "PRODUCTION_CENTER",
    };

    return [allKitchensOption, ...kitchenOpts];
  }, [kitchens, selectedCities]);

  useEffect(() => {
    if (selectedCities === null || selectedCities.length === 0) {
      setSelectedKitchens([]);
    }
  }, [selectedCities]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {loading === true && <LinearProgress />}

      <DialogTitle>{t(supplyManagement.UPDATE_DIALOG_TITLE)}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t(supplyManagement.UPDATE_DIALOG_CONTENT)}
        </DialogContentText>

        <Box my={2} display="flex" gap={2}>
          <KitchensAutocomplete<City>
            options={citiesOptions}
            value={selectedCities}
            label={t(commons.CITY)}
            onChange={(value) => setSelectedCities(value)}
            disabled={loading}
          />

          <KitchensAutocomplete<KitchenSlice>
            options={kitchensOptions}
            value={selectedKitchens}
            label={t(commons.KITCHENS)}
            onChange={(value) => setSelectedKitchens(value)}
            disabled={loading}
          />
        </Box>

        <Box display="flex" gap={1} alignItems="center">
          <Box bgcolor="#F1EFEF" p={1} borderRadius={1}>
            <Typography variant="body2">
              {t(supplyManagement.UPDATE_DIALOG_FOOTER, {
                nro: selectedSupplies.length,
              })}
              :
            </Typography>
          </Box>

          <Box
            display="flex"
            gap={1}
            alignItems="center"
            flexBasis={160}
            flexShrink={0}
          >
            <GreenSwitch
              checked={activeStatusCheckbox}
              onChange={handleActiveStatusCheckboxChange}
              inputProps={{ "aria-label": "controlled" }}
              disabled={selectedKitchens.length === 0 || loading}
            />

            <Typography fontWeight={700} variant="body2">
              {t(
                activeStatusCheckbox
                  ? supplyManagement.UPDATE_DIALOG_FOOTER_ENABLE
                  : supplyManagement.UPDATE_DIALOG_FOOTER_DISABLE
              )}
            </Typography>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={onClose}
          disabled={loading}
          fullWidth
        >
          {t(commons.CANCEL)}
        </Button>

        <Button
          variant="contained"
          onClick={() =>
            onContinue(
              selectedKitchens.map((kitchen) => kitchen.kitchenId),
              activeStatusCheckbox
            )
          }
          disabled={loading}
          fullWidth
        >
          {t(commons.CONTINUE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuppliesManagementUpdateStateDialog;
