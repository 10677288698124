import { bcIntegrations } from "app/i18n/types";
import { PurchaseOrderModifyError } from "core/BusinessCentralIntegration/entities/BusinessCentralIntegration";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import BcIntegrationTable from "./BcIntegrationTable";
import { getPurchaseErrorHeader } from "./utils";

interface Props {
  errors: PurchaseOrderModifyError[];
  loading: boolean;
  archive: (id: string) => Promise<void>;
}

const PurchaseOrderModifyErrors: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { errors, loading, archive } = props;

  return (
    <BcIntegrationTable
      data={errors}
      headers={getPurchaseErrorHeader(t)}
      loading={loading}
      title={t(bcIntegrations.PURCHASE_ORDER_MODIFY)}
      archive={archive}
    />
  );
};
export default PurchaseOrderModifyErrors;
