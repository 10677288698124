import { KOSBaseTableHeader } from "@foodology-co/alejandria";
import { bcIntegrations, commons } from "app/i18n/types";
import { VendorError } from "core/BusinessCentralIntegration/entities/BusinessCentralIntegration";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import BcIntegrationTable from "./BcIntegrationTable";
import { dateUtcHeader, textHeader } from "./utils";

interface Props {
  errors: VendorError[];
  loading: boolean;
  archive: (id: string) => Promise<void>;
}

const VendorErrors: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { errors, loading, archive } = props;

  const getHeader = (): KOSBaseTableHeader[] => {
    return [
      textHeader(t, commons.COUNTRY, "country"),
      textHeader(t, commons.CODE, "code"),
      textHeader(t, commons.ACTION, "type"),
      dateUtcHeader(t, commons.DATE, "createdAt"),
      textHeader(t, commons.STATUS, "status"),
      textHeader(t, commons.ERROR, "error"),
    ];
  };

  return (
    <BcIntegrationTable
      data={errors}
      headers={getHeader()}
      loading={loading}
      title={t(bcIntegrations.VENDORS)}
      archive={archive}
    />
  );
};
export default VendorErrors;
