import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
} from "@foodology-co/alejandria";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, IconButton } from "@mui/material";
import PageHeader from "app/components/PageHeader/PageHeader";
import { bcIntegrations, commons } from "app/i18n/types";
import { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonDialog from "../common/CommonDialog";

interface Props {
  data: any[];
  loading: boolean;
  title: string;
  headers: KOSBaseTableHeader[];
  archive: (id: string) => Promise<void>;
}

const BcIntegrationTable: FunctionComponent<Props> = (props) => {
  const { data, loading, title, headers, archive } = props;
  const { t } = useTranslation();
  const [loadingArchive, setLoadingArchive] = useState<boolean>(false);
  const [archiveId, setArchiveId] = useState<string>();

  const handleConfirm = useCallback(async () => {
    if (!archiveId) return;
    setLoadingArchive(true);
    await archive(archiveId);
    setArchiveId(undefined);
    setLoadingArchive(false);
  }, [archiveId]);

  const refinedHeaders = useCallback((): KOSBaseTableHeader[] => {
    const actionButton = (item: KOSRowData) => {
      return (
        <IconButton
          onClick={() => setArchiveId(item.id)}
          color="error"
          disabled={loadingArchive}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      );
    };
    return [
      ...headers,
      {
        label: t(commons.ACTIONS),
        align: "center",
        component: actionButton,
      },
    ];
  }, [headers, loadingArchive, archive]);

  return (
    <Box>
      <PageHeader title={title} isFullDialog />

      {archiveId && (
        <CommonDialog
          open
          title={t(commons.CONFIRM)}
          message={t(bcIntegrations.ARCHIVE_DIALOG_MESSAGE, { type: t(title) })}
          handleClose={() => setArchiveId(undefined)}
          handleConfirm={handleConfirm}
          showCancelButton
        />
      )}

      <KOSBaseTable
        columns={refinedHeaders()}
        rows={{
          data: data ?? [],
          total: data.length,
          loading,
        }}
        pagination={{
          enable: true,
        }}
      />
    </Box>
  );
};
export default BcIntegrationTable;
