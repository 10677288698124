import { CancelOutlined, TaskAlt } from "@mui/icons-material";
import CalculateIcon from "@mui/icons-material/CalculateOutlined";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import NumberField from "app/components/common/Field/Number";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAlert } from "app/hooks/useAlert";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import {
  commons,
  measureUnits,
  productionManagement,
  productionSchedule,
} from "app/i18n/types";
import {
  productionScheduleSave,
  updateProductionPlan,
} from "app/store/slices/productions/plan/thunks";
import { getToday } from "app/utils/dateUtils";
import { UserLite } from "core/account/entities/User";
import { ProductionCatalog } from "core/productions/entities/Catalog";
import {
  ProductionPlanSelectedSlot,
  SlotEnum,
} from "core/productions/entities/Plan";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Actions } from "utils/modal";
import { ValidationError } from "yup";
import { getUsersByKitchenId } from "../../../../store/slices/productions/thunks";
import ProductionSelector from "../../../Productions/Selector";
import ProductionCalculator from "../../Calculator";
import ProductionModifyReasonSelect from "../../ModifyReason";
import { FormState } from "./constants";
import { handleValidations } from "./formValidations";

interface Props {
  initialValues?: FormState;
  action: Actions;
  onClose: () => void;
  selectedSlot?: ProductionPlanSelectedSlot;
  onlyQuantity?: boolean;
  refreshCalendar?: () => void;
  askForReason: boolean;
}

export const HandleForm = (props: Props) => {
  const {
    initialValues,
    selectedSlot,
    refreshCalendar,
    onlyQuantity,
    action,
    askForReason,
  } = props;

  const navigator = useNavigator();
  const { kitchenId: kitchenIdParam } = navigator.params();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const alert = useAlert();

  const [formState, setFormState] = useState<FormState>({
    id: initialValues?.id ?? 0,
    sku: initialValues?.sku ?? "",
    name: initialValues?.name ?? "",
    unit: initialValues?.unit ?? t(measureUnits.UN),
    quantity: initialValues?.quantity ?? 0,
    scheduledDate:
      initialValues?.scheduledDate ?? selectedSlot?.scheduledDate ?? "",
    timeSlot: initialValues?.timeSlot ?? selectedSlot?.timeSlot ?? "",
    userAssigned: initialValues?.userAssigned ?? "",
    userAssignedName: initialValues?.userAssignedName ?? "",
  });

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [usersByKitchen, setUsersByKitchen] = useState<UserLite[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openCalculator, setOpenCalculator] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [kitchenId, setKitchenId] = useState<string>();

  const today = getToday();

  useEffect(() => {
    setErrors({});
    handleValidations
      .validate(formState, { abortEarly: false })
      .catch((error: ValidationError) => {
        let modifiedErrors: Record<string, boolean> = {};
        error.inner.forEach((errorDetail) => {
          if (errorDetail.path) modifiedErrors[errorDetail.path] = true;
        });
        setErrors(modifiedErrors);
      });
  }, [formState]);

  const getUsers = (kitchenId?: string) => {
    if (!kitchenId) return;
    dispatch(getUsersByKitchenId({ kitchenId })).then((result) => {
      setUsersByKitchen(result.payload as UserLite[]);
    });
  };

  useEffect(() => {
    setKitchenId(kitchenIdParam);
  }, [kitchenIdParam]);

  useEffect(() => {
    getUsers(kitchenId);
  }, [kitchenId]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormState((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    },
    []
  );

  const handleSelectChange = useCallback((event: SelectChangeEvent<string>) => {
    setFormState((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const [selectedProduction, setSelectedProduction] =
    useState<ProductionCatalog | null>(null);

  const handleProductionName = useCallback(
    (value: ProductionCatalog | null) => {
      setSelectedProduction(value);
      setFormState((prev) => ({
        ...prev,
        sku: value?.sku ?? "",
        name: value?.name ?? "",
        unit: value?.unit ?? t(measureUnits.UN),
      }));
    },
    [t]
  );

  const handleUserAssigned = useCallback((value: UserLite | null) => {
    setFormState((prev) => ({
      ...prev,
      userAssignedName: value?.name ?? "",
      userAssigned: value?._id ?? "",
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!kitchenId) return;
    if (!handleValidations.isValidSync(formState)) return;

    setIsLoading(true);
    const { name, userAssignedName, quantity, ...rest } = formState;
    let ok = false;
    if (props.action === Actions.edit) {
      const response = await dispatch(
        updateProductionPlan({
          productionPlanId: formState.id,
          plan: {
            ...rest,
            quantity: Number(quantity),
            modifyComment: reason,
          },
        })
      );
      ok = response.payload as boolean;
    }
    if (props.action === Actions.add) {
      const response = await dispatch(
        productionScheduleSave({
          kitchenId,
          body: [
            {
              ...rest,
              quantity: Number(quantity),
              comment: reason,
            },
          ],
        })
      );
      ok = response.payload as boolean;
    }
    if (ok) {
      alert.success();
    } else {
      alert.error();
    }
    setIsLoading(false);

    if (refreshCalendar) refreshCalendar();
  }, [formState, kitchenId, dispatch, props.action, refreshCalendar, reason]);

  const handleIcon = (isError: boolean) => {
    if (isError) {
      return <CancelOutlined color="warning" />;
    }

    return <TaskAlt color="success" />;
  };

  const handleConfirm = (quantity: number) => {
    setFormState((prev) => ({
      ...prev,
      quantity: quantity,
    }));
    setOpenCalculator(false);
  };

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      <Grid item xs={12}>
        {props.action === Actions.edit ? (
          <FormControl variant="outlined" fullWidth>
            <InputLabel>{t(productionManagement.PRODUCTION_NAME)}</InputLabel>
            <OutlinedInput
              type="text"
              label={t(productionManagement.PRODUCTION_NAME)}
              name="quantity"
              value={`${formState.sku} - ${formState.name}`}
              disabled={true}
            />
          </FormControl>
        ) : (
          <ProductionSelector
            kitchenId={kitchenId ?? ""}
            selectedProduction={selectedProduction}
            setSelectedProduction={handleProductionName}
            label={productionManagement.PRODUCTION_NAME}
            endAdornment={handleIcon(errors["sku"])}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" gap={3}>
          <FormControl variant="outlined" fullWidth>
            <NumberField
              value={formState.quantity}
              endAdornment={handleIcon(errors["quantity"])}
              label={t(productionManagement.QUANTITY)}
              onChangeValue={(newValue) => {
                setFormState((prev) => ({
                  ...prev,
                  quantity: newValue,
                }));
              }}
            />
          </FormControl>

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
              fontFamily: "Inter",
            }}
          >
            {formState.unit}
          </Typography>
          {props.action === Actions.add && (
            <Button
              color="primary"
              aria-label="close"
              disabled={
                selectedProduction === null ||
                selectedProduction.ingredients === null
              }
              onClick={() => {
                setOpenCalculator(true);
              }}
            >
              <CalculateIcon fontSize="large" />
            </Button>
          )}
        </Box>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{t(productionManagement.SCHEDULED_DATE)}</InputLabel>
          <OutlinedInput
            startAdornment={<InputAdornment position="start"></InputAdornment>}
            endAdornment={handleIcon(errors["scheduledDate"])}
            label={t(productionManagement.SCHEDULED_DATE)}
            name="scheduledDate"
            type="date"
            value={formState.scheduledDate}
            onChange={handleInputChange}
            inputProps={{
              min: today.str,
            }}
            disabled={onlyQuantity}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{t(productionManagement.TIME_SLOT)}</InputLabel>
          <Select
            name="timeSlot"
            label={t(productionManagement.TIME_SLOT)}
            value={formState.timeSlot}
            endAdornment={handleIcon(errors["timeSlot"])}
            onChange={handleSelectChange}
            disabled={onlyQuantity}
          >
            {Object.keys(SlotEnum).map((slot) => (
              <MenuItem key={`timeSlot-${slot}`} value={slot}>
                {slot}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            getOptionLabel={(option) => option.name}
            options={usersByKitchen}
            defaultValue={
              props.action === Actions.edit && initialValues?.userAssigned
                ? {
                    _id: initialValues?.userAssigned,
                    name: initialValues?.userAssignedName,
                    username: "",
                  }
                : null
            }
            onChange={(_, user) => handleUserAssigned(user)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(productionManagement.USER_ASSIGNED)}
                variant="outlined"
                name="userAssigned"
                value={formState.userAssigned}
              />
            )}
            disabled={onlyQuantity}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <ManagerDialogFooter
          onCancel={props.onClose}
          mainButton={{
            children: t(
              props.action === Actions.edit ? commons.CONFIRM : commons.ADD
            ),
            onClick: () => {
              if (askForReason) {
                setOpenConfirmDialog(true);
                return;
              }
              handleSubmit();
            },
            disabled:
              isLoading ||
              !!Object.keys(errors).length ||
              (props.action === Actions.edit &&
                initialValues?.quantity === formState.quantity),
          }}
          loading={isLoading}
        />
      </Grid>

      {openConfirmDialog && (
        <CommonDialog
          open
          showCancelButton
          title={t(
            action === Actions.edit
              ? productionManagement.EDIT_PRODUCTION_TITLE
              : productionManagement.ADD_PRODUCTION_TITLE
          )}
          handleConfirm={handleSubmit}
          handleClose={() => setOpenConfirmDialog(false)}
          disabledConfirmButton={!reason}
          bodyElement={
            <Grid container spacing={2}>
              {isLoading && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
              <Grid item xs={12}>
                {action === Actions.edit && (
                  <Typography variant="body1">
                    {t(productionSchedule.MODIFY_MESSAGE, {
                      productionName: formState.name,
                      initialQuantity: initialValues?.quantity,
                      finalQuantity: formState.quantity,
                      unit: initialValues?.unit,
                    })}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <ProductionModifyReasonSelect
                  type={
                    (initialValues?.quantity ?? 0) < formState.quantity
                      ? "MORE"
                      : "LESS"
                  }
                  onChangeAction={setReason}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
          }
        />
      )}

      <ProductionCalculator
        handleClose={() => setOpenCalculator(false)}
        ingredients={selectedProduction?.ingredients!!}
        openCalculator={openCalculator}
        handleConfirm={handleConfirm}
      ></ProductionCalculator>
    </Grid>
  );
};
