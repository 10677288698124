import { UserInfo } from "core/account/entities/User";
import jwtDecode from "jwt-decode";

export const userInfo = (token: string): UserInfo | null => {
  try {
    return jwtDecode(token) as UserInfo;
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null;
  }
};
