import Adjustments from "app/pages/Adjustments";
import ManualAdjustements from "app/pages/Adjustments/ManualAdjustments";
import AuthorizationReCount from "app/pages/AuthorizeRecounts/AuthorizationReCount";
import BusinessCentralIntegration from "app/pages/BusinessCentralIntegration";
import ConsolitadedExport from "app/pages/ConsolidatedReport";
import DailyAdjustmentsPage from "app/pages/DailyAdjustments";
import DailyAdjustmentAuthorizationPage from "app/pages/DailyAdjustments/authorization";
import DailyAdjustmentReport from "app/pages/DailyAdjustments/DailyAdjustmentReport";
import ModuleSelectorPage from "app/pages/ModuleSelector";
import PhysicalCount from "app/pages/PhysicalCount";
import PhysicalCountAccuracy from "app/pages/PhysicalCountAccuracy";
import PhysicalCountHistory from "app/pages/PhysicalCountHistory";
import PhysicalCountSchedule from "app/pages/PhysicalCountSchedule";
import ProductionsPage from "app/pages/Productions";
import ProductionCreatePage from "app/pages/Productions/Create";
import ProductionEditPage from "app/pages/Productions/Edit";
import ProductionLiberationPage from "app/pages/Productions/Liberation";
import ProductionLogPage from "app/pages/Productions/Log";
import SupplyingPage from "app/pages/Productions/Supply";
import SupplyingDetailPage from "app/pages/Productions/Supply/Detail";
import ProductionSupplyReception from "app/pages/Productions/Supply/Receipt";
import ProductionSupplyReceptionDetails from "app/pages/Productions/Supply/Receipt/Details";
import PurchaseOrder from "app/pages/PurchaseOrder";
import PurchaseOrderAccount from "app/pages/PurchaseOrder/Account";
import PurchaseOrderConciliation from "app/pages/PurchaseOrder/Conciliation";
import CreatePurchaseOrderPage from "app/pages/PurchaseOrder/Create";
import PurchaseOrderInvoices from "app/pages/PurchaseOrder/Invoices";
import PurchaseOrderLineReview from "app/pages/PurchaseOrderLine/Review";
import PurchaseOrderReceipt from "app/pages/ReceiptOrder";
import PurchaseOrderLines from "app/pages/ReceiptOrder/PurchaseOrderLines";
import TransferOrderReceipt from "app/pages/ReceiptOrder/TransferOrderReceipt";
import ReplacementPage from "app/pages/Replacement";
import AuthorizeReplacementPage from "app/pages/Replacement/Authorize";
import CurrentRoutes from "app/pages/Route/Current";
import SuppliesManagement from "app/pages/SuppliesManagement";
import TheoreticalInvenotrycomparisonReport from "app/pages/TheoreticalInventory/TheoreticalInvenotryComparisonReport";
import TheoricalInventoryMovementsPage from "app/pages/TheoreticalInventory/TheoreticalInventoryMovements";
import TheoricalInventoryPage from "app/pages/TheoreticalInventory/TheoreticalInventoryPage";
import TransferOrderDetailsPage from "app/pages/TransferOrderDetails";
import TransferOrderPage from "app/pages/TransferOrders";
import TransfersPage from "app/pages/Transfers";
import ReviewPackedTransferOrdersPage from "app/pages/Transfers/Authorize/reviewPacked";
import TransferOrdersReviewPage from "app/pages/Transfers/ordersReview";
import TransferOrderPickingPage from "app/pages/Transfers/Packing";
import VendorPage from "app/pages/Vendor";
import VendorBySkuAndCityPage from "app/pages/Vendor/BySkuAndCity";
import VendorSupplyByVendorPage from "app/pages/Vendor/Supply/Byvendor";

import { TypeOfRole as Role } from "utils/role";
import { CustomRoute } from "utils/route";

export const rolesWithAllAccess = [
  Role.TECH,
  Role.PRODUCTO,
  Role.TECH_OPS,
  Role.ADMIN,
  Role.INVENTORY_ANALYST,
  Role.CITY_MANAGER,
  Role.COUNTRY_MANAGER,
  Role.OPS_EXCELLENTS,
];

export const routeConfig: CustomRoute[] = [
  {
    path: "modules",
    element: <ModuleSelectorPage />,
    childrens: [
      {
        path: ":inventoryModule",
        element: <ModuleSelectorPage />,
        childrens: [{ path: ":kitchenId", element: <ModuleSelectorPage /> }],
      },
    ],
  },
  {
    path: "physical-count",
    childrens: [
      {
        path: "schedule",
        element: <PhysicalCountSchedule />,
        roles: [Role.OPS],
        companies: ["fdgy", "trofi"],
      },
      {
        path: ":kitchenId",
        element: <PhysicalCountHistory />,
        roles: [
          Role.JEFE_COCINA,
          Role.SEGUNDO_COCINA,
          Role.COCINERO,
          Role.JEFE_CP,
          Role.SEGUNDO_CP,
          Role.COCINERO_CP,
          Role.STORER,
          Role.QUALITY,
          Role.QUALITY_INNOVATION,
          Role.OPS,
          Role.COMPRAS,
        ],
        companies: ["fdgy", "trofi"],
        childrens: [
          {
            path: ":countingId",
            element: <PhysicalCount />,
            companies: ["fdgy", "trofi"],
          },
          {
            path: "accuracy/:countingId",
            element: <PhysicalCountAccuracy />,
            companies: ["fdgy", "trofi"],
          },
        ],
      },
      {
        path: "consolidated",
        element: <ConsolitadedExport />,
        roles: [
          Role.JEFE_COCINA,
          Role.SEGUNDO_COCINA,
          Role.JEFE_CP,
          Role.SEGUNDO_CP,
          Role.OPS,
          Role.QUALITY,
          Role.QUALITY_INNOVATION,
          Role.COMPRAS,
          Role.MARKETING,
        ],
        companies: ["fdgy", "trofi"],
      },
    ],
  },
  {
    path: "purchase-orders",
    element: <PurchaseOrder />,
    roles: [Role.COMPRAS],
    companies: ["fdgy", "trofi"],
    childrens: [
      {
        path: "create",
        element: <CreatePurchaseOrderPage />,
        roles: [Role.COMPRAS],
        companies: ["fdgy", "trofi"],
      },
      {
        path: ":kitchenId",
        childrens: [
          {
            path: ":orderId/:origin",
            element: <PurchaseOrderLineReview />,
            roles: [Role.COMPRAS],
            companies: ["fdgy", "trofi"],
          },
        ],
      },
      {
        path: "conciliation",
        element: <PurchaseOrderConciliation />,
        roles: [Role.CONTABILIDAD, Role.FINANZAS],
        companies: ["fdgy", "trofi"],
      },
      {
        path: "account",
        element: <PurchaseOrderAccount />,
        roles: [Role.CONTABILIDAD, Role.FINANZAS, Role.COMPRAS],
        companies: ["fdgy"],
      },
    ],
  },
  {
    path: "receipt-order",
    childrens: [
      {
        path: ":kitchenId",
        element: <PurchaseOrderReceipt />,
        roles: [
          Role.JEFE_COCINA,
          Role.SEGUNDO_COCINA,
          Role.COCINERO,
          Role.JEFE_CP,
          Role.SEGUNDO_CP,
          Role.COCINERO_CP,
          Role.STORER,
          Role.OPS,
          Role.QUALITY,
          Role.QUALITY_INNOVATION,
          Role.COMPRAS,
        ],
        companies: ["fdgy", "trofi"],
        childrens: [
          {
            path: "purchase/:orderId/:origin",
            element: <PurchaseOrderLines />,
            companies: ["fdgy", "trofi"],
          },
          {
            path: "transfer/:orderId/:origin",
            element: <TransferOrderReceipt />,
            companies: ["fdgy", "trofi"],
          },
        ],
      },
    ],
  },
  {
    path: "adjustments",
    element: <Adjustments />,
    roles: rolesWithAllAccess,
    companies: ["fdgy", "trofi"],
    childrens: [
      {
        path: "manual",
        element: <ManualAdjustements />,
        roles: rolesWithAllAccess,
        companies: ["fdgy", "trofi"],
      },
      {
        path: "re-count",
        element: <AuthorizationReCount />,
        roles: rolesWithAllAccess,
        companies: ["fdgy", "trofi"],
      },
    ],
  },
  {
    path: "daily-adjustments",
    childrens: [
      {
        path: ":kitchenId",
        element: <DailyAdjustmentsPage />,
        roles: [
          Role.JEFE_COCINA,
          Role.SEGUNDO_COCINA,
          Role.COCINERO,
          Role.JEFE_CP,
          Role.SEGUNDO_CP,
          Role.COCINERO_CP,
          Role.STORER,
          Role.OPS,
          Role.COMPRAS,
        ],
        companies: ["fdgy", "trofi"],
        childrens: [
          {
            path: "authorize",
            element: <DailyAdjustmentAuthorizationPage />,
            roles: [
              Role.JEFE_COCINA,
              Role.SEGUNDO_COCINA,
              Role.JEFE_CP,
              Role.SEGUNDO_CP,
              Role.OPS,
              Role.QUALITY,
              Role.QUALITY_INNOVATION,
              Role.COMPRAS,
            ],
            companies: ["fdgy", "trofi"],
          },
        ],
      },
      {
        path: "report",
        element: <DailyAdjustmentReport />,
        roles: [
          Role.JEFE_COCINA,
          Role.SEGUNDO_COCINA,
          Role.JEFE_CP,
          Role.SEGUNDO_CP,
          Role.OPS,
          Role.QUALITY,
          Role.QUALITY_INNOVATION,
          Role.COMPRAS,
          Role.FINANZAS,
          Role.CONTABILIDAD,
          Role.PEOPLE,
        ],
        companies: ["fdgy", "trofi"],
      },
    ],
  },
  {
    path: "theorical-inventory",
    element: <TheoricalInventoryPage />,
    roles: [
      Role.JEFE_CP,
      Role.SEGUNDO_CP,
      Role.OPS,
      Role.QUALITY,
      Role.QUALITY_INNOVATION,
      Role.COMPRAS,
      Role.MARKETING,
    ],
    companies: ["fdgy", "trofi"],
    childrens: [
      {
        path: "comparison",
        element: <TheoreticalInvenotrycomparisonReport />,
        roles: [
          Role.JEFE_COCINA,
          Role.SEGUNDO_COCINA,
          Role.JEFE_CP,
          Role.SEGUNDO_CP,
          Role.OPS,
          Role.QUALITY,
          Role.QUALITY_INNOVATION,
          Role.COMPRAS,
        ],
        companies: ["fdgy", "trofi"],
      },
      {
        path: ":kitchenId",
        childrens: [
          {
            path: ":sku",
            element: <TheoricalInventoryMovementsPage />,
            companies: ["fdgy", "trofi"],
          },
        ],
      },
    ],
  },
  {
    path: "productions",
    childrens: [
      {
        path: ":kitchenId",
        element: <ProductionsPage />,
        roles: [
          Role.JEFE_COCINA,
          Role.SEGUNDO_COCINA,
          Role.COCINERO,
          Role.JEFE_CP,
          Role.SEGUNDO_CP,
          Role.COCINERO_CP,
          Role.FRANCHISE,
          Role.OPS,
          Role.QUALITY,
          Role.QUALITY_INNOVATION,
          Role.COMPRAS,
        ],
        companies: ["fdgy", "trofi"],
        childrens: [
          {
            path: "create",
            element: <ProductionCreatePage />,
            companies: ["fdgy", "trofi"],
          },
          {
            path: "edit/:productionId",
            element: <ProductionEditPage />,
            companies: ["fdgy", "trofi"],
          },
        ],
      },
      {
        path: "log",
        element: <ProductionLogPage />,
        companies: ["fdgy", "trofi"],
      },
      {
        path: "supply-reception",
        childrens: [
          {
            path: ":kitchenId",
            element: <ProductionSupplyReception />,
            roles: [Role.STORER],
            companies: ["fdgy"],
            childrens: [
              {
                path: "details/:supplyId",
                element: <ProductionSupplyReceptionDetails />,
                roles: [Role.STORER],
                companies: ["fdgy"],
              },
            ],
          },
        ],
      },
      {
        path: "liberation",
        childrens: [
          {
            path: ":kitchenId",
            element: <ProductionLiberationPage />,
            roles: [
              Role.STORER,
              Role.JEFE_CP,
              Role.QUALITY,
              Role.QUALITY_INNOVATION,
              Role.OPS,
              Role.SEGUNDO_CP,
            ],
            companies: ["fdgy", "trofi"],
          },
        ],
      },
    ],
  },
  {
    path: "supplies-management",
    element: <SuppliesManagement />,
    roles: rolesWithAllAccess,
    companies: ["fdgy", "trofi"],
  },
  {
    path: "transfers",
    childrens: [
      {
        path: ":kitchenId",
        element: <TransfersPage />,
        roles: [
          Role.JEFE_COCINA,
          Role.SEGUNDO_COCINA,
          Role.COCINERO,
          Role.JEFE_CP,
          Role.SEGUNDO_CP,
          Role.COCINERO_CP,
          Role.OPS,
          Role.QUALITY,
          Role.QUALITY_INNOVATION,
          Role.COMPRAS,
        ],
        companies: ["fdgy", "trofi"],
        childrens: [
          {
            path: "review-packed",
            element: <ReviewPackedTransferOrdersPage />,
            roles: [
              Role.JEFE_COCINA,
              Role.SEGUNDO_COCINA,
              Role.JEFE_CP,
              Role.SEGUNDO_CP,
              Role.COCINERO,
              Role.COCINERO_CP,
              Role.OPS,
              Role.QUALITY,
              Role.QUALITY_INNOVATION,
              Role.COMPRAS,
            ],
            companies: ["fdgy", "trofi"],
          },
          {
            path: "review",
            element: <TransferOrdersReviewPage />,
            companies: ["fdgy", "trofi"],
          },
          {
            path: "packing",
            element: <TransferOrderPickingPage />,
            roles: [
              Role.JEFE_CP,
              Role.SEGUNDO_CP,
              Role.STORER,
              Role.COCINERO_CP,
              Role.OPS,
              Role.QUALITY,
              Role.QUALITY_INNOVATION,
              Role.COMPRAS,
            ],
            companies: ["fdgy", "trofi"],
          },
        ],
      },
    ],
  },
  {
    path: "transfer-order",
    element: <TransferOrderPage />,
    roles: [
      Role.OPS,
      Role.QUALITY,
      Role.QUALITY_INNOVATION,
      Role.JEFE_CP,
      Role.SEGUNDO_CP,
    ],
    companies: ["fdgy", "trofi"],
    childrens: [
      {
        path: ":id",
        element: <TransferOrderDetailsPage />,
        roles: [
          Role.OPS,
          Role.QUALITY,
          Role.QUALITY_INNOVATION,
          Role.JEFE_CP,
          Role.SEGUNDO_CP,
        ],
        companies: ["fdgy", "trofi"],
      },
    ],
  },
  {
    path: "supplying",
    childrens: [
      {
        path: ":kitchenId",
        element: <SupplyingPage />,
        roles: [Role.STORER],
        companies: ["fdgy"],
        childrens: [
          {
            path: "detail/:supplyId",
            element: <SupplyingDetailPage />,
            roles: [Role.STORER],
            companies: ["fdgy"],
          },
        ],
      },
    ],
  },
  {
    path: "bc-integration",
    element: <BusinessCentralIntegration />,
    roles: [Role.COMPRAS, Role.CONTABILIDAD],
    companies: ["fdgy", "trofi"],
  },
  {
    path: "routes",
    childrens: [
      {
        path: "current",
        element: <CurrentRoutes />,
        roles: rolesWithAllAccess,
        companies: ["fdgy", "trofi"],
      },
    ],
  },
  {
    path: "vendor",
    element: <VendorPage />,
    roles: [Role.COMPRAS, Role.OPS],
    companies: ["fdgy", "trofi"],
    childrens: [
      {
        path: ":vendorId/supplies",
        element: <VendorSupplyByVendorPage />,
        roles: [Role.COMPRAS, Role.OPS],
        companies: ["fdgy", "trofi"],
      },
      {
        path: "sku/:sku/city/:city",
        element: <VendorBySkuAndCityPage />,
        roles: [Role.COMPRAS, Role.OPS],
        companies: ["fdgy", "trofi"],
      },
    ],
  },
  {
    path: "invoices",
    element: <PurchaseOrderInvoices />,
    roles: [Role.CONTABILIDAD, Role.COMPRAS],
    companies: ["fdgy"],
  },
  {
    path: "replacement",
    childrens: [
      {
        path: ":kitchenId",
        element: <ReplacementPage />,
        roles: [
          Role.JEFE_COCINA,
          Role.SEGUNDO_COCINA,
          Role.COCINERO,
          Role.JEFE_CP,
          Role.SEGUNDO_CP,
          Role.OPS,
        ],
        companies: ["fdgy", "trofi"],
      },
      {
        path: "authorize",
        element: <AuthorizeReplacementPage />,
        roles: [Role.OPS, Role.COMPRAS],
        companies: ["fdgy", "trofi"],
      },
    ],
  },
];
