import { KOSBaseTableHeader } from "@foodology-co/alejandria";
import { bcIntegrations, commons } from "app/i18n/types";
import { SaleError } from "core/BusinessCentralIntegration/entities/BusinessCentralIntegration";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import BcIntegrationTable from "./BcIntegrationTable";

interface Props {
  saleErrors: SaleError[];
  loading: boolean;
  archive: (id: string) => Promise<void>;
}

const SaleErrors: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { saleErrors, loading, archive } = props;

  const getSaleHeader = (): KOSBaseTableHeader[] => {
    return [
      {
        label: t(commons.COUNTRY),
        field: "country",
      },
      {
        label: t(commons.LOCATION),
        field: "kitchen",
      },
      {
        label: t(commons.CODE),
        field: "orderId",
      },
      {
        label: t(commons.DATE),
        field: "receivedAt",
        type: "datetime",
      },
      {
        label: t(commons.STATUS),
        field: "status",
      },
      {
        label: t(commons.ERROR),
        field: "error",
      },
    ];
  };

  return (
    <BcIntegrationTable
      data={saleErrors}
      headers={getSaleHeader()}
      loading={loading}
      title={t(bcIntegrations.SALES)}
      archive={archive}
    />
  );
};
export default SaleErrors;
