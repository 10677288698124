import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useAlert } from "app/hooks/useAlert";
import { commons, productionScannedError } from "app/i18n/types";
import {
  ProductionPlan,
  SlotEnum,
  SlotType,
} from "core/productions/entities/Plan";
import { findByKitchenAndScheduledDate } from "core/productions/repositories/http/Plan";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrentWeekNumber } from "utils/general";
import { column, DateToCalendar, dayOfWeek } from "utils/productionSchedule";
import { DragAndDropData } from "./Calendar";
import { ProductionDialogProps } from "./Manager/useHandleProductions";
import ProductionScheduleSlotContent from "./SlotContent";

interface Props {
  date: DateToCalendar;
  openAddProductionDialog: (props: ProductionDialogProps) => void;
  kitchenId: string;
  dragAndDropData: DragAndDropData;
  openInfoDialog: (planId: number) => void;
  onlyView?: boolean;
}

const ProductionScheduleDayOfWeek = (props: Props): React.ReactElement => {
  const {
    date,
    openAddProductionDialog,
    kitchenId,
    dragAndDropData,
    openInfoDialog,
    onlyView,
  } = props;

  const { t } = useTranslation();

  const alert = useAlert();

  const weekPrefix = t(commons.WEEK).toUpperCase().slice(0, 1);
  const weekNumber = getCurrentWeekNumber(date.date);
  const { dayLabel, dayNumber, isToday, str } = date;

  const [dateStr, setDateStr] = useState<string>();
  const [plans, setPlans] = useState<ProductionPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [resetDay, setResetDay] = useState<number>(0);

  useEffect(() => {
    setDateStr(str);
  }, [str]);

  const getData = (dateStr?: string) => {
    if (!dateStr) return;
    setLoading(true);
    setPlans([]);
    findByKitchenAndScheduledDate(kitchenId, dateStr)
      .then((result) => {
        setPlans(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(dateStr);
  }, [dateStr]);

  useEffect(() => {
    if (!resetDay) return;
    getData(dateStr);
  }, [resetDay]);

  const reloadDay = (plan: ProductionPlan) => {
    alert.warningWithMsg({
      title: t(productionScannedError.CAN_NOT_DELETE),
      message: plan.name,
    });
    setResetDay(new Date().getTime());
  };

  return (
    <Box sx={column}>
      <Box sx={dayOfWeek}>
        <Typography
          variant="h6"
          sx={{ color: isToday ? "#3C81DB" : "#121F56" }}
        >
          {t(`common.${dayLabel}`)}
        </Typography>
        {isToday ? (
          <Chip
            label={dayNumber}
            sx={{
              backgroundColor: "#3C81DB",
              color: "white",
              fontWeight: "bold",
            }}
          />
        ) : (
          <Typography variant="h6" sx={{ color: "#121F56" }}>
            {dayNumber}
          </Typography>
        )}
        <Typography
          variant="h6"
          sx={{
            backgroundColor: "#F1EFEF",
            color: "#7B7B7B",
            p: 1,
            borderRadius: 1,
          }}
        >
          {`${weekPrefix}${weekNumber}`}
        </Typography>
      </Box>
      {Object.keys(SlotEnum).map((slot) => (
        <ProductionScheduleSlotContent
          key={`ProductionScheduleSlotContent${dateStr}${slot}`}
          date={date}
          plans={plans}
          loading={loading}
          openAddProductionDialog={openAddProductionDialog}
          slot={slot as SlotType}
          dragAndDropData={dragAndDropData}
          openInfoDialog={openInfoDialog}
          reloadDay={reloadDay}
          onlyView={onlyView}
        />
      ))}
    </Box>
  );
};

export default ProductionScheduleDayOfWeek;
