import { KOSBaseTableHeader } from "@foodology-co/alejandria";
import { bcIntegrations, commons } from "app/i18n/types";
import { JournalError } from "core/BusinessCentralIntegration/entities/BusinessCentralIntegration";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import BcIntegrationTable from "./BcIntegrationTable";

interface Props {
  journals: JournalError[];
  loading: boolean;
  archive: (id: string) => Promise<void>;
}

const AdjustmentErrors: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { journals, loading, archive } = props;

  const getHeader = (): KOSBaseTableHeader[] => {
    return [
      {
        label: t(commons.COUNTRY),
        field: "country",
      },
      {
        label: t(commons.LOCATION),
        field: "location",
      },

      {
        label: t(commons.SKU),
        field: "sku",
      },
      {
        label: t(commons.QUANTITY),
        field: "quantity",
      },
      {
        label: t(commons.TYPE),
        field: "documentType",
      },
      {
        label: t(commons.STATUS),
        field: "status",
      },
      {
        label: t(commons.ERROR),
        field: "error",
      },
    ];
  };

  return (
    <BcIntegrationTable
      data={journals}
      headers={getHeader()}
      loading={loading}
      title={t(bcIntegrations.INVENTORY_ADJUSTMENTS)}
      archive={archive}
    />
  );
};
export default AdjustmentErrors;
