import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { measureUnitsConvert } from "app/utils/measureUnits";
import {
  ProductionDetail,
  ProductionRecord,
} from "core/productions/entities/Productions";
import { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { commons } from "../../../i18n/types";

interface Props {
  production: ProductionRecord;
  componentRef?: React.MutableRefObject<null>;
  prepareToPrint?: boolean;
}

const ProductionCardIngredients: FunctionComponent<Props> = (props) => {
  const { production, componentRef, prepareToPrint } = props;

  const { t } = useTranslation();

  const getProductionDetailQuantity = useCallback(
    (detail: ProductionDetail) => {
      let quantity = detail.requiredQuantity ?? 0;
      let unit = detail.unit;

      if (detail.displayUnit !== detail.unit) {
        quantity = Number(
          measureUnitsConvert(
            production.quantityToProduce,
            detail.unit,
            detail.displayUnit
          )
        );
        unit = detail.displayUnit;
      }

      if (production.status === "FINISHED") {
        quantity = detail.usedQuantity ?? 0;
        unit = detail.unit;
      }

      if (quantity % 1 !== 0) {
        return `${quantity.toFixed(2)} ${unit}`;
      } else {
        return `${quantity} ${unit}`;
      }
    },
    [production]
  );

  const quantity =
    production.status === "FINISHED"
      ? production.producedQuantity
      : production.quantityToProduce;

  return (
    <Box key={`ProductionCardIngredients-${production.id}`} ref={componentRef}>
      {prepareToPrint && (
        <>
          <Typography
            fontWeight={700}
            component="div"
            bgcolor="#eeeeee"
            color="primary.main"
            width="100%"
            pl={1}
            mb={1}
          >
            {production.name}
          </Typography>

          <Typography
            fontWeight={700}
            component="div"
            bgcolor="#eeeeee"
            color="primary.main"
            width="100%"
            pl={1}
            mb={1}
          >
            {`${t(commons.QUANTITY)}: ${quantity} ${production.unit}`}
          </Typography>
        </>
      )}

      <Typography
        fontWeight={700}
        component="div"
        bgcolor="#eeeeee"
        color="primary.main"
        width="100%"
        pl={1}
        mb={1}
      >
        {t(commons.INGREDIENTS)}
      </Typography>

      <Box>
        {production.productionDetails.map((productionDetail, i) => (
          <Box
            key={`ProductionCardIngredientsDetail-${productionDetail.id}`}
            display="flex"
            justifyContent="space-between"
            py={0.5}
            px={1}
            gap={2}
            alignItems="center"
            bgcolor={i % 2 === 0 ? "background.paper" : "#eeeeee"}
          >
            <Typography>{productionDetail.name}</Typography>
            <Typography sx={{ flexShrink: 0 }} fontWeight={700}>
              {getProductionDetailQuantity(productionDetail)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ProductionCardIngredients;
