import { Actions } from "utils/modal";
import { FormState } from "./constants";
import { HandleForm } from "./form";

interface Props {
  action: Actions;
  onClose: () => void;
  currentPlan?: FormState;
  onlyQuantity?: boolean;
  refreshCalendar?: () => void;
  askForReason: boolean;
}

export const EditDialogContent = (props: Props) => {
  const {
    action,
    onClose,
    currentPlan,
    refreshCalendar,
    onlyQuantity,
    askForReason,
  } = props;
  return (
    <HandleForm
      action={action}
      onClose={onClose}
      initialValues={currentPlan}
      refreshCalendar={refreshCalendar}
      onlyQuantity={onlyQuantity}
      askForReason={askForReason}
    />
  );
};
