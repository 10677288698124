import { FoodologyCountry } from "core/country/entities/Country";
import { Station } from "core/productions/entities/Station";
import { ApiVersion, getHttp } from "utils/http";

const byCountry = async (country: FoodologyCountry): Promise<Station[]> => {
  const response = await getHttp(
    process.env.REACT_APP_BISTRO_KEEPER_API_BASE_URL ?? "",
    ApiVersion.V1,
    `station/type/country/${country}`
  );
  const data = await response.json();
  return data.data ?? [];
};

const StationService = { byCountry };

export default StationService;
