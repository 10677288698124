import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import { commons } from "app/i18n/types";
import { Station } from "core/productions/entities/Station";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import SelectStationCard from "./SelectStationCard";

interface Props {
  stations: Station[];
  onFilter: (station: Station | null) => void;
  onClose: VoidFunction;
  loading: boolean;
  previousSelection: Station | null;
}
const SelectStationContent = ({
  stations,
  onFilter,
  onClose,
  loading,
  previousSelection,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  const [selectedStation, setSelectedStation] = useState<Station | null>(null);

  const selectStation = (station: Station | null) => {
    setSelectedStation(station);
  };

  useMount(() => {
    if (previousSelection) {
      setSelectedStation(previousSelection);
    }
  });

  return (
    <DialogContent sx={{ width: "100%", maxHeight: 700, pb: 3 }}>
      <Stack direction="column" gap={2}>
        <Grid container spacing={2} columnSpacing={2}>
          {stations.map((station) => (
            <Grid item xs={3} key={`SelectStationContent${station.id}`}>
              <SelectStationCard
                onClick={selectStation}
                station={station}
                selectedStation={selectedStation}
              ></SelectStationCard>
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Stack direction="row" gap={2} mt={2}>
        <Button
          variant="outlined"
          color="error"
          startIcon={<ClearIcon />}
          fullWidth
          onClick={onClose}
          disabled={loading}
        >
          {t(commons.CANCEL)}
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            onClose();
            onFilter(selectedStation);
          }}
          startIcon={<CheckIcon />}
        >
          {t(commons.ACCEPT)}
        </Button>
      </Stack>
    </DialogContent>
  );
};

export default SelectStationContent;
