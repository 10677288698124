/* eslint-disable no-template-curly-in-string */
import {
  EventReasonType,
  Locales,
  adjustmentType,
  adjustmentsModule,
  bcIntegrations,
  bulkLoadAction,
  bulkLoadError,
  bulkLoadModule,
  commentArea,
  commons,
  countingTypeSelector,
  dailyAdjustmentsModule,
  dailyAdjustmentsReport,
  errorCode,
  general,
  inventoryGroups,
  inventoryMovement,
  inventoryMovementType,
  kitchenInventoryManagement,
  massiveTransfer,
  measureUnits,
  missingReason,
  organoleptics,
  physicalCountModule,
  physicalCountSchedule,
  productionDialog,
  productionLiberation,
  productionLogReason,
  productionManagement,
  productionModifyReason,
  productionPreparation,
  productionScannedError,
  productionSchedule,
  purchaseOrderConciliation,
  purchaseOrderCreation,
  purchaseOrderLine,
  purchaseOrderModule,
  receiptType,
  replacementModule,
  status,
  suppliesManagerModule,
  supplyManagement,
  supplyReception,
  supplyingManagement,
  takePhotoLocale,
  theoricalInventoryModule,
  transferModule,
  transferOrderDetails,
  transferOrderModule,
  transferOrdersPackedReviewTable,
  transferPackingDialog,
  vendor,
  vendorMeasureUnit,
} from "./types";

export const enLocale: Locales = {
  [status.IN_PROCESS]: "IN PROCESS",
  [status.ARCHIVED]: "ARCHIVED",
  [status.RELEASED]: "RELEASED",
  [status.PACKED]: "PACKED",
  [status.IN_TRANSIT]: "IN TRANSIT",
  [status.RECEIVED]: "RECEIVED",
  [status.RETURNED]: "RETURNED",
  [status.PARTIAL_RECEIVED]: "PARTIAL RECEIVED",
  [status.ALL]: "ALL",
  [status.FINISHED]: "FINISHED",
  [status.SCHEDULED]: "SCHEDULED",
  [status.VALIDATED]: "VALIDATED",

  [inventoryGroups.BEER]: "BEER",
  [inventoryGroups.ADJUSTABLE_PACKAGING]: "ADJUSTABLE PACKAGING",
  [inventoryGroups.ALCOHOLIC_DRINK]: "ALCOHOLIC DRINKS",
  [inventoryGroups.BAKERY]: "BAKERY",
  [inventoryGroups.CIGARETTES]: "CIGARETTES",
  [inventoryGroups.COMBO]: "COMBO",
  [inventoryGroups.DAIRY]: "DAIRY",
  [inventoryGroups.DRINK]: "DRINKS",
  [inventoryGroups.FINISHED_PRODUCT]: "FINISHED PRODUCT",
  [inventoryGroups.FRUITS]: "FRUITS AND VEGETABLES",
  [inventoryGroups.GROCERY]: "GROCERIES",
  [inventoryGroups.MAINTENANCE]: "MAINTENANCE",
  [inventoryGroups.MEAT]: "MEAT",
  [inventoryGroups.PACKAGING]: "PACKAGING",
  [inventoryGroups.PRODUCTION]: "PRODUCTION",
  [inventoryGroups.SUPPLY]: "SUPPLY",
  [inventoryGroups.UTENSILS]: "UTENSILS",

  [commons.DAILY_INDIVIDUAL]: "Individual daily",
  [commons.DAILY_MASSIVE]: "Massive daily",
  [commons.DAILY]: "Daily",
  [commons.WEEKLY]: "Weekly",
  [commons.MONTHLY]: "Monthly",
  [commons.RE_COUNT]: "Recount",
  [commons.OTHER]: "Other",
  [commons.OTHER_INDIVIDUAL]: "Other individual",
  [commons.OTHER_MASSIVE]: "Other massive",
  [commons.LOADING]: "Loading",
  [commons.ACCEPT]: "Accept",
  [commons.INVENTORY]: "Inventory log",
  [commons.PURCHASE_ORDER]: "Purchase Order Reception",
  [commons.FILTERS]: "Filters",
  [commons.RETURN]: "Return",
  [commons.RETURN2]: "Return",
  [commons.FINISH]: "Finish",
  [commons.START]: "Start",
  [commons.FINISHED]: "Finished",
  [commons.FINISHED_PLURAL]: "Finished",
  [commons.NAME]: "Name",
  [commons.MEASURE_UNIT]: "Unit of measure",
  [commons.SKU]: "SKU",
  [commons.STATUS]: "Status",
  [commons.GROUP]: "Group",
  [commons.DOES_NOT_APPLY]: "Does not apply",
  [commons.DETAILS]: "More details",
  [commons.SHOW_DETAILS]: "Show details",
  [commons.CATEGORY]: "Category",
  [commons.CATEGORIES]: "Categories",
  [commons.CONTINUE]: "Continue",
  [commons.RECEIVE]: "Receive",
  [commons.PROGRESS]: "Progress",
  [commons.DELIVERY_DATE]: "Delivery date",
  [commons.ORDER_DATE]: "Order date",
  [commons.SUPPLIER]: "Supplier",
  [commons.ORDER_ID]: "Order ID",
  [commons.RECEIPT_ID]: "Receipt ID",
  [commons.PRODUCT_NAME]: "Product name",
  [commons.REQUESTED]: "Requested",
  [commons.RECEIVED]: "Received",
  [commons.UNIT]: "Unit",
  [commons.UNITS]: "Units",
  [commons.UNIT_PRICE]: "Unit price",
  [commons.TOTAL_PRICE]: "Total price",
  [commons.ACTIONS]: "Actions",
  [commons.ACTION]: "Action",
  [commons.INIT_DATE]: "Start date",
  [commons.FINAL_DATE]: "End date",
  [commons.SEARCH]: "Search",
  [commons.EXPORT]: "Export",
  [commons.CANCEL]: "Cancel",
  [commons.DELETE]: "Delete",
  [commons.MARK_RECEIVED]: "Order Received",
  [commons.SAVE]: "Save",
  [commons.BACK]: "Back",
  [commons.KITCHENS]: "Kitchens",
  [commons.KITCHEN]: "Kitchen",
  [commons.COUNTRIES]: "Countries",
  [commons.TYPE]: "Type",
  [commons.SELECT_FILE]: "Select file",
  [commons.SELECT_TEMPLATE]: "You must select the template",
  [commons.SELECT_MOVE_FILE]: "Select or drag the file",
  [commons.SELECT_MOVE_TEMPLATE]: "Select or drag the template",
  [commons.SELECTED_FILE]: "Selected file",
  [commons.UPLOAD_FILE]: "Upload file",
  [commons.UPLOAD_EVIDENCE]: "Upload evidence",
  [commons.MAKE_ADJUSTMENT]: "Make Adjustment",
  [commons.QUANTITY]: "Quantity",
  [commons.LAST_MODIFIED]: "Last Modified",
  [commons.PERSON_IN_CHARGE]: "Responsible",
  [commons.REASON]: "Reason",
  [commons.RETRY]: "Retry",
  [commons.DATETIME]: "Date / Time",
  [commons.AUTHORIZE]: "Authorize",
  [commons.EMPTY_RESULT]: "No results found, try again",
  [commons.BACK_BUTTON]: "Back",
  [commons.FIND_INPUT]: "Input Finder",
  [commons.FIND_LOCATION_TITLE]: "Kitchen Finder",
  [commons.FIND_LOCATION_INPUT]: "Search Kitchen",
  [commons.CONFIRM]: "Confirm",
  [commons.ENTER_QUANTITY]: "Enter quantity",
  [commons.CONFIRMATION]: "CONFIRM",
  [commons.VALIDATE]: "VALIDATE",
  [commons.SHOW_ALL]: "SHOW ALL",
  [commons.ALL]: "ALL",
  [commons.NEW_PRODUCTION]: "NEW PRODUCTION",
  [commons.PRODUCTION_QUANTITY]: "Production Quantity",
  [commons.INGREDIENT_NAME]: "INGREDIENT NAME",
  [commons.REQUIRED_QUANTITY]: "REQUIRED QUANTITY",
  [commons.EXISTING_QUANTITY]: "EXISTING QUANTITY",
  [commons.USED_QUANTITY]: "Used quantity",
  [commons.FINAL_QUANTITY]: "Final Quantity",
  [commons.EXISTENCE]: "Existence",
  [commons.ENTER]: "ENTER",
  [commons.UPDATE]: "Update",
  [commons.SELECT_COUNTRY]: "Country",
  [commons.SELECT_CITY]: "City",
  [commons.SELECT_LOCATION]: "Location",
  [commons.SELECT_LOCATIONS]: "Select the location",
  [commons.INGREDIENTS]: "Ingredients",
  [commons.SELECTED]: "Selected",
  [commons.SCHEDULE_DATE]: "Scheduled date",
  [commons.VALIDATED]: "Validated",
  [commons.WITHOUT_INVOICE]: "Without invoice",
  [commons.ERROR_IN_PRICE]: "Error in price",
  [commons.ERROR_IN_QUANTITY]: "Error in quantity",
  [commons.ERROR_IN_PRICE_AND_QUANTITY]: "Error in quantity and price",
  [commons.ACCOUNTED]: "Accounted",
  [commons.PRE_VALIDATED]: "Pre-validated",
  [commons.RECIVED_QUANTITY]: "Received quantity",
  [commons.CORRECTED]: "Corrected",
  [commons.HOUR]: "Hour",
  [commons.TABLE_EMPTY]: "No records to show",
  [commons.PARTIAL]: "Partial",
  [commons.PARTIAL_RECEIVED]: "Partial",
  [commons.PENDING]: "Pending",
  [commons.COMPLETE]: "Complete",
  [commons.PARTIAL_PLURAL]: "Partials",
  [commons.PENDING_PLURAL]: "Pendings",
  [commons.COMPLETE_PLURAL]: "Completes",
  [commons.REMAINING]: "Remaining",
  [commons.SENT]: "Sent",
  [commons.SEND_DATE]: "Send date",
  [commons.SUPPLIES]: "Supplies",
  [commons.SUPPLY]: "Supply",
  [commons.ACCURACY]: "Accuracy",
  [commons.FIND_SUPPLY_LABEL]: "Search Supply",
  [commons.SHOWING]: "Showing",
  [commons.SELECT_OPTION]: "Select the information you want to consult",
  [commons.SELECT_COUNTRY_CITY_KITCHEN]:
    "Use the filters to select the information of the country, city, or kitchen you want to consult.",
  [commons.DATE]: "Date",
  [commons.REPORTED_AMOUNT]: "Reported amount",
  [commons.SEND_TO_VALIDATION]: "Request validation",
  [commons.TO_AUTHORIZE]: "To authorize",
  [commons.REPORT]: "Report",
  [commons.FOR_VALIDATE]: "For validation",
  [commons.CLOSE]: "Close",
  [commons.SAVE_CHANGES]: "Save changes",
  [commons.WEEK]: "Week",
  [commons.AMOUNT_SET_ASIDE]: "Amount set aside",
  [commons.TRANSFER_DATE]: "Transfer date",
  [commons.PROGRAMMED_KITCHENS]: "Scheduled Kitchens",
  [commons.TOTAL_SUPPLIES]: "Total Supplies",
  [commons.PROGRAMMED_BY]: "Scheduled by",
  [commons.PACKING_PROGRESS]: "Packing progress",
  [commons.PACKING_PROGRESS_SUPPLIES]:
    "{{packing}} of {{total}} supplies ({{percentage}})",
  [commons.PACKING_PROGRESS_KITCHENS]:
    "{{packing}} of {{total}} kitchens ({{percentage}})",
  [commons.SCANNED]: "Scanned",
  [commons.SCANNED_SUCCESSFULLY]: "Scanned successfully",
  [commons.SCANNING_ERROR]: "Scanning error",
  [commons.SEARCH_RESULT]: "Search result",
  [commons.RESULTS_FOR]: "Results for",
  [commons.READING_BARCODE_SCANNER]: "Barcode scanner reading",
  [commons.PRODUCTION_ALREADY_ENTERED]:
    "This production has already been entered",
  [commons.EMAIL_LABEL]: "Email",

  [commons.ERROR_TITLE]: "The page does not exist or you do not have access...",
  [commons.ERROR_SUBTITLE]: "Please contact the administrator.",
  [commons.ADD]: "Add",
  [commons.DOCUMENT_CODE]: "Document code",
  [commons.PRINT_LABELS]: "Print labels",
  [commons.PACK]: "Pack",
  [commons.PRINT]: "Print",
  [commons.SUPPLIES_FILTER]: "Filter supplies",
  [commons.VIEW_MODES]: "View modes",
  [commons.FOR_SUPPLY]: "By supply",
  [commons.FOR_KITCHEN]: "By kitchen",
  [commons.ORDER_DETAILS]: "Order details",
  [commons.ENTER_INVOICE_OR_REMISSION_NO]: "Enter Invoice/Remission No.",
  [commons.DECLINE]: "Decline",
  [commons.FILE_TOO_BIG]: "File too large (max {{size}}MB)",
  [commons.ORDER_RECEIPT]: "Receive order",
  [commons.RECEIPT]: "Receipt",
  [commons.CHANGE_STATUS]: "Change status",
  [commons.SELECT_A_NEW_STATUS]: "Select the new status for this order",
  [commons.VIEW_PDF]: "View PDF",
  [commons.PDF]: "PDF",
  [commons.MODIFY]: "Modify",
  [commons.COMMENT]: "Comment",
  [commons.PRODUCTIONS]: "Productions",
  [commons.PRODUCTION]: "Production",
  [commons.MONDAY]: "Monday",
  [commons.TUESDAY]: "Tuesday",
  [commons.WEDNESDAY]: "Wednesday",
  [commons.THURSDAY]: "Thursday",
  [commons.FRIDAY]: "Friday",
  [commons.SATURDAY]: "Saturday",
  [commons.SUNDAY]: "Sunday",
  [commons.TODAY]: "Today",
  [commons.TO_THE]: "to",
  [commons.JANUARY]: "January",
  [commons.FEBRUARY]: "February",
  [commons.MARCH]: "March",
  [commons.APRIL]: "April",
  [commons.MAY]: "May",
  [commons.JUNE]: "June",
  [commons.JULY]: "July",
  [commons.AUGUST]: "August",
  [commons.SEPTEMBER]: "September",
  [commons.OCTOBER]: "October",
  [commons.NOVEMBER]: "November",
  [commons.DECEMBER]: "December",
  [commons.IMPORT]: "Import",
  [commons.MASSIVE]: "Massive",
  [commons.INDIVIDUAL]: "Individual",
  [commons.TIME]: "Time",
  [commons.NO_SEARCH_RESULTS]: "No matches found for: {{search}}",
  [commons.ID]: "ID",
  [commons.PREPARE]: "Prepare",
  [commons.QUANTITY_ERROR]: "Quantity must be greater than 0",
  [commons.CODE]: "Code",
  [commons.ERROR]: "Error",
  [commons.ACTIVATE]: "Activate",
  [commons.INACTIVATE]: "Inactivate",
  [commons.EXIT]: "Exit",
  [commons.ARCHIVE]: "Archive",
  [commons.RESOURCES]: "Resources",
  [commons.ROUTES]: "Routes",
  [commons.COUNTRY]: "Country",
  [commons.CITY]: "City",
  [commons.FILTER_BY_CITY]: "Filter by city",
  [commons.LOCATION]: "Location",
  [commons.LABELS]: "Labels",
  [commons.VIEW_LABELS]: "View Labels",
  [commons.RETURNED]: "Returned",
  [commons.ADDRESS]: "Address",
  [commons.PHONE]: "Phone",
  [commons.YES]: "Yes",
  [commons.NO]: "No",
  [commons.REQUIRED_FIELD]: "Required field",
  [commons.SUCCESSFUL_PROCESS]: "Process completed successfully.",
  [commons.FAILED_PROCESS]: "An error occurred in the process.",
  [commons.SKU_TYPE_CITY_ALREADY_EXIST]:
    "The categorization for this supply already exists in this city",
  [commons.CONTENT_IS_EMPTY]: "Content is empty",
  [commons.SEND]: "Send",
  [commons.BETTER]: "Better",
  [commons.BUYER]: "Buyer",
  [commons.DOWNLOAD_PDFS]: "Download PDFs",
  [commons.THE_PROCESS_CONTINUES]: "Continue the process",
  [commons.A_SIMILAR_RECORD_ALREADY_EXIST]: "A similar record already exists",
  [commons.BULK_LOAD]: "Bulk load",
  [commons.TEMPLATE_VIEW]: "View/Download template",
  [commons.ERROR_LINE]: "Error on line {{index}}: {{error}}",
  [commons.EMPTY_ERROR]: "There are empty fields",
  [commons.RESET]: "Reset",
  [commons.SUCCESSFUL]: "Successful",
  [commons.FAILED]: "Failed",
  [commons.RESULT]: "Result",
  [commons.ONLY_FILE_TYPE]: "Only files of type: {{type}}",
  [commons.DOCUMENT]: "Document",
  [commons.VENDOR_INFO]: "Vendor Information",
  [commons.RECORD]: "Record",
  [commons.OF]: "of",
  [commons.SEEKER]: "Seeker",
  [commons.FINISHED_PRODUCTS]: "Finished products",
  [commons.AUTOGENERATE]: "Auto-generate",
  [commons.LINK]: "Link",
  [commons.SUBTOTAL]: "Subtotal",
  [commons.INVOICE]: "Invoice",
  [commons.WITH_INVOICE]: "With Invoice",
  [commons.ITEMS]: "Items",
  [commons.UNLINK]: "Unlink",
  [commons.VENDOR]: "Vendor",
  [commons.OPS_REVIEW]: "Review OPS",
  [commons.LINKED]: "Linked",
  [commons.FREEZING]: "Freezing ( -18ºC )",
  [commons.REFRIGERATION]: "Refrigeration (1 ºC - 5ºC)",
  [commons.ROOM_TEMPERATURE]: "Room temperature (15ºC - 30ºC)",
  [commons.PRODUCTS]: "Products",
  [commons.ORIGIN]: "Origin",
  [commons.SELECT_STATION]: "Select station",
  [commons.TOTAL]: "Total",

  [measureUnits.UN]: "Unit",
  [measureUnits.G]: "Grams",
  [measureUnits.GR]: "Grams",
  [measureUnits.ML]: "Milliliters",
  [measureUnits.KG]: "Kilograms",
  [measureUnits.PCS]: "Pieces",

  [receiptType.ALL]: "All",
  [receiptType.PURCHASE_ORDER]: "Purchase Order",
  [receiptType.INBOUND_TRANSFER]: "Inbound Transfer",
  [receiptType.PENDING]: "Pending",
  [receiptType.RECEIVED]: "Received",
  [receiptType.DETAIL_TITLE]: "Receipt Detail",

  [adjustmentType.LOSS]: "Loss",
  [adjustmentType.LOSSES]: "Losses",
  [adjustmentType.DISCARDED]: "Discarded",
  [adjustmentType.DISCARDEDS]: "Discards",
  [adjustmentType.INTERNAL_CONSUMPTION]: "Internal consumption",
  [adjustmentType.FINISHED_PRODUCT]: "Finished product",
  [adjustmentType.PRODUCTION_REJECTED]: "Rejected production",
  [adjustmentType.EVENT]: "Event",
  [adjustmentType.EVENTS]: "Events",
  [adjustmentType.SALE]: "Sale",
  [adjustmentType.PRODUCTION]: "Production",
  [adjustmentType.PURCHASE_ORDER]: "Purchase order",
  [adjustmentType.COUNTING]: "Counting",
  [adjustmentType.INITIAL]: "Initial",
  [adjustmentType.ADJUSTMENT]: "Adjustments",
  [adjustmentType.MANUAL_ADJUSTMENT]: "Manual Adjustment",
  [adjustmentType.VALIDATION_ADJUSTMENT]: "Validation Adjustment",
  [adjustmentType.COUNTING_ADJUSTMENT]: "Counting Adjustment",
  [adjustmentType.SALE_CANCELLATION]: "Cancelled sale",
  [adjustmentType.UPDATE_PURCHASE_ORDER_LINE]: "Purchase order adjustment",
  [adjustmentType.DAILY_COUNTING_ADJUSTMENT]: "Daily counting adjustment",
  [adjustmentType.TRANSFER]: "Transfer Order",
  [adjustmentType.POSITIVE]: "Positive",
  [adjustmentType.NEGATIVE]: "Negative",
  [adjustmentType.TRANSFORM]: "Transform",

  [inventoryMovementType.EGRESS]: "Outflow",
  [inventoryMovementType.ENTRY]: "Entry",

  [EventReasonType.DAMAGED]: "Damaged product",
  [EventReasonType.EXPIRED]: "Expired product",
  [EventReasonType.OBSOLETE]: "Obsolete product",
  [EventReasonType.TRAINING]: "Training",
  [EventReasonType.MARKETING]: "Marketing process",
  [EventReasonType.QUALITY_TESTS]: "Quality tests",

  [physicalCountModule.TITLE]: "Inventory Log",
  [physicalCountModule.SUBTITLE]:
    "Enter the number of items available in the inventory.",
  [physicalCountModule.HISTORY_SUBTITLE]:
    "Select the physical count you want to continue or create a new one.",
  [physicalCountModule.CONFIRMATION_FINISHED_TITLE]: "Deviation Detected",
  [physicalCountModule.CONFIRMATION_MODAL_DESCRIPTION]:
    "The entered quantity has a high deviation compared to the theoretical inventory. Please validate the count or confirm to continue.",
  [physicalCountModule.COUNTING_FINISHED_TITLE]: "Physical count completed.",
  [physicalCountModule.HISTORIAL]: "Counting History",
  [physicalCountModule.ADD_QUANTITY]: "Add quantity",
  [physicalCountModule.ADD_QUANTITY_DESCRIPTION]:
    "Add the quantity you want to add to your count.",
  [physicalCountModule.COUNTING_FINISHED_SUBTITLE]:
    "The physical count has been successfully completed.",
  [physicalCountModule.FILTER_SEARCH_PLACEHOLDER]: "Item name",
  [physicalCountModule.DISARD_ITEM_MODAL_TITLE]: "Discard item",
  [physicalCountModule.DISARD_ITEM_MODAL_DESCRIPTION]:
    "The item will no longer be listed in your inventory. Please confirm if you wish to continue.",
  [physicalCountModule.EMPTY_COUNTINGS]:
    "You don't have scheduled inventory counts",
  [physicalCountModule.CONSOLIDATED_EXPORT_TITLE]: "Consolidated Report",
  [physicalCountModule.CONSOLIDATED_EXPORT_SUBTITLE]:
    "Enter the dates to generate the consolidated report.",
  [physicalCountModule.FINISH_ITEM_MODAL_TITLE]: "Finish Count",
  [physicalCountModule.FINISH_ITEM_MODAL_DESCRIPTION]:
    "This item's count will be finished. Please confirm if you wish to continue.",
  [physicalCountModule.CONFIRM_FINISH_COUNTING_TITLE]: "Finish count",
  [physicalCountModule.CONFIRM_FINISH_COUNTING_MESSAGE]:
    "Do you want to finish the count? Once finished, the quantities cannot be modified.",
  [physicalCountModule.SCHEDULED]: "Scheduled",
  [physicalCountModule.IN_PROCESS]: "In process",
  [physicalCountModule.FINISHED]: "Finished",
  [physicalCountModule.ARCHIVE_COUNTING_TITLE]: "Archive count",
  [physicalCountModule.ARCHIVE_COUNTING_MESSAGE]:
    "Do you want to archive the count? It cannot be recovered.",
  [physicalCountModule.RELOAD_PAGE]: "Reload page",
  [physicalCountModule.REPLACE_VALUE]: "Replace value",
  [physicalCountModule.PRODUCT_EDIT_WARNING]:
    "<b>{{firstName}}</b> <b>{{lastName}}</b> entered a value of <b>{{quantity}}</b> for <b>{{SKU}}</b> <b>{{name}}</b> before you, please review the final value and enter it again.",
  [physicalCountModule.COUNTING_CONFLICT]: "Counting conflict",
  [physicalCountModule.SEARCH_ITEM_NAME]:
    "Enter the name of the item you want to search for.",
  [physicalCountModule.SEARCH_ACTION]: "Perform Search",
  [physicalCountModule.SELECT_ALL]: "Select all",
  [physicalCountModule.FILTER_UNCOUNTED_ITEMS]: "Have not been counted",

  [purchaseOrderModule.PROGRESS]: "Reception Progress",
  [purchaseOrderModule.RECORDS_PAGE_TITLE]: "Purchase Order Reception",
  [purchaseOrderModule.RECORDS_PAGE_SUBTITLE]:
    "List of pending orders to receive.",
  [purchaseOrderModule.DETAIL_PAGE_TITLE]: "Purchase order lines",
  [purchaseOrderModule.FIND_ORDERS]: "Search purchase order",

  [purchaseOrderModule.ORDER_ID]: "Order No.",
  [purchaseOrderModule.RECEPTION]: "Reception No.",
  [purchaseOrderModule.TYPE]: "Order type",
  [purchaseOrderModule.REQUEST_DATE]: "Request Date",
  [purchaseOrderModule.ITMES]: "Requested Items",
  [purchaseOrderModule.UNIT]: "UNIT",
  [purchaseOrderModule.FIND_ITEM]: "Search Item",
  [purchaseOrderModule.STATUS_RECEIPT]: "Receipt status",
  [purchaseOrderModule.ADD_DOCUMENTS]: "Add documents",
  [purchaseOrderModule.DOCUMENTS_LIST_TITLE]: "Uploaded documents",
  [purchaseOrderModule.DOCUMENTS_LIST_INFO]:
    "View or download the document you need.",
  [purchaseOrderModule.MARK_ASRECEIVED_TITLE]: "Purchase order received",
  [purchaseOrderModule.MARK_ASRECEIVED_MESSAGE]:
    "The purchase order will be marked as received and pending reconciliation",
  [purchaseOrderModule.DELETE_RECEIPT_TITLE]: "Delete Reception Order",
  [purchaseOrderModule.DELETE_RECEIPT_MESSAGE]:
    "Do you want to delete reception order number {{receiptNo}}",
  [purchaseOrderModule.RECEIPT]: "Receive purchase order",
  [purchaseOrderModule.TITLE]: "Review purchase orders",
  [purchaseOrderModule.DESCRIPTION]:
    "Review requested purchase orders from suppliers.",
  [purchaseOrderModule.FILTER_ORDERS]: "Filter purchase orders",
  [purchaseOrderModule.FIND_ORDER_LABEL]: "Order ID / Supplier name",
  [purchaseOrderModule.PARTIAL_ORDERS]: "Partial orders",
  [purchaseOrderModule.PENDING_ORDERS]: "Pending orders",
  [purchaseOrderModule.COMPLETE_ORDERS]: "Complete orders",
  [purchaseOrderModule.PERCENTAGE_TO_ORDERS]: "{{percentage}}% of total orders",
  [purchaseOrderModule.ADD_DOCUMENTS_LABEL_INFO]:
    "Take a photo or select the document",
  [purchaseOrderModule.INVOICES]: "Invoices",
  [purchaseOrderModule.DOWNLOAD_OCS]: "Purchase Orders",
  [purchaseOrderModule.DOWNLOAD_LINES]: "Purchase order details",
  [purchaseOrderModule.INVOICE_ID]: "Invoice ID",
  [purchaseOrderModule.VENDOR_CODE]: "Vendor code",
  [purchaseOrderModule.NOTES]: "Notes",
  [purchaseOrderModule.INVOICE_TITLE]: "Manage invoices",
  [purchaseOrderModule.INVOICE_DESCRIPTION]:
    "Manage received invoices and associate purchase orders",
  [purchaseOrderModule.FILTER_INVOICES]: "Filter invoices",
  [purchaseOrderModule.IMPORT_TITLE]: "Import invoice xml",
  [purchaseOrderModule.WATCH_ORDER]: "View Order",
  [purchaseOrderModule.LINK_ORDER]: "Associate",
  [purchaseOrderModule.INVOICE_REPORT]: "Invoices",
  [purchaseOrderModule.INVOICE_REPORT_DETAILED]: "Invoice details",
  [purchaseOrderModule.CONCILLIATION_CREATE_ADJUSTMENT]: "Generate adjustment",
  [purchaseOrderModule.CONCILLIATION_CREATE_ADJUSTMENT_DESCRIPTION]:
    "Enter the total invoice value to calculate the adjustment value",
  [purchaseOrderModule.CONCILLIATION_CREATE_ADJUSTMENT_MAX]:
    "The maximum allowed adjustment percentage is {{percentage}}%",
  [purchaseOrderModule.CONCILLIATION_CREATE_ADJUSTMENT_VALUE]:
    "An adjustment will be generated for the purchase order of {{value}}",

  [suppliesManagerModule.TITLE]: "Inventory Supplies Management",
  [suppliesManagerModule.SUBTITLE]:
    "Activate or deactivate supplies for inventory counting.",
  [suppliesManagerModule.MASSIVE_SUCCESS_TITLE]: "Supplies Management",
  [suppliesManagerModule.MASSIVE_SUCCESS_MESSAGE]:
    "Supplies updated successfully.",

  [adjustmentsModule.TITLE]: "Inventory Adjustments",
  [adjustmentsModule.SUBTITLE]: "Bulk inventory adjustment upload.",
  [adjustmentsModule.INSTRUCTIONS]: "Instructions",
  [adjustmentsModule.INSTRUCTIONS_DETAILS]:
    "Import the template with the corresponding adjustments to update the actual inventory.",
  [adjustmentsModule.BOX_DETAILS]:
    "Select or drag the template with the adjustments",
  [adjustmentsModule.MSG_OK_START_PROCCESS]: "Process successfully started",
  [adjustmentsModule.MSG_FAIL_START_PROCCESS]: "Process failed",
  [adjustmentsModule.SUCCESSFULLY_SAVE]: "Adjustment saved successfully",

  [dailyAdjustmentsModule.TITLE]: "Adjustments Record",
  [dailyAdjustmentsModule.TITLE_DISCARDED]: "Scrap Record",
  [dailyAdjustmentsModule.SUBTITLE_DISCARDED]:
    "Register the scraps generated in kitchen products.",
  [dailyAdjustmentsModule.INSTRUCTIONS_DISCARDED]:
    "Search for the product you want to report the scrap for.",

  [dailyAdjustmentsModule.TITLE_LOSS]: "Loss Record",
  [dailyAdjustmentsModule.SUBTITLE_LOSS]:
    "Register the losses in kitchen products.",
  [dailyAdjustmentsModule.INSTRUCTIONS_LOSS]:
    "Search for the product you want to report the loss for.",

  [dailyAdjustmentsModule.TITLE_CONSUMPTION]: "Consumption Record",
  [dailyAdjustmentsModule.SUBTITLE_CONSUMPTION]:
    "Register the consumptions generated from kitchen products.",
  [dailyAdjustmentsModule.INSTRUCTIONS_CONSUMPTION]:
    "Search for the product you want to report the consumption for.",

  [dailyAdjustmentsModule.TITLE_EVENT]: "Event Record",
  [dailyAdjustmentsModule.SUBTITLE_EVENT]:
    "Register the events generated from kitchen products.",
  [dailyAdjustmentsModule.INSTRUCTIONS_EVENT]:
    "Search for the product you want to report the event for.",

  [dailyAdjustmentsModule.TITLE_FINISHED_PRODUCT]: "Finished Products Record",
  [dailyAdjustmentsModule.SUBTITLE_FINISHED_PRODUCT]:
    "Register the finished products generated from kitchen products.",
  [dailyAdjustmentsModule.INSTRUCTIONS_FINISHED_PRODUCT]:
    "Search for the product you want to report the finished product for.",

  [dailyAdjustmentsModule.REGISTER_SUPPLY_OR_PRODUCT_INSTRUCTION]:
    "Search for the supply or product you want to report",

  [dailyAdjustmentsModule.MOVEMENTS_AUTHORIZATION]: "Authorize Movements",
  [dailyAdjustmentsModule.VALIDATE_AUTHORIZATION]:
    "Validate the movement records generated by kitchen staff",
  [dailyAdjustmentsModule.CONFIRM_MESSAGE]:
    "{{quantity}} {{unit}} will be recorded as {{type}} for this supply. Please confirm if you wish to proceed.",
  [dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE]:
    "The selected movements will be validated. Please verify the quantity sent, as it cannot be modified afterward and your inventory will be affected immediately.",
  [dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE_PLURAL]:
    "The {{supplyQuantity}} selected movements will be validated. Please verify the quantity sent, as it cannot be modified afterward and your inventory will be affected immediately.",
  [dailyAdjustmentsModule.AUTHORIZE_DELETE_TITLE]: "Delete Movements",
  [dailyAdjustmentsModule.AUTHORIZE_DELETE_MESSAGE]:
    "The selected movement will be deleted. Please confirm if you wish to proceed.",
  [dailyAdjustmentsModule.AUTHORIZE_DELETE_MESSAGE_PLURAL]:
    "The {{supplyQuantity}} selected movements will be deleted. Please confirm if you wish to proceed.",
  [dailyAdjustmentsModule.AUTHORIZE_VALIDATE_TITLE]: "Validate Movements",
  [dailyAdjustmentsModule.AUTHORIZE_VALIDATE_MESSAGE]:
    "The selected movement will be validated. Please confirm if you wish to proceed.",
  [dailyAdjustmentsModule.AUTHORIZE_VALIDATE_MESSAGE_PLURAL]:
    "The {{supplyQuantity}} selected movements will be validated. Please confirm if you wish to proceed.",
  [dailyAdjustmentsModule.REPORT_EVENT_TITLE]: "Report Events",
  [dailyAdjustmentsModule.SHOW_FINISHED_PRODUCTS]: "Show finished products",
  [dailyAdjustmentsModule.PRODUCT_DRAWER_INFO]: "Validate the supplies used",
  [dailyAdjustmentsModule.PRE_AUTHORIZE_ADJUSMENT_BUTTON]: "Pre-authorize",

  [theoricalInventoryModule.TITLE]: "Theoretical Inventory",
  [theoricalInventoryModule.NO_ELEMENTS]: "No items",
  [theoricalInventoryModule.SUBTITLE]:
    "Consult theoretical inventory by kitchen",
  [theoricalInventoryModule.SUPPLIES_EXCLUDED]: "Excluded Supplies",
  [theoricalInventoryModule.DEVIATION_ZERO]: "No Deviation",
  [theoricalInventoryModule.DEVIATION_LOW]: "Tolerable Deviation",
  [theoricalInventoryModule.DEVIATION_HIGH]: "High Deviation",
  [theoricalInventoryModule.REPORT_TITLE]: "Inventory Report",
  [theoricalInventoryModule.REPORT_SUBTITLE]:
    "Validate theoretical inventory vs physical count.",
  [theoricalInventoryModule.REPORT_FILTER]: "Filter report",
  [theoricalInventoryModule.ERROR_DATA]:
    "An error occurred while fetching the data",
  [theoricalInventoryModule.ERROR_EXPORT]: "No records found for export",
  [theoricalInventoryModule.THEORETICAL_AMOUNT]: "Theoretical amount",
  [theoricalInventoryModule.ACTUAL_AMOUNT]: "Actual amount",
  [theoricalInventoryModule.DIFFERENCE]: "Difference",
  [theoricalInventoryModule.DEVIATION]: "Deviation",
  [theoricalInventoryModule.HIGH_ACCURACY]: "High Accuracy",
  [theoricalInventoryModule.LOW_ACCURACY]: "Low Accuracy",
  [theoricalInventoryModule.MEDIUM_ACCURACY]: "Medium Accuracy",
  [theoricalInventoryModule.LAST_COUNTING_LABEL]:
    "Last count performed on {{date}} at {{time}}",
  [theoricalInventoryModule.RESULT]: "Result",
  [theoricalInventoryModule.ACCURACY_RESULT]: "{{accuracy}}% Accuracy",
  [theoricalInventoryModule.DETAILS]: "",

  [kitchenInventoryManagement.INVENTORY_MODULE]: "Kitchen Inventory Management",
  [kitchenInventoryManagement.INVENTORY_MODULE_DESCRIPTION]:
    "Select the tool you wish to use for inventory management in your kitchen.",

  [kitchenInventoryManagement.INVENTORY_ADMIN_MODULE]: "Inventory Admin",
  [kitchenInventoryManagement.INVENTORY_ADMIN_MODULE_DESCRIPTION]:
    "Select the tool you wish to use to manage inventory settings.",

  [kitchenInventoryManagement.SUPPLY_ADMIN_MODULE]: "Purchasing Admin",
  [kitchenInventoryManagement.SUPPLY_ADMIN_MODULE_DESCRIPTION]: "",

  [kitchenInventoryManagement.KITCHEN_INVENTORY_TITLE]: "Inventory",
  [kitchenInventoryManagement.KITCHEN_INVENTORY_DESCRIPTION]:
    "Scheduled counts for inventory recording.",

  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE]: "Daily Movements",
  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_DESCRIPTION]:
    "Adjustment report for scraps, losses, consumption, and events.",

  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_CP]:
    "Inventory Management for C.P.",
  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_DESCRIPTION_CP]:
    "Select the tool you want to use for inventory management at your production center.",

  [kitchenInventoryManagement.RECEPTION_INVENTORY_TITLE]: "Order Reception",
  [kitchenInventoryManagement.RECEPTION_INVENTORY_DESCRIPTION]:
    "Reception of transfers and purchase orders.",

  [kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_TITLE]:
    "Authorize Recounts",
  [kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_DESCRIPTION]:
    "Authorization of recounts requested by locations.",

  [kitchenInventoryManagement.AUTHORIZATION_INVENTORY_TITLE]:
    "Movement Authorization",
  [kitchenInventoryManagement.AUTHORIZATION_INVENTORY_DESCRIPTION]:
    "Review of inventory adjustments for daily movements.",

  [kitchenInventoryManagement.PRODUCT_INVENTORY_TITLE]: "Production Record",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_DESCRIPTION]:
    "Scheduling and production generation.",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_DESCRIPTION_2]:
    "Creation of productions for transfers",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_MODULE]:
    "List of active productions at the Production Center.",

  [kitchenInventoryManagement.PRODUCT_INVENTORY_MODAL_TITLE]:
    "List of active productions at the Production Center.",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_MODAL_INSTRUCTION]:
    "Start a new production to continue",

  [kitchenInventoryManagement.PRODUCT_INVENTORY_SCHEDULE_PRODUCTIONS]:
    "Scheduled Productions",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_ACTIVE_PRODUCTIONS]:
    "Active Productions",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_ENDED_PRODUCTIONS]:
    "Finished Productions",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_NEW_PRODUCTIONS]:
    "NEW PRODUCTION",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_ADD_PRODUCTIONS]:
    "ADD PRODUCTION",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_NAME_PRODUCTIONS]:
    "Production Name",

  [kitchenInventoryManagement.TRANSFER_LOAD_FILE_TITLE]: "Transfer Scheduling",
  [kitchenInventoryManagement.TRANSFER_LOAD_FILE_DESCRIPTION]:
    "Scheduling of product transfers between locations.",
  [kitchenInventoryManagement.TRANSFER_PICKING_PACKING_TITLE]:
    "Transfer Preparation",
  [kitchenInventoryManagement.TRANSFER_PICKING_PACKING_DESCRIPTION]:
    "Selection and packaging of supplies for transfer shipping.",
  [kitchenInventoryManagement.TRANSFER_AUTHORIZATION_TITLE]:
    "Transfer Authorization",
  [kitchenInventoryManagement.TRANSFER_AUTHORIZATION_DESCRIPTION]:
    "Review of scheduled transfers reported between locations.",

  [kitchenInventoryManagement.INVENTORYREVIEW_MODULE]: "Review Inventories",
  [kitchenInventoryManagement.COUNTDOWNLOAD_MODULE]: "Download Counts",
  [kitchenInventoryManagement.SUPPLIESMANAGEMENT_MODULE]: "Manage Supplies",
  [kitchenInventoryManagement.MASSIVEADJUSTS_MODULE]: "Massive Adjustments",
  [kitchenInventoryManagement.THEORETICALINVENTORYCOMPARISON_MODULE]:
    "Compare Inventory",
  [kitchenInventoryManagement.SINGLEADJUSTS_MODULE]: "Individual Adjustment",
  [kitchenInventoryManagement.DAILYMOVS_MODULE]:
    "Individual Inventory Adjustment Update.",

  [kitchenInventoryManagement.INVENTORYREVIEW_MODULE_DESCRIPTION]:
    "Review and analysis of theoretical inventory.",
  [kitchenInventoryManagement.COUNTDOWNLOAD_MODULE_DESCRIPTION]:
    "Download of inventory counts by location.",
  [kitchenInventoryManagement.SUPPLIESMANAGEMENT_MODULE_DESCRIPTION]:
    "Activation and deactivation of supplies for inventories.",
  [kitchenInventoryManagement.MASSIVEADJUSTS_MODULE_DESCRIPTION]:
    "Massive inventory adjustment update.",
  [kitchenInventoryManagement.THEORETICALINVENTORYCOMPARISON_MODULE_DESCRIPTION]:
    "Analysis and validation of theoretical vs physical inventory.",
  [kitchenInventoryManagement.SINGLEADJUSTS_MODULE_DESCRIPTION]:
    "Individual inventory adjustment update.",
  [kitchenInventoryManagement.DAILYMOVS_MODULE_DESCRIPTION]:
    "Report of daily movements reported by location",

  [kitchenInventoryManagement.SELECT_MODULE_TITLE]: "Select the Module",
  [kitchenInventoryManagement.SELECT_MODULE_SUBTITLE]: "Select the tool",
  [kitchenInventoryManagement.FOR_SUPPLY_INFO]:
    "Select the supply you want to begin preparing transfers for the requested locations",
  [kitchenInventoryManagement.FOR_KITCHEN_INFO]:
    "Scan the packages or enter the quantity of each supply you want to pack for the kitchen",
  [kitchenInventoryManagement.PRODUCTION_SUPPLY_RECEPTION_TITLE]:
    "Supply Reception",
  [kitchenInventoryManagement.PRODUCTION_SUPPLY_RECEPTION_DESCRIPTION]:
    "Supply preparation for locations.",
  [kitchenInventoryManagement.PRODUCTION_LIBERATION_TITLE]:
    "Production Release",
  [kitchenInventoryManagement.PRODUCTION_LIBERATION_DESCRIPTION]:
    "Validation and release of generated productions.",
  [kitchenInventoryManagement.BC_INTEGRATION_MODULE]: "Integrations",
  [kitchenInventoryManagement.BC_INTEGRATION_MODULE_DESCRIPTION]:
    "Business Central Integrations",

  [productionManagement.PRODUCT_INSTRUCTION]:
    "Select a production to view the necessary ingredients.",
  [productionManagement.START_PRODUCTION]: "Start Production",
  [productionManagement.FINISH_PRODUCTION]: "Finish Production",
  [productionManagement.FINISH_PRODUCTION_MODAL]:
    "Enter the actual performance of the completed production.",
  [productionManagement.FINISH_REAL_PERFORMANCE]: "Actual Performance",
  [productionManagement.PRODUCTION_USER]: "Production Manager",
  [productionManagement.PRODUCTION_PACKED_QUANTITY]: "Quantity per Package",
  [productionManagement.PRODUCTION_CALCULATOR_TITLE]: "Production Calculator",
  [productionManagement.PRODUCTION_CALCULATOR_DESCRIPTION]:
    "Enter the supply you want to use to calculate production performance",
  [productionManagement.PRODUCTION_CALCULATOR_ESTIMATED]:
    "Estimated Production",
  [productionManagement.EDIT_PRODUCTION]: "Modify Production",
  [productionManagement.EDIT_PRODUCTION_MODAL_SUCCESS_TITLE]:
    "Production Modified",
  [productionManagement.EDIT_PRODUCTION_MODAL_SUCCESS_MESSAGE]:
    "The production {{productionName}} was successfully updated.",
  [productionManagement.REPORT_PRODUCTION_ERROR_MESSAGE_HIGH]:
    "The actual performance {{producedQuantity}} {{unit}} for production {{productionName}} is TOO HIGH for the quantity to be produced {{quantityToProduce}} {{unit}}",
  [productionManagement.REPORT_PRODUCTION_ERROR_MESSAGE_LOW]:
    "The actual performance {{producedQuantity}} {{unit}} for production {{productionName}} is TOO LOW for the quantity to be produced {{quantityToProduce}} {{unit}}",
  [productionManagement.REPORT_PRODUCTION_ERROR_VALIDATION_MESSAGE]:
    "The production of <strong>{{productionName}}</strong> requires prior production of <strong>{{itemName}}</strong>.",
  [productionManagement.REPORT_PRODUCTION_ERROR_PRODUCTION_VALIDATION_MESSAGE]:
    "The production of <strong>{{productionName}}</strong> requires prior production of <strong>{{itemName}}</strong>. <strong>Please generate {{itemName}} production first to continue</strong>.",
  [productionManagement.DELETE_DIALOG_TITLE]: "Delete Production",
  [productionManagement.DELETE_DIALOG_MESSAGE]:
    "The production of {{productionName}} will be deleted from the list of active productions. Please confirm if you wish to continue.",
  [productionManagement.PACKAGING_PARTIAL]: "To Pack",
  [productionManagement.PACKAGING_COMPLETE]: "Packed Production",
  [productionManagement.LABELS_HISTORY]: "Packaging History",
  [productionManagement.LABELS_HISTORY_EMPTY]:
    "Packaging for this production has not yet started.",
  [productionManagement.PACK_PRODUCTION]: "Pack Production",
  [productionManagement.SELECT_LABELS_TO_PRINT]:
    "Select the labels you want to reprint",
  [productionManagement.REPRINT_LABELS]: "Reprint Labels",
  [productionManagement.LABELS_OF]: "Packages of {{quantity}} {{unit}}",
  [productionManagement.LABELS_GENERATED]: "Generated Labels",
  [productionManagement.LABEL]: "Label",
  [productionManagement.USER_CREATED_LABELS]: "Production Manager",
  [productionManagement.SELECT_USER_CREATED_LABELS]: "Select the Manager",
  [productionManagement.LABELS_GENERATED_INFO]:
    "Add the information to generate packaging labels",
  [productionManagement.TRIGGER]: "Generate",
  [productionManagement.QUANTITY_TO_PACK]: "Quantity to Pack",
  [productionManagement.QUANTITY_OF_PACKAGING]: "Number of Packages",
  [productionManagement.LOG_TITLE]: "Track Productions",
  [productionManagement.LOG_DESCRIPTION]:
    "Review of packaging movement history.",
  [productionManagement.LOG_TITLE_INPUT]: "Search or scan production",
  [productionManagement.LOG_LABEL_INPUT]: "Enter the production batch",
  [productionManagement.LOG_TRACK]: "Track",
  [productionManagement.LOG_EMPTY_LABEL_1]: "Production Tracking",
  [productionManagement.LOG_EMPTY_LABEL_2]:
    "Enter or scan a production to validate its recorded movements",
  [productionManagement.LOG_DETECTED_MOVS]: "Detected Movements",
  [productionManagement.LOG_MOV]: "Movement",
  [productionManagement.LOG_VALIDITY_LABEL]:
    "Validity: {{days}} days remaining",
  [productionManagement.LOG_INVALIDITY_LABEL]: "Expired {{days}} days ago",
  [productionManagement.SCHEDULED]: "Scheduled",
  [productionManagement.IN_PROCESS]: "In Process",
  [productionManagement.FINISHED]: "Finished",
  [productionManagement.APPROVED]: "Approved",
  [productionManagement.APPROVED_WITH_NOVELTY]: "Approved with novelty",
  [productionManagement.PRODUCTION_AND_QUANTITY]: "Production and Quantity",
  [productionManagement.CHRONOMETER]: "Stopwatch",
  [productionManagement.RESPONSIBLE]: "Responsible",
  [productionManagement.SCHEDULED_QUANTITY]: "Scheduled Quantity",
  [productionManagement.PRODUCED_QUANTITY]: "Produced Quantity",
  [productionManagement.STARTED_PRODUCTION]: "Production Start",
  [productionManagement.FINISHED_PRODUCTION]: "Production End",
  [productionManagement.PRODUCTION_TIME]: "Production Time",
  [productionManagement.PRODUCTION_USER_ASSIGNED_REQUIRED]:
    "The production manager is required",
  [productionManagement.PRODUCTION_SCHEDULED_DATE_REQUIRED]:
    "The production date is required",
  [productionManagement.PRODUCTION_QUANTITY_REQUIRED]:
    "The production quantity is required",
  [productionManagement.PRODUCTION_QUANTITY_TYPE_ERROR]:
    "The production quantity must be a number",
  [productionManagement.PRODUCTION_SKU_REQUIRED]: "The SKU is required",
  [productionManagement.DOWNLOAD_EXAMPLE_TEMPLATE]:
    "You can download our template by clicking here",
  [productionManagement.INVALID_DOCUMENT_TITLES]:
    "The document titles are not valid.",
  [productionManagement.INVALID_DOCUMENT_DATA]:
    "The document data is not valid.",
  [productionManagement.DRAG_AND_DROP_FILE]:
    "Select or drag the production template.",
  [productionManagement.FILL_IN_THE_FORM]:
    "Enter the details of the production you wish to add.",
  [productionManagement.ADD_PRODUCTION_TITLE]: "Add Production",
  [productionManagement.EDIT_PRODUCTION_TITLE]: "Edit Production",
  [productionManagement.VALIDATE_ADDED_DATA]:
    "Validate the added production data.",
  [productionManagement.PRODUCTION_NAME]: "Production Name",
  [productionManagement.QUANTITY]: "Production Quantity",
  [productionManagement.SCHEDULED_DATE]: "Production Date",
  [productionManagement.TIME_SLOT]: "Time Slot",
  [productionManagement.USER_ASSIGNED]: "Production Manager",
  [productionManagement.PRODUCING_FOR]: "Producing for",
  [productionManagement.PRINT_INGREDIENTS]: "Print Ingredients",
  [productionManagement.PPRODUCTION_ORDER]: "Production Order",
  [productionManagement.GENERATED_QUANTITY]: "Generated Quantity",
  [productionManagement.VALIDATION_REQUIRED_INFO]:
    "Production pending validation by Quality",
  [productionManagement.SELECT_STATION]: "Select station",

  [transferModule.CONFIRM]: "Confirm Transfer",
  [transferModule.SCHEDULE_SUCCESSFULLY]:
    "The transfer has been scheduled successfully",
  [transferModule.NUMBER_OF_BASKETS]: "Number of Baskets",
  [transferModule.SUCCESS_AUTHORIZE_TITLE]: "Transfer Confirmed",
  [transferModule.SUCCESS_AUTHORIZE_DESCRIPTION]:
    "The transfer was successfully confirmed in the inventory system.",
  [transferModule.ERROR_AUTHORIZE_TITLE]: "Transfer could not be confirmed",
  [transferModule.NUMBER_OF_BASKETS_QUESTION]: "How many baskets will be sent?",
  [transferModule.SUCCESS]: "Transfer successfully completed",
  [transferModule.DELETE_LABELS]: "Delete Packages",
  [transferModule.ERROR_SKU]: "The product number is empty or invalid",
  [transferModule.ERROR_MEASURE_UNIT]: "The unit of measure is empty",
  [transferModule.ERROR_LINE]: "Error in the line",
  [transferModule.DELETE_TRANSFER]: "Delete Transfer",
  [transferModule.DELETE_TRANSFER_CONFIRM]:
    "Do you want to delete the transfer for the kitchen?",
  [transferModule.USER_CREATE]: "Created by",
  [transferModule.USER_RECIBE]: "Received by",
  [transferModule.DESTINATION]: "Destination",
  [transferModule.STORAGE]: "Storage",
  [transferModule.EDIT_TRANSFER]: "Edit",
  [transferModule.PREPARE_TRANSFER]: "Prepare",
  [transferModule.ENLISTED]: "Enlisted",
  [transferModule.PENDING]: "Pending",
  [transferModule.UNSPECIFIED]: "Unspecified",
  [transferModule.RECEIPT_NO]: "Receipt Number",
  [transferModule.ORDER_NO]: "Transfer Number",
  [transferModule.RECEIVED_AT]: "Received At",
  [transferModule.RECEIVED_BY]: "Received by",
  [transferModule.CREATED_AT]: "Created At",
  [transferModule.STATUS]: "Status",
  [transferModule.NOT_PENDIG]: "You have no pending transfers to send.",
  [transferModule.ERROR_SINGLE_TRANSFER]:
    "The transfer destination must be different from the origin.",
  [transferModule.TITLE_ERROR_PENDING_TRANSFER]:
    "You have the following pending transfers to sync:",
  [transferModule.DESCRIPCTION_ERROR_PENDING_TRANSFER]:
    "Please check if you have pending productions to report or pending reception orders. If not, contact the Inventory Administrator.",
  [transferModule.SCHEDULE]: "Schedule Transfer",
  [transferModule.MASSIVE]: "Massive Transfer",
  [transferModule.INDIVIDUAL]: "Individual Transfer",
  [transferModule.TO_SEND]: "Pending Transfers",
  [transferModule.MADE]: "Authorized Transfers",

  [transferModule.RETURN_MESSAGE]:
    "The following products will be returned to ",
  [transferModule.RECEIPT_CONFIRM_MESSAGE]:
    "The transfer has been successfully received",
  [transferModule.RECEIPT_CONFIRM_TITLE]: "Successful Reception",
  [transferModule.RECEIPT_MESSAGE]: "Confirm the receipt of the transfer order",
  [transferModule.RECEIPT_TITLE]: "Receive Transfer",
  [transferModule.EXTRA]: "EXTRA",
  [transferModule.TRANSFER]: "TRANSFER",
  [transferModule.RETURN]: "RETURN",
  [transferModule.HAVE_EXTRA_OR_LEFTOVER_QUESTION]:
    "Did this transfer include supplies not in the reception order or with leftover quantities?",
  [transferModule.HAVE_EXTRA_OR_LEFTOVER_COMMENT]:
    "Indicate the supplies and quantities",
  [transferModule.RECEIPT]: "Receive Transfer",
  [transferModule.SKU]: "SKU",
  [transferModule.DESCRIPTION]: "Description",
  [transferModule.QUANTITY]: "Quantity",
  [transferModule.UNIT]: "Unit",

  [transferPackingDialog.SUBTITLE]:
    "Select the kitchen and start scanning the packages you want to add.",
  [transferPackingDialog.SUBTITLE_FOR_KITCHEN]:
    "Validate the scanned packages.",
  [transferPackingDialog.SUBTITLE_DELETE_LABELS]:
    "Scan the packages you want to delete.",
  [transferPackingDialog.RESUME]: "Transfer Summary",
  [transferPackingDialog.SUPPLY]: "Supply",
  [transferPackingDialog.TOTAL_QUANTITY]: "Total Quantity",
  [transferPackingDialog.CONTINUE]: "Continue",
  [transferPackingDialog.CANCEL]: "Cancel",
  [transferPackingDialog.REQUESTED_QUANTITY]: "Requested Quantity",
  [transferPackingDialog.SERVICE_LEVEL]: "Service Level",
  [transferPackingDialog.QUANTITY_SENT]: "Quantity Sent",
  [transferPackingDialog.CONFIRMED_TRANSFERS_TITLE]: "Confirmed Transfers",
  [transferPackingDialog.CONFIRMED_TRANSFERS_MESSAGE]:
    "The quantities sent to the kitchens were successfully confirmed in the inventory system.",

  [transferOrdersPackedReviewTable.SKU]: "Sku",
  [transferOrdersPackedReviewTable.NAME]: "Name",
  [transferOrdersPackedReviewTable.UNIT]: "Unit",
  [transferOrdersPackedReviewTable.ESTIMATED_QUANTITY]: "Estimated Quantity",
  [transferOrdersPackedReviewTable.PACKED_QUANTITY]: "Packed Quantity",
  [transferOrdersPackedReviewTable.QUANTITY_TO_SEND]: "Quantity to Send",
  [transferOrdersPackedReviewTable.SERVICE_LEVEL]: "Service Level",
  [transferOrdersPackedReviewTable.STATUS]: "Status",
  [transferOrdersPackedReviewTable.COMPLETE]: "Complete",
  [transferOrdersPackedReviewTable.PARTIAL]: "Partial",
  [transferOrdersPackedReviewTable.NO_SENT]: "Not Sent",
  [transferOrdersPackedReviewTable.ALL_TITLE]: "Show All",
  [transferOrdersPackedReviewTable.COMPLETE_TITLE]: "Complete Supplies",
  [transferOrdersPackedReviewTable.PARTIAL_TITLE]: "Partial Supplies",
  [transferOrdersPackedReviewTable.NO_SENT_TITLE]: "Not Sent Supplies",

  [countingTypeSelector.LABEL]: "Counting Type",

  [physicalCountSchedule.HEADER_PAGE_TITLE]: "Schedule Inventories",
  [physicalCountSchedule.HEADER_PAGE_SUBTITLE]:
    "Scheduling of inventory counts by location",
  [physicalCountSchedule.STEP_LABEL_1]: "Counting Type",
  [physicalCountSchedule.STEP_LABEL_2]: "Location",
  [physicalCountSchedule.IMPORT]: "Import",
  [physicalCountSchedule.STEP_LABEL_3]: "Dates",
  [physicalCountSchedule.STEP_LABEL_4]: "Supplies",
  [physicalCountSchedule.STEP_LABEL_FINAL]: "Confirm",
  [physicalCountSchedule.PREVIOUS]: "Previous",
  [physicalCountSchedule.CONTINUE]: "Continue",
  [physicalCountSchedule.FINISH]: "Finish",
  [physicalCountSchedule.ADD_LOCATION]: "Add Location",
  [physicalCountSchedule.ACTIONS]: "Actions",
  [physicalCountSchedule.STEP_2_TABLE_EMPTY]:
    "No locations have been added yet",
  [physicalCountSchedule.SINGLE_DATE]: "Single Date",
  [physicalCountSchedule.DATE_RANGE]: "Date Range",
  [physicalCountSchedule.COUNT_DATE]: "Count Date",
  [physicalCountSchedule.START_DATE]: "Start Date",
  [physicalCountSchedule.END_DATE]: "End Date",
  [physicalCountSchedule.SCHEDULE_DATE]: "Schedule Date",
  [physicalCountSchedule.SCHEDULE_DATES]: "Schedule Dates",
  [physicalCountSchedule.DATE]: "Date",
  [physicalCountSchedule.STEP_3_TABLE_EMPTY]: "No dates have been added yet",
  [physicalCountSchedule.STEPS_RESET]: "Reset",
  [physicalCountSchedule.STEPS_COMPLETED]: "All steps have been completed",
  [physicalCountSchedule.STEP_1_INFO]:
    "Select the type of physical count you want to schedule.",
  [physicalCountSchedule.STEP_2_INFO]:
    "Select the locations you want to schedule the physical count for.",
  [physicalCountSchedule.STEP_IMPORT_INFO]:
    "Select or drag the recount template",
  [physicalCountSchedule.STEP_3_INFO]:
    "Select the dates you want to schedule for the physical inventory count.",
  [physicalCountSchedule.STEP_4_INFO]:
    "Select the supplies you want to schedule the physical count for.",
  [physicalCountSchedule.STEP_FINAL_INFO]:
    "Validate the data before finalizing the physical count scheduling.",
  [physicalCountSchedule.STEP_4_TABLE_EMPTY]: "No supplies to show",
  [physicalCountSchedule.SUCCESS_TITLE]: "Count Scheduled",
  [physicalCountSchedule.SUCCESS_SUBTITLE]:
    "The physical count has been successfully scheduled.",
  [physicalCountSchedule.ERROR_TITLE]: "Count Not Scheduled",
  [physicalCountSchedule.ERROR_SUBTITLE]:
    "Oops... An error occurred. The physical count was not scheduled.",
  [physicalCountSchedule.ARCHIVE_ERROR]: "Error archiving count(s)",
  [physicalCountSchedule.ARCHIVE_SUCCESS]: "Count(s) successfully archived",

  [productionPreparation.INSTRUCTIONS_FOR_PREPARATION]:
    "Preparation Instructions",
  [productionPreparation.NO_PREPARATION_INSTRUCTIONS_FOUND]:
    "No preparation instructions found",
  [productionPreparation.PRINT_PREAPARATION]: "Print Preparation",

  [purchaseOrderLine.TITLE]: "Purchase Order Details",
  [purchaseOrderLine.DELETE_RECEIPT]: "Delete Receipt",
  [purchaseOrderLine.DIALOG_CONFIRM_TITLE]: "Delete Receipt",
  [purchaseOrderLine.DIALOG_CONFIRM_MESSAGE]:
    "The receipt will be deleted and partial and pending products will no longer be delivered to the location. Please confirm if you wish to continue.",
  [purchaseOrderLine.DIALOG_SUCCESS_TITLE]: "Receipt Deleted",
  [purchaseOrderLine.DIALOG_SUCCESS_MESSAGE]:
    "The receipt was successfully deleted. The missing products will no longer be received at the location assigned to the purchase order.",
  [purchaseOrderLine.DIALOG_ERROR_TITLE]: "Receipt Not Deleted",
  [purchaseOrderLine.DIALOG_ERROR_MESSAGE]: "An error occurred",
  [purchaseOrderLine.FIND_PRODUCT_TITLE]: "Search Product",
  [purchaseOrderLine.FIND_PRODUCT_LABEL]: "Product ID / Product Name",
  [purchaseOrderLine.RECEIPT_PENDING]:
    "Pending Receipt: {{pendingLines}} Supplies",
  [purchaseOrderLine.RECEIPT_COMPLETE]: "Complete Receipt",
  [purchaseOrderLine.UPDATE_RECEIVED_QUANTITY_ERROR]:
    "Exceeds by {{num}} the requested amount",

  [purchaseOrderCreation.TITLE]: "Generate Purchase Orders",
  [purchaseOrderCreation.DESCRIPTION]:
    "Generation and sending of purchase orders to suppliers",
  [purchaseOrderCreation.CREATE_TITLE]: "Create Purchase Order",
  [purchaseOrderCreation.CREATE_MASSIVE_BUTTON]: "Mass Upload",
  [purchaseOrderCreation.CREATE_INDIVIDUAL_BUTTON]: "Individual Upload",
  [purchaseOrderCreation.CONFIRM_BUTTON]: "Confirm Purchase Orders",
  [purchaseOrderCreation.DETECTED_ORDERS_TITLE]: "Detected Orders",
  [purchaseOrderCreation.NO_ORDENES_DETECTED]: "No purchase orders detected",
  [purchaseOrderCreation.NO_ORDERS_FOR_SEARCH]:
    "No matches found for the search",
  [purchaseOrderCreation.CREATE_MASSIVE_MODAL_TITLE]:
    "Create Purchase Order Massively",
  [purchaseOrderCreation.CONFIRM_MULTIPLE_MODAL_TITLE]:
    "Confirm Purchase Orders",
  [purchaseOrderCreation.CONFIRM_MULTIPLE_MODAL_SUBTITLE]:
    "Confirm the following purchase orders:",
  [purchaseOrderCreation.ESTIMATED_DELIVERY_DATE_LABEL]: "Estimated Delivery",
  [purchaseOrderCreation.VALIDATE_ITEM_MESSAGE]:
    "Add or remove products for this purchase order",
  [purchaseOrderCreation.CONFIRM_AND_SEND_BUTTON]: "Confirm and Send",
  [purchaseOrderCreation.DELETE_ORDER]: "Delete Order",
  [purchaseOrderCreation.UPDATE_ORDER_MODAL_TITLE]: "Change Delivery Date",
  [purchaseOrderCreation.UPDATE_ORDER_MODAL_INFO]:
    "Select the estimated date for the purchase order",
  [purchaseOrderCreation.UPDATE_ORDER_MODAL_BUTTON]:
    "Update the estimated date",
  [purchaseOrderCreation.ESTIMATED_DATE_LABEL]: "Estimated Date",
  [purchaseOrderCreation.INITIAL_ESTIMATED_DATE_LABEL]:
    "Initial Estimated Date",
  [purchaseOrderCreation.RECEIPT_DATE_LABEL]: "Receipt Date",
  [purchaseOrderCreation.DELETE_LINE_MODAL_TITLE]: "Delete Product",
  [purchaseOrderCreation.DELETE_PURCHASE_ORDER_TITLE]: "Delete Purchase Order",
  [purchaseOrderCreation.DELETE_PURCHASE_ORDER_MESSAGE]:
    "Do you want to delete the purchase order for the supplier ",
  [purchaseOrderCreation.DELETE_LINE_MODAL_SUBTITLE]:
    "Delete products from the purchase order:",
  [purchaseOrderCreation.ADD_LINE_MODAL_TITLE]: "Add Product",
  [purchaseOrderCreation.ADD_LINE_MODAL_INFO]:
    "Add the information to update the purchase order",
  [purchaseOrderCreation.ADD_LINE_MODAL_BUTTON]: "Add to Purchase Order",
  [purchaseOrderCreation.SEND_ORDER_MODAL_TITLE]: "Send Purchase Order",
  [purchaseOrderCreation.SEND_ORDER_MODAL_SUBTITLE]:
    "Confirm the following purchase orders:",
  [purchaseOrderCreation.SEND_ORDER_MODAL_INFO]:
    "Add the information to send the purchase order",
  [purchaseOrderCreation.SEND_LOADING_MODAL]: "Confirming Purchase Order",
  [purchaseOrderCreation.SEND_ORDER_MODAL_BUTTON]: "Send Purchase Order",
  [purchaseOrderCreation.ORDER_CONFIRMED]: "Order Confirmed",
  [purchaseOrderCreation.ORDERD_CONFIRMED_OK]: "Ok",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_TITLE]: "Add Purchase Order",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_INFO]:
    "Add the information to create the purchase order",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_BUTTON]: "Add",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_LOCATION_LABEL]:
    "Delivery Location",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_VENDOR_LABEL]:
    "Supplier Code / Name",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_INVOICE_LABEL]: "Invoice Number",
  [purchaseOrderCreation.DOCUMENT_LIST_BUTTON_LABEL]: "View Documents",
  [purchaseOrderCreation.RESULT_DIALOG_TITLE]: "Purchase Order Creation Result",
  [purchaseOrderCreation.RESULT_DIALOG_ERROR_VENDOR]:
    "The supplier with code {{code}} was not found in the order with number {{invoiceNo}}",
  [purchaseOrderCreation.RESULT_DIALOG_ERROR_SKU]:
    "The supply with SKU {{code}} was not found in the order with number {{invoiceNo}}",
  [purchaseOrderCreation.RESULT_NO_ERRORS]:
    "All purchase orders were successfully created",

  [productionDialog.WEIGHT]: "WEIGHT",
  [productionDialog.BATCH]: "BATCH",
  [productionDialog.TECHNICAL_NAME]: "TECHNICAL NAME",
  [productionDialog.NAME]: "PRODUCT",
  [productionDialog.PRODUCTION_DATE]: "PRODUCTION DATE",
  [productionDialog.EXPIRATION_DATE]: "EXPIRATION DATE",
  [productionDialog.CONSERVATION]: "CONSERVATION",
  [productionDialog.MAXIMUM_DURATION]: "MAXIMUM DURATION",
  [productionDialog.RESPONSIBLE]: "RESPONSIBLE",
  [productionDialog.MANUFACTURER]: "MANUFACTURER",
  [productionDialog.ADDRESS]: "ADDRESS",
  [productionDialog.WEEK]: "WEEK",
  [productionDialog.DAYS]: "Days",

  [errorCode.TRANSFER_WITHOUT_STOCK]:
    "Insufficient inventory for the following items:",
  [errorCode.PARSING_EXCEPTION]: "Error processing the XML, please try again",
  [errorCode.VENDOR_NOT_FOUND]: "The supplier is not registered",

  [massiveTransfer.SELECT_MOVE_FILE]: "Select or drag the transfer template.",
  [massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_BUTTON]:
    "Review Pending Transfer",
  [massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_TITLE]: "Pending Transfer",
  [massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_SUBTITLE]:
    "You have a pending transfer review. To generate a new transfer, first finish the current one.",
  [massiveTransfer.LOAD_TRANSFER_SUCCESS_TITLE]: "Transfers Created",
  [massiveTransfer.LOAD_TRANSFER_SUCCESS_MESSAGE]:
    "The transfer orders have been successfully created",

  [dailyAdjustmentsReport.FILTERS_LABEL]: "Filter Daily Movements",
  [dailyAdjustmentsReport.SEND_TO_VALIDATION_MESSAGE]:
    "{{supplyQuantity}} supplies will be sent for review by the Kitchen Manager to receive confirmation of the reported quantity. Please confirm if you wish to continue.",
  [dailyAdjustmentsReport.APPROVED]: "Authorized",
  [dailyAdjustmentsReport.VALIDATED]: "Validated",

  [productionScannedError.LABEL_NOT_FOUND]: "Label not found",
  [productionScannedError.ALREADY_SCANNED]: "Label already scanned",
  [productionScannedError.INTERNAL_ERROR]: "Error scanning",
  [productionScannedError.WITHOUT_HISTORIAL]: "No history",
  [productionScannedError.CAN_NOT_DELETE]: "Cannot delete",
  [productionScannedError.SELECT_THE_KITCHEN]: "You must select the kitchen",
  [productionScannedError.SELECT_THE_COUNTING]: "You must select the count",
  [productionScannedError.SELECT_ORDER_AND_KITCHEN]:
    "You must select the order and the kitchen",

  [productionLogReason.CREATED]: "Packed Production",
  [productionLogReason.COUNTING]: "Inventoried Production",
  [productionLogReason.TRANSFER_PACKING]: "Transferred Production",
  [productionLogReason.TRANSFER_RECEIPT]: "Received Production",
  [transferOrderModule.TITLE]: "Manage Transfers",
  [transferOrderModule.DESCRIPTION]:
    "Review of scheduled transfers between locations.",
  [transferOrderModule.FILTER_ORDERS]: "Filter Transfers",
  [transferOrderModule.FIND_ORDERS]: "Search Transfer",
  [transferOrderModule.FIND_ORDER_LABEL]: "Transfer ID / Location Name",
  [transferOrderModule.TRANSFER_ID]: "Transfer ID",
  [transferOrderModule.DESTINATION_NAME]: "Location Name",
  [transferOrderModule.ALL]: "ALL",
  [transferOrderModule.RELEASED]: "PREPARING",
  [transferOrderModule.IN_PROCESS]: "PREPARING",
  [transferOrderModule.PACKED]: "PACKED",
  [transferOrderModule.IN_TRANSIT]: "IN TRANSIT",
  [transferOrderModule.RECEIVED]: "RECEIVED",
  [transferOrderDetails.TITLE]: "Transfer Details",
  [transferOrderDetails.FIND_SUPPLY]: "Search Supply",
  [transferOrderDetails.FIND_SUPPLY_LABEL]: "Supply ID / Supply Name",
  [takePhotoLocale.RETRY]: "Retry",
  [takePhotoLocale.CONTINUE]: "Continue",
  [takePhotoLocale.TAKE_PHOTO]: "Take Photo",
  [takePhotoLocale.TAKE_PHOTO_AGAIN]: "Retake Photo",
  [takePhotoLocale.BAG_PRODUCTS]: "Products in the Bag",
  [takePhotoLocale.TITLE]: "Take Photo",

  [purchaseOrderConciliation.TITLE]: "Reconcile Purchase Orders",
  [purchaseOrderConciliation.DESCRIPTION]:
    "Reconciliation of purchase orders requested from suppliers.",

  [productionSchedule.EMPTY_TITLE]: "You have no scheduled productions",
  [productionSchedule.EMPTY_DESCRIPTION]: "Add a production for this time slot",
  [productionSchedule.DELETE_TITLE]: "Delete Production",
  [productionSchedule.DELETE_MESSAGE]:
    "{{productionName}} will be removed from the production plan. Please confirm if you wish to continue.",
  [productionSchedule.MODIFY_MESSAGE]:
    'The quantity to be produced of "{{productionName}}" will be modified (from {{initialQuantity}}{{unit}} to {{finalQuantity}}{{unit}}). Please confirm if you wish to proceed.',

  [productionSchedule.TITLE_FINISHED]: "Finished Production",
  [productionSchedule.TITLE_IN_PROCESS]: "Production in Process",
  [productionSchedule.TITLE_SCHEDULED]: "Scheduled Production",

  [supplyingManagement.MODULE_TITLE]: "C.P. Supply",
  [supplyingManagement.MODULE_DESCRIPTION]: "Supply preparation for locations.",
  [supplyingManagement.RESUME]: "Requisition Summary",
  [supplyingManagement.TOTAL]: "Totals",
  [supplyingManagement.PARTIAL]: "Partial",
  [supplyingManagement.COMPLETED]: "Completed",
  [supplyingManagement.SEARCH]: "Search Requisition",
  [supplyingManagement.SEARCH_LABEL]: "Requisition Number",
  [supplyingManagement.COMPLETE]: "Completed",
  [supplyingManagement.PENDING]: "Pending",
  [supplyingManagement.CP_NAME]: "C.P. Name",
  [supplyingManagement.SUPPLY_VIEW]: "View by Supplies",
  [supplyingManagement.SLOT_VIEW]: "View by Time Slots",
  [supplyingManagement.ALL_SLOTS]: "Show All",
  [supplyingManagement.ALL_ITEMS]: "Show All",
  [supplyingManagement.ADD_EXTRA_SUPPLY]: "Send Extra Supply",
  [supplyingManagement.ADD_EXTRA_SUPPLY_INFO]:
    "Enter the details of the supply you want to add.",
  [supplyingManagement.OVER_QUANTITY]: "Surplus Quantity",
  [supplyingManagement.SUGGESTED_QUANTITY]: "Suggested Quantity",

  [supplyReception.TITLE]: "Supply Reception",
  [supplyReception.RECEIVE_SUPPLIES_TAB]: "Receive Supplies",
  [supplyReception.REQUEST_SUPPLIES_TAB]: "Request Supplies",
  [supplyReception.REQUISITION_NUMBER_LABEL]: "Requisition Number",
  [supplyReception.TOTAL_REQUISITION_LABEL]: "Total Requisitions",
  [supplyReception.PACKED_REQUISITION_LABEL]: "Packed Requisitions",
  [supplyReception.COMPLETED_REQUISITION_LABEL]: "Completed Requisitions",
  [supplyReception.LOCATION_NAME_LABEL]: "Cedis Name",
  [supplyReception.DATE_LABEL]: "Requisition Date",
  [supplyReception.PACKED]: "PACKED",
  [supplyReception.SENT_QUANTITY]: "Sent Quantity",
  [supplyReception.RECEIVED_QUANTITY]: "Received Quantity",
  [supplyReception.REQUESTED_QUANTITY]: "Requested Quantity",
  [supplyReception.NOVELTY]: "Novelty",
  [supplyReception.CONFIRM_SUPPLY]: "Confirm Supply",
  [supplyReception.NOVELTY_MODAL_TITLE]: "Update Supply",
  [supplyReception.NOVELTY_MODAL_INFO]:
    "Update the details of the supply you are receiving.",
  [supplyReception.MISSING_REASON_LABEL]: "Reason for Missing",
  [supplyReception.CONFIRM_SUPPLY_MODAL_TITLE]: "Supply Summary",
  [supplyReception.CONFIRM_SUPPLY_MODAL_SUBTITLE]:
    "Validate the supply summary to continue.",
  [supplyReception.ITEMS]: "Items",
  [supplyReception.PARTIAL_PRODUCTS]: "Partial Products",
  [supplyReception.MISSING_PRODUCTS]: "Missing Products",
  [supplyReception.SPARE_PRODUCTS]: "Surplus Products",
  [supplyReception.COMPLETE_PRODUCTS]: "Complete Products",
  [supplyReception.NO_SUPPLIES]:
    "There are no supplies entered to make the request",
  [supplyReception.NO_SUPPLIES_INFO]:
    "Select the supplies and add the quantity you wish to request.",
  [supplyReception.CONFIRM_SUPPLY_CREATION_INFO]: "Confirm the supply request",

  [missingReason.DAMAGED_PRODUCT]: "Damaged Product",
  [missingReason.MISSING_PRODUCT]: "Missing Product",
  [missingReason.PRODUCT_EXPIRED]: "Expired Product",
  [missingReason.SPARE_PRODUCT]: "Surplus Product",

  [productionLiberation.TITLE]: "Production Release",
  [productionLiberation.FINISH_DATE]: "Completion Date",
  [productionLiberation.LIBERATE_ACTION_BUTTON]: "Release",
  [productionLiberation.RESULT_APPROVEMENT_LABEL]: "Validation Result",
  [productionLiberation.APPROVED]: "Approved",
  [productionLiberation.APPROVED_WITH_NOVELTY]: "Approved with Novelty",
  [productionLiberation.REJECTED]: "Rejected",
  [productionLiberation.NOVELTY_LABEL]:
    "Enter comments or novelties of the validation.",
  [productionLiberation.EVALUATE_BUTTON]: "Evaluate",
  [productionLiberation.ORGANOLEPTIC_MODAL_TITLE]: "Quality Evaluation",
  [productionLiberation.ORGANOLEPTIC_MODAL_SUBTITLE]:
    "Select whether the organoleptic meets or does not meet the quality characteristics",
  [productionLiberation.ORGANOLEPTIC_MODAL_SUBTITLE_2]:
    "Indicate the reasons why the following organoleptics of this production did not meet the requirements.",
  [productionLiberation.PRODUCTION_ORGANOLEPTIC_ERROR]:
    "Production details not found",

  [organoleptics.APPEARENCE]: "Appearance",
  [organoleptics.COLOR]: "Color",
  [organoleptics.SMELL]: "Smell",
  [organoleptics.TASTE]: "Taste",
  [organoleptics.TEXTURE]: "Texture",
  [organoleptics.VISCOSITY]: "Viscosity",

  [bcIntegrations.INVENTORY_ADJUSTMENTS]: "Inventory Adjustments",
  [bcIntegrations.BATCHES]: "Batches",
  [bcIntegrations.PURCHASE_ORDERS]: "Purchase Orders",
  [bcIntegrations.PURCHASE_ORDERS_RECEIPT]: "Purchase Order Receipts",
  [bcIntegrations.TRANSFER]: "Transfers",
  [bcIntegrations.TRANSFER_RECEIPT]: "Transfer Receipts",
  [bcIntegrations.SALES]: "Sales",
  [bcIntegrations.CONCILIATION]: "Conciliation",
  [bcIntegrations.PURCHASE_ORDER_MODIFY]: "PO Update",
  [bcIntegrations.VENDORS]: "Vendors",
  [bcIntegrations.PRODUCTS]: "Items",
  [bcIntegrations.ARCHIVE_DIALOG_MESSAGE]:
    "Are you sure you want to delete the error report for {{type}}",

  [vendor.VENDOR_SUPPLY_MODULE_TITLE]: "Supplier Control",
  [vendor.VENDOR_SUPPLY_MODULE_DESCRIPTION]:
    "Management of suppliers registered by country.",
  [vendor.PAYMENT_DAYS]: "Credit Days",
  [vendor.PAYMENT_METHOD]: "Payment Method",
  [vendor.DOCUMENT_TYPE]: "Document Type",
  [vendor.SUPPLIES_QUANTITY]: "Supplies Quantity",
  [vendor.VENDORS_QUANTITY]: "Vendors Quantity",
  [vendor.SHOW_SUPPLIES]: "View Supplies",
  [vendor.SHOW_VENDORS]: "View Vendors",
  [vendor.VENDOR_SUPPLIES_TITLE]: "Supplies by Supplier",
  [vendor.SUPPLY_VENDORS_TITLE]: "Suppliers by Supply",
  [vendor.SUPPLY_NAME]: "Supply Name",
  [vendor.VENDOR_SUPPLY_NAME]: "Supplier Description",
  [vendor.VENDOR_UNIT]: "Supplier Unit",
  [vendor.SUPPLY_QUANTITY]: "Presentation",
  [vendor.SUPPLY_UNIT]: "KIS Unit",
  [vendor.VENDOR_UNIT_PRICE]: "Supplier Unit Price",
  [vendor.SUPPLY_UNIT_PRICE]: "KIS Unit Price",
  [vendor.VENDOR_MIN_ORDER_QUANTITY]: "Min. Order Quantity",
  [vendor.VENDOR_ORDER_PER_WEEK]: "Orders per Week",
  [vendor.CATEGORY]: "Category",
  [vendor.VIEW_BY_VENDORS]: "Show Vendors",
  [vendor.VIEW_BY_SUPPLIES]: "Show Supplies",
  [vendor.LEAD_TIME]: "Lead Time",
  [vendor.SUPPLY_VALIDATOR_LABEL_LONG]:
    "The unit price ({{price}}) for the supply {{sku}} (SKU), is outside the range | Range between {{minPrice}} and {{maxPrice}} | The agreed price is {{vendorPrice}}",
  [vendor.SUPPLY_VALIDATOR_LABEL_SHORT]:
    "The agreed price is {{vendorPrice}} | Range between {{minPrice}} and {{maxPrice}}",
  [vendor.SUPPLY_VALIDATOR_LABEL_SHORT_2]:
    "The agreed price is {{vendorPrice}}",
  [vendor.SUPPLY_VALIDATOR_CHIP_LABEL]:
    "There are {{number}} price variation(s) that need validation.",
  [vendor.PURCHASE_EMAIL]: "Purchase Email",
  [vendor.ACCOUNTING_EMAIL]: "Accounting Email",

  [vendorMeasureUnit.KG]: "KILOGRAM",
  [vendorMeasureUnit.PZ]: "PIECE",
  [vendorMeasureUnit.BOX]: "BOX",
  [vendorMeasureUnit.BAG]: "SACK",
  [vendorMeasureUnit.BUCKET]: "BUCKET",
  [vendorMeasureUnit.CAN]: "CAN",
  [vendorMeasureUnit.GALLON]: "GALLON",
  [vendorMeasureUnit.PACKAGE]: "PACKAGE",
  [vendorMeasureUnit.DRUM]: "DRUM",
  [vendorMeasureUnit.TIN]: "TIN",
  [vendorMeasureUnit.ML]: "MILLILITER",
  [vendorMeasureUnit.LITER]: "LITER",
  [vendorMeasureUnit.BAG_SMALL]: "BAG",
  [vendorMeasureUnit.UN]: "UNIT",
  [vendorMeasureUnit.BED]: "BED",
  [vendorMeasureUnit.THOUSAND]: "THOUSAND",
  [vendorMeasureUnit.G]: "GRAM",
  [vendorMeasureUnit.ROLL]: "ROLL",
  [vendorMeasureUnit.JUG]: "JUG",
  [vendorMeasureUnit.JAR]: "JAR",
  [vendorMeasureUnit.SACK]: "SACK",
  [vendorMeasureUnit.BOTTLE]: "BOTTLE",
  [vendorMeasureUnit.CRATE]: "CRATE",
  [vendorMeasureUnit.DOME]: "DOME",
  [vendorMeasureUnit.COVER]: "COVER",
  [vendorMeasureUnit.TETRAPACK]: "TETRAPACK",
  [vendorMeasureUnit.ATADO]: "BUNDLE",

  [commentArea.TITLE]: "Comments Section",
  [commentArea.SHOW_ANSWER]: "Show Answers",
  [commentArea.HIDE_ANSWER]: "Hide Answers",
  [commentArea.COMMENT_LABEL]: "Comment",
  [commentArea.ANSWER_LABEL]: "Answer",

  [bulkLoadAction.CREATE]: "Insert",
  [bulkLoadAction.MODIFY]: "Modify",
  [bulkLoadAction.NOTHING]: "No Action",
  [bulkLoadAction.NOTHING_BY_ERROR]: "No Action due to Error",

  [bulkLoadModule.TABLE_INFO]:
    "Preview of record import. Please confirm the data and save.",
  [bulkLoadModule.REQUIRED_COLUMNS]: "The first {{num}} columns are mandatory",

  [bulkLoadError.VENDOR_CODE_NOT_FOUND]: "Supplier code not found.",
  [bulkLoadError.PRODUCT_NOT_FOUND]: "SKU not found.",
  [bulkLoadError.KITCHEN_NOT_FOUND]: "Kitchen not found.",
  [bulkLoadError.VENDOR_TYPE_NOT_FOUND]: "Type not found.",
  [bulkLoadError.ALL_FIELDS_MUST_BE_FILLED_TO_INSERT]:
    "To insert, all fields must be filled.",
  [bulkLoadError.UNIDENTIFIED]: "Unidentified error.",
  [bulkLoadError.VENDOR_UNIT_NOT_FOUND]: "Supplier unit not found.",

  [replacementModule.TITLE]: "Transformations",
  [replacementModule.REPLACE]: "Replacement",
  [replacementModule.QUANTITY_FINAL]: "Final Quantity",
  [replacementModule.QUANTITY_INITIAL]: "Initial Quantity",
  [replacementModule.AUTHORIZE_TITLE]: "Authorize Transformations",
  [replacementModule.DESCRIPTION]: "Registration of transformation requests",
  [replacementModule.AUTHORIZE_DESCRIPTION]:
    "Authorize transformation requests",

  [inventoryMovement.EMPTY_TABLE]: "No movements found",
  [commons.DETAIL]: "Detail",
  [commons.PAID]: "Paid",
  [commons.PAYMENT_DATE]: "payment date",
  [purchaseOrderModule.ACCOUNT_TITLE]: "Record purchase orders",
  [purchaseOrderModule.ACCOUNT_DESCRIPTION]: "Record and pay purchase orders",
  [purchaseOrderModule.CHANGE_TO_PAID_TITLE]: "Purchase Order Paid",
  [purchaseOrderModule.CHANGE_TO_PAID_DESCRIPTION]:
    "The following purchase orders will be marked as paid",
  [purchaseOrderModule.CHANGE_TO_ACCOUNTED_TITLE]: "Purchase Order Accounted",
  [purchaseOrderModule.CHANGE_TO_ACCOUNTED_DESCRIPTION]:
    "The following purchase orders will be marked as accounted",

  [general.ALL]: "All",

  [productionModifyReason.LABEL_SELECT]: "Reason",
  [productionModifyReason.PLACEHOLDER_SELECT]: "Select the reason",
  [productionModifyReason.LACK_OF_SUPPLIES]: "Lack of supplies",
  [productionModifyReason.DAMAGED_EQUIPMENT]: "Damaged equipment",
  [productionModifyReason.LACK_OF_PERSONNEL]: "Lack of personnel",
  [productionModifyReason.WATER_RATIONING]: "Water rationing",
  [productionModifyReason.POOR_PLANNING]: "Poor planning",
  [productionModifyReason.SUGGESTED_TOO_HIGH]: "Suggested too high",
  [productionModifyReason.STOCKOUTS]: "Stockouts",
  [productionModifyReason.PRODUCTION_ABOVE_PLAN]: "Production above plan",
  [productionModifyReason.SUGGESTED_TOO_LOW]: "Suggested too low",
  [productionModifyReason.TRAINING]: "Training",
  [productionModifyReason.INVENTORY_EXPIRATION]: "Inventory expiration",
  [productionModifyReason.LAUNCH]: "Launch",
  [productionModifyReason.YIELD]: "Yield",

  [supplyManagement.DRAWER_TITLE]: "Supply Status",
  [supplyManagement.KITCHEN_STATUS]: "Status in Kitchens",
  [supplyManagement.SELECT_CITY]: "Select a City",
  [supplyManagement.EMPTY_STATE_MSG]: "No supplies selected",
  [supplyManagement.EMPTY_STATE_INST]: "Search for a supply to continue",
  [supplyManagement.UPDATE_DIALOG_TITLE]: "Manage Supplies",
  [supplyManagement.UPDATE_DIALOG_CONTENT]:
    "Select the kitchens in which you want to activate or deactivate the selected supplies.",
  [supplyManagement.UPDATE_DIALOG_FOOTER]:
    "Inventory status for {{nro}} supply(ies) with the selected filter",
  [supplyManagement.UPDATE_DIALOG_FOOTER_ENABLE]: "Enable",
  [supplyManagement.UPDATE_DIALOG_FOOTER_DISABLE]: "Disable",
};
