import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import PurchaseOrderConciliationDetailModal from "app/pages/PurchaseOrderLine/Conciliation/detailModal";
import PurchaseOrderDetailModal from "app/pages/PurchaseOrderLine/detailModal";
import { viewPurchaseOrderPdf } from "core/attachment/repository/http";
import {
  OriginType,
  Purchase,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import PurchaseOrderStatusChip from "../PurchaseOrder/Status/Chip";
import WithInvoiceChip from "../PurchaseOrder/WithInvoiceChip";
import UpdateExpectedReceiptAtTextField from "./UpdateExpectedReceiptAtTextField";

interface Props {
  items: Purchase[];
  loading: boolean;
  isConciliation?: boolean;
  onUpdateItem?: (id: string, purchase: Purchase) => void;
  selectedItems?: KOSSelectedResult[];
  setSelectedItems?: (selectedItems: KOSSelectedResult[]) => void;
  canPagination?: boolean;
  onUpdate: () => void;
}

const PurchaseTable: FunctionComponent<Props> = (props) => {
  const {
    items,
    loading,
    isConciliation,
    onUpdateItem,
    selectedItems,
    setSelectedItems,
    canPagination,
    onUpdate,
  } = props;

  const { t } = useTranslation();

  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [openDetailReview, setOpenDetailReview] = useState<boolean>(false);
  const [selectedPurchase, setSelectedPurchase] = useState<Purchase | null>(
    null
  );

  const statusChip = (purchase: Purchase): React.ReactElement => {
    return <PurchaseOrderStatusChip purchase={purchase} />;
  };

  const withInvoice = (purchase: Purchase): React.ReactElement => {
    return <WithInvoiceChip purchaseOrder={purchase} />;
  };

  const onShowDetail = (item: KOSRowData) => {
    setSelectedPurchase(item as Purchase);
    if (isConciliation) {
      setOpenDetail(true);
    } else {
      setOpenDetailReview(true);
    }
  };

  const onClose = () => {
    setOpenDetailReview(false);
    setOpenDetail(false);
  };

  const getHeader = useCallback((): KOSBaseTableHeader[] => {
    const extra: KOSBaseTableHeader[] = isConciliation
      ? [{ label: t(commons.RECEIPT), field: "receiptDate", type: "date" }]
      : [
          {
            label: t(purchaseOrderCreation.INITIAL_ESTIMATED_DATE_LABEL),
            type: "date",
            align: "center",
            field: "initialExpectedReceiptAt",
          },
          {
            label: t(purchaseOrderCreation.RECEIPT_DATE_LABEL),
            type: "date",
            align: "center",
            field: "receiptDate",
          },
          {
            label: t(purchaseOrderCreation.ESTIMATED_DATE_LABEL),
            type: "date",
            align: "center",
            text: (item: KOSRowData) => {
              if (item.status === "PENDING") return undefined;
              return item.expectedReceiptAt;
            },
            component: (item: KOSRowData) => {
              if (item.status !== "PENDING") return undefined;
              return (
                <UpdateExpectedReceiptAtTextField
                  purchase={item as Purchase}
                  onUpdateItem={onUpdateItem}
                />
              );
            },
          },
        ];

    return [
      {
        label: t(commons.ORDER_ID),
        text: (item: KOSRowData) =>
          item.origin === OriginType.KIS ? item.code : item.orderId,
      },
      {
        label: t(commons.SUPPLIER),
        field: "vendorName",
      },
      {
        label: t(commons.KITCHEN),
        field: "kitchenId",
      },
      {
        label: t(commons.TOTAL_PRICE),
        align: "right",
        text: (item: KOSRowData) =>
          formatPrice(
            item.totalNet + (item.adjustmentAmount ?? 0),
            localeData[item.country]
          ),
      },
      {
        label: t(commons.ORDER_DATE),
        type: "date",
        align: "center",
        field: "dateDocument",
      },
      ...extra,
      {
        label: t(purchaseOrderCreation.CREATE_ORDER_MODAL_INVOICE_LABEL),
        field: "invoiceNo",
      },
      {
        label: t(commons.WITH_INVOICE),
        align: "center",
        component: (item: KOSRowData) => withInvoice(item as Purchase),
      },
      {
        label: t(commons.STATUS),
        align: "center",
        component: (item: KOSRowData) => statusChip(item as Purchase),
      },
      {
        label: t(commons.ACTIONS),
        align: "center",
        component: (item: KOSRowData) => {
          return (
            <Box>
              <IconButton
                onClick={() => {
                  onShowDetail(item);
                }}
              >
                <FactCheckOutlinedIcon color={"primary"} />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (item.origin === OriginType.KIS) {
                    viewPurchaseOrderPdf(item as Purchase);
                  }
                }}
                disabled={item.origin === OriginType.BC}
              >
                <InsertDriveFileOutlinedIcon />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  }, [isConciliation]);

  return (
    <Box>
      <KOSBaseTable
        columns={getHeader()}
        rows={{
          data: items,
          total: items.length ?? 0,
          loading: !!loading,
        }}
        pagination={{ enable: !!canPagination }}
        selectable={
          !isConciliation && selectedItems && setSelectedItems
            ? {
                resultHeader: [
                  "country",
                  "orderId",
                  "code",
                  "kitchenId",
                  "vendorName",
                ],
                items: selectedItems,
                onChange: setSelectedItems,
                canSelectAll: true,
              }
            : undefined
        }
      />

      <PurchaseOrderConciliationDetailModal
        open={openDetail}
        onClose={onClose}
        orderId={selectedPurchase?.orderId ?? ""}
        kitchenId={selectedPurchase?.kitchenId ?? ""}
        onUpdate={onUpdate}
      />

      <PurchaseOrderDetailModal
        open={openDetailReview}
        onClose={onClose}
        kitchenId={selectedPurchase?.kitchenId ?? ""}
        orderId={selectedPurchase?.orderId ?? ""}
        onUpdate={onUpdate}
      />
    </Box>
  );
};

export default PurchaseTable;
