import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Grid, LinearProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CommonDialog from "app/components/common/CommonDialog";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useCompany } from "app/hooks/useCompany";
import { productionSchedule } from "app/i18n/types";
import { archiveProductionPlan } from "app/store/slices/productions/plan/thunks";
import { ProductionPlan } from "core/productions/entities/Plan";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProductionModifyReasonSelect from "../../ModifyReason";

interface Props {
  productionPlan: ProductionPlan;
  removeCard: () => void;
  reloadDay: () => void;
}

const ProductionScheduleCardDeleteButton = (
  props: Props
): React.ReactElement => {
  const { productionPlan, removeCard, reloadDay } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const company = useCompany();
  const productionReason = company.config("productionReason") ?? [];
  const askForReason = (productionReason as string[]).includes("ARCHIVE");

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");

  const remove = async () => {
    if (!reason) return;
    setLoading(true);
    const response = await dispatch(
      archiveProductionPlan({
        productionPlanId: productionPlan.id,
        comment: reason,
      })
    );
    const archived = response.payload as boolean;
    setLoading(false);
    if (archived) {
      removeCard();
    } else {
      reloadDay();
    }
  };

  return (
    <>
      <IconButton size="small" onClick={() => setOpenDeleteDialog(true)}>
        <DeleteOutlineIcon fontSize="small" />
      </IconButton>
      {openDeleteDialog && (
        <CommonDialog
          open
          showCancelButton
          title={t(productionSchedule.DELETE_TITLE)}
          handleConfirm={remove}
          handleClose={() => setOpenDeleteDialog(false)}
          disabledConfirmButton={askForReason ? !reason : false}
          bodyElement={
            <Grid container spacing={2}>
              {loading && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t(productionSchedule.DELETE_MESSAGE, {
                    productionName: productionPlan.name,
                  })}
                </Typography>
              </Grid>
              {askForReason && (
                <Grid item xs={12}>
                  <ProductionModifyReasonSelect
                    type="ARCHIVE"
                    onChangeAction={setReason}
                    disabled={loading}
                  />
                </Grid>
              )}
            </Grid>
          }
        />
      )}
    </>
  );
};

export default ProductionScheduleCardDeleteButton;
