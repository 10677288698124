import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Typograhpy from "@mui/material/Typography";
import { useNavigator } from "app/hooks/useNavigator";
import { commons } from "app/i18n/types";
import { AmplitudeEvent, logEvent } from "core/common/utils/analytics";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SelectedProductionActionType } from "utils/ratatouille";
import ProductionCardIngredientsToPrint from "./IngredientsToPrint";

interface Props {
  production: ProductionRecord;
  kitchenId: string | null;
  openDelete: () => void;
  setSelectedProduction: (action: SelectedProductionActionType) => void;
  isManual?: boolean;
  isFinished?: boolean;
}

const ProductionCardHeaderActions: FunctionComponent<Props> = (props) => {
  const {
    production,
    kitchenId,
    openDelete,
    setSelectedProduction,
    isFinished,
    isManual,
  } = props;
  const { t } = useTranslation();
  const navigator = useNavigator();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <Typograhpy variant="caption" color="text.secondary">
        {t(commons.ACTIONS)}
      </Typograhpy>
      <Box display="flex" gap={0.5}>
        {!isFinished && (
          <>
            <ProductionCardIngredientsToPrint production={production} compact />
            {isManual && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ minWidth: 56 }}
                  onClick={() => {
                    navigator.toByLocationType(
                      `/productions/${kitchenId}/edit/${production.id}`
                    );
                    logEvent(AmplitudeEvent.OpenEditProduction, {
                      kitchenId: kitchenId,
                      productionId: production.id,
                      sku: production.sku,
                      productName: production.name,
                    });
                  }}
                >
                  <EditOutlinedIcon color="primary" />
                </Button>
                <Button
                  onClick={openDelete}
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ minWidth: 56 }}
                >
                  <DeleteOutlinedIcon color="error" />
                </Button>
              </>
            )}
          </>
        )}
        {isFinished && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{ minWidth: 56 }}
            onClick={() =>
              setSelectedProduction(SelectedProductionActionType.LABEL_HISTORY)
            }
            disabled={!production.labels.length}
          >
            <HistoryOutlinedIcon
              color={!production.labels.length ? "disabled" : "primary"}
            />
          </Button>
        )}
      </Box>
    </Box>
  );
};
export default ProductionCardHeaderActions;
