import appConfig from "config/app";
import { Session } from "core/account/entities/Session";
import { getCookie, removeCookie } from "./cookie";

export const sessionValidator = (session: Session) => {
  const {
    kitchenDisplay: { url: kds },
    kitchenInventory: { url },
  } = appConfig;
  const redirect = `${kds}/login?redirect=${url}${window.location.pathname}`;
  if (!session) {
    console.error("Cookie session not found");
    window.location.href = redirect;
  }
  const { userId, token } = session;
  if (!userId || !token) {
    console.error("Cookie session not found");
    window.location.href = redirect;
  }
};

export const getSession = (): Session => {
  const cookieUserId = getCookie("userId");
  const cookieLoginToken = getCookie("loginToken");
  const cookieCompanyId = getCookie("companyId");
  const authorization = getCookie("authorization");

  const session: Session = {
    userId: cookieUserId ?? "",
    token: cookieLoginToken ?? "",
    companyId: cookieCompanyId ?? "fdgy",
    authorization: authorization ?? "",
  };

  sessionValidator(session);

  return session;
};

export const removeSession = (): void => {
  const cookieUserId = getCookie("userId");
  if (cookieUserId) {
    removeCookie("userId");
  }
  const cookieLoginToken = getCookie("loginToken");
  if (cookieLoginToken) {
    removeCookie("loginToken");
  }
};
