import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FunctionComponent, useCallback } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Chip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { setSupplyDisplayMeasureUnit } from "app/store/slices/productions";
import { getSupplyQuantity, measureUnitsConvert } from "app/utils/measureUnits";
import {
  CatalogProductionIngredient,
  ProductionCatalog,
} from "core/productions/entities/Catalog";
import {
  MeasureUnits,
  VolumeUnits,
  WeightUnits,
} from "core/productions/entities/Productions";
import { useTranslation } from "react-i18next";
import { commons } from "../../i18n/types";

interface ProductionCatalogTableProps {
  production: ProductionCatalog;
  quantityToProduce: number | null;
  showInventoryOnNewProductions: boolean;
}

interface QuantityDecoration {
  color: "error" | "success" | "warning";
  icon: JSX.Element;
}

const ProductionCatalogTable: FunctionComponent<ProductionCatalogTableProps> = (
  props
) => {
  const { production, quantityToProduce, showInventoryOnNewProductions } =
    props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const setDisplayUnit = useCallback(
    (productionSku: string, supplySku: string, measureUnit: MeasureUnits) => {
      dispatch(
        setSupplyDisplayMeasureUnit({ productionSku, supplySku, measureUnit })
      );
    },
    []
  );

  const getDecoration = (
    productionDetail: CatalogProductionIngredient
  ): QuantityDecoration => {
    const inventory = productionDetail.inventory ?? 0;
    const quantity = getSupplyQuantity(
      quantityToProduce,
      productionDetail.quantity
    );

    const safeQuantity = inventory * 0.9;

    if (quantity >= safeQuantity && quantity < inventory) {
      return { color: "warning", icon: <NotificationsIcon /> };
    }

    if (inventory >= quantity) {
      return { color: "success", icon: <CheckCircleOutlineIcon /> };
    }

    return { color: "error", icon: <WarningAmberIcon /> };
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Tabla de producciones">
        <TableHead>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell> {t(commons.INGREDIENT_NAME)}</TableCell>
            {showInventoryOnNewProductions && (
              <TableCell>{t(commons.EXISTING_QUANTITY)}</TableCell>
            )}
            <TableCell> {t(commons.REQUIRED_QUANTITY)}</TableCell>
            <TableCell>{t(commons.UNIT)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {production.ingredients.map((supply) => (
            <TableRow
              key={supply.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell>{supply.sku}</TableCell>
              <TableCell>{supply.name}</TableCell>
              {showInventoryOnNewProductions && (
                <TableCell>
                  {(supply.inventory ?? 0).toFixed(0)} {supply.unit}
                </TableCell>
              )}
              <TableCell>
                <Chip
                  sx={{ p: "5px" }}
                  label={measureUnitsConvert(
                    getSupplyQuantity(quantityToProduce, supply.quantity),
                    supply.unit,
                    supply.displayUnit ?? supply.unit
                  )}
                  color={getDecoration(supply).color}
                  icon={getDecoration(supply).icon}
                ></Chip>
              </TableCell>
              <TableCell>
                {(supply.unit === WeightUnits.g ||
                  supply.unit === WeightUnits.gr ||
                  supply.unit === WeightUnits.kg) && (
                  <Select
                    value={supply.displayUnit ?? supply.unit}
                    label="Unidad"
                    onChange={(event: SelectChangeEvent) =>
                      setDisplayUnit(
                        production.sku,
                        supply.sku,
                        event.target.value as MeasureUnits
                      )
                    }
                    size="small"
                    fullWidth
                  >
                    {(supply.unit === WeightUnits.gr ||
                      supply.unit === WeightUnits.kg) && (
                      <MenuItem value="GR">GR</MenuItem>
                    )}
                    {supply.unit === WeightUnits.g && (
                      <MenuItem value="G">G</MenuItem>
                    )}
                    <MenuItem value="KG">KG</MenuItem>
                  </Select>
                )}
                {(supply.unit === VolumeUnits.ml ||
                  supply.unit === VolumeUnits.ltr) && (
                  <Select
                    value={supply.displayUnit ?? supply.unit}
                    label="Unidad"
                    onChange={(event) =>
                      setDisplayUnit(
                        production.sku,
                        supply.sku,
                        event.target.value as MeasureUnits
                      )
                    }
                    size="small"
                    fullWidth
                  >
                    <MenuItem value="ML">ML</MenuItem>
                    {supply.unit === VolumeUnits.ltr ? (
                      <MenuItem value="LTR">LTR</MenuItem>
                    ) : (
                      <MenuItem value="L">L</MenuItem>
                    )}
                  </Select>
                )}
                {supply.unit === "UNI" && (
                  <Select
                    value={supply.displayUnit ?? supply.unit}
                    label="Unidad"
                    size="small"
                    disabled
                    fullWidth
                  >
                    {supply.unit === "UNI" && (
                      <MenuItem value="UNI">UNI</MenuItem>
                    )}
                  </Select>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductionCatalogTable;
