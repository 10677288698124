import { KOSBaseTableHeader } from "@foodology-co/alejandria";
import { bcIntegrations, commons } from "app/i18n/types";
import { TransferOrderReceiptError } from "core/BusinessCentralIntegration/entities/BusinessCentralIntegration";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import BcIntegrationTable from "./BcIntegrationTable";

interface Props {
  transferReceiptErrors: TransferOrderReceiptError[];
  loading: boolean;
  archive: (id: string) => Promise<void>;
}

const TransferReceiptErrors: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { transferReceiptErrors, loading, archive } = props;

  const getTransferReceiptHeader = (): KOSBaseTableHeader[] => {
    return [
      {
        label: t(commons.COUNTRY),
        field: "country",
      },
      {
        label: t(commons.LOCATION),
        field: "kitchenId",
      },
      {
        label: t(commons.CODE),
        field: "transferOrderCode",
      },
      {
        label: t(commons.STATUS),
        field: "status",
      },
      {
        label: t(commons.ERROR),
        field: "error",
      },
    ];
  };

  return (
    <BcIntegrationTable
      data={transferReceiptErrors}
      headers={getTransferReceiptHeader()}
      loading={loading}
      title={t(bcIntegrations.TRANSFER_RECEIPT)}
      archive={archive}
    />
  );
};
export default TransferReceiptErrors;
