export enum status {
  IN_PROCESS = "status.IN_PROCESS",
  ARCHIVED = "status.ARCHIVED",
  RELEASED = "status.RELEASED",
  PACKED = "status.PACKED",
  IN_TRANSIT = "status.IN_TRANSIT",
  RECEIVED = "status.RECEIVED",
  RETURNED = "status.RETURNED",
  PARTIAL_RECEIVED = "status.PARTIAL_RECEIVED",
  ALL = "status.ALL",
  FINISHED = "status.FINISHED",
  SCHEDULED = "status.SCHEDULED",
  VALIDATED = "status.VALIDATED",
}

export enum inventoryGroups {
  BEER = "BEER",
  PRODUCTION = "PRODUCTION",
  FINISHED_PRODUCT = "FINISHED_PRODUCT",
  SUPPLY = "SUPPLY",
  COMBO = "COMBO",
  GROCERY = "GROCERY",
  DRINK = "DRINK",
  ALCOHOLIC_DRINK = "ALCOHOLIC_DRINK",
  MEAT = "MEAT",
  CIGARETTES = "CIGARETTES",
  ADJUSTABLE_PACKAGING = "ADJUSTABLE_PACKAGING",
  PACKAGING = "PACKAGING",
  FRUITS = "FRUITS",
  DAIRY = "DAIRY",
  MAINTENANCE = "MAINTENANCE",
  BAKERY = "BAKERY",
  UTENSILS = "UTENSILS",
}

export enum commons {
  DAILY_MASSIVE = "daily_massive",
  DAILY_INDIVIDUAL = "daily_individual",
  DAILY = "daily",
  MONTHLY = "monthly",
  WEEKLY = "weekly",
  RE_COUNT = "reCount",
  OTHER = "other",
  OTHER_INDIVIDUAL = "other_individual",
  OTHER_MASSIVE = "other_massive",
  ACCEPT = "commons.accept",
  PURCHASE_ORDER = "commons.purchase_order",
  INVENTORY = "commons.inventory",
  FILTERS = "commons.filters",
  FINISH = "commons.finish",
  START = "commons.start",
  RETURN = "commons.return",
  RETURN2 = "commons.return2",
  FINISHED = "commons.finished",
  NAME = "commons.name",
  MEASURE_UNIT = "commons.measure_unit",
  SKU = "commons.sku",
  STATUS = "commons.status",
  GROUP = "commons.group",
  DOES_NOT_APPLY = "commons.does_not_apply",
  DETAILS = "commons.details",
  DETAIL = "commons.DETAIL",
  SHOW_DETAILS = "commons.show_details",
  LOADING = "commons.loading",
  CATEGORIES = "commons.categories",
  CONTINUE = "commons.continuar",
  RECEIVE = "commons.receive",
  PROGRESS = "commons.progress",
  DELIVERY_DATE = "commons.delivery_date",
  ORDER_DATE = "commons.order_date",
  SUPPLIER = "commons.supplier",
  ORDER_ID = "commons.order_id",
  RECEIPT_ID = "commons.receipt_id",
  PRODUCT_NAME = "commons.product_name",
  REQUESTED = "commons.requested",
  RECEIVED = "commons.RECEIVED",
  UNIT = "commons.unit",
  UNIT_PRICE = "commons.unit_price",
  UNITS = "commons.units",
  TOTAL_PRICE = "commons.total_price",
  TOTAL = "commons.total",
  ACTIONS = "commons.actions",
  ACTION = "commons.action",
  INIT_DATE = "commons.initDate",
  FINAL_DATE = "commons.finalDate",
  SEARCH = "commons.search",
  EXPORT = "commons.export",
  CANCEL = "commons.cancel",
  DELETE = "commons.delete",
  MARK_RECEIVED = "commons.mark.received",
  SAVE = "commons.save",
  BACK = "commons.back",
  KITCHENS = "commons.kitchens",
  KITCHEN = "commons.kitchen",
  COUNTRIES = "commons.countries",
  TYPE = "commons.type",
  SELECT_FILE = "commons.SELECT_FILE",
  SELECT_TEMPLATE = "commons.SELECT_TEMPLATE",
  MAKE_ADJUSTMENT = "commons.make_adjustment",
  QUANTITY = "commons.quantity",
  LAST_MODIFIED = "commons.last_modified",
  PERSON_IN_CHARGE = "commons.person_in_charge",
  REASON = "commons.reason",
  RETRY = "commons.retry",
  DATETIME = "commons.datetime",
  AUTHORIZE = "commons.authorize",
  EMPTY_RESULT = "commons.empty_result",
  BACK_BUTTON = "common.back_button",
  ENTER = "common.enter",
  FIND_INPUT = "common.find_input",
  FIND_LOCATION_TITLE = "common.find_location_title",
  FIND_LOCATION_INPUT = "common.find_location",
  CONFIRM = "common.confirm",
  ENTER_QUANTITY = "common.enter_quantity",
  CONFIRMATION = "common.confirmation",
  VALIDATE = "common.validate",
  VALIDATED = "commons.VALIDATED",
  WITHOUT_INVOICE = "commons.WITHOUT_INVOICE",
  ERROR_IN_PRICE = "commons.ERROR_IN_PRICE",
  ERROR_IN_QUANTITY = "commons.ERROR_IN_QUANTITY",
  ERROR_IN_PRICE_AND_QUANTITY = "commons.ERROR_IN_PRICE_AND_QUANTITY",
  ACCOUNTED = "commons.ACCOUNTED",
  PRE_VALIDATED = "commons.PRE_VALIDATED",
  SHOW_ALL = "common.show_all",
  ALL = "common.ALL",
  NEW_PRODUCTION = "common.new_production",
  PRODUCTION_QUANTITY = "common.production_quantity",
  INGREDIENT_NAME = "common.ingredient.name",
  REQUIRED_QUANTITY = "common.required.quantity",
  EXISTING_QUANTITY = "common.existing.quantity",
  USED_QUANTITY = "common.used.quantity",
  FINAL_QUANTITY = "common.used.final_quantity",
  EXISTENCE = "common.used.existence",
  UPDATE = "common.update",
  SELECT_COUNTRY = "common.select.country",
  SELECT_CITY = "common.select.city",
  SELECT_LOCATION = "common.select.location",
  SELECT_LOCATIONS = "common.select.locations",
  INGREDIENTS = "common.ingredients",
  SELECTED = "common.SELECTED",
  SCHEDULE_DATE = "common.SCHEDULE_DATE",
  TABLE_EMPTY = "common.TABLE_EMPTY",
  PARTIAL = "commons.PARTIAL",
  PARTIAL_RECEIVED = "commons.PARTIAL_RECEIVED",
  PENDING = "commons.PENDING",
  COMPLETE = "commons.COMPLETE",
  PARTIAL_PLURAL = "common.PARTIAL_PLURAL",
  PENDING_PLURAL = "common.PENDING_PLURAL",
  COMPLETE_PLURAL = "common.COMPLETE_PLURAL",
  REMAINING = "common.REMAINING",
  SENT = "common.SENT",
  SEND_DATE = "common.SENT_DATE",
  ERROR_TITLE = "common.ERROR_TITLE",
  ERROR_SUBTITLE = "common.ERROR_SUBTITLE",
  SUPPLIES = "common.SUPPLIES",
  SUPPLY = "common.SUPPLY",
  ACCURACY = "common.ACCURACY",
  FIND_SUPPLY_LABEL = "common.FIND_SUPPLY_LABEL",
  SHOWING = "common.SHOWING",
  SELECT_OPTION = "common.SELECT_OPTION",
  SELECT_COUNTRY_CITY_KITCHEN = "common.SELECT_COUNTRY_CITY_KITCHEN",
  SELECT_MOVE_FILE = "common.SELECT_MOVE_FILE",
  SELECT_MOVE_TEMPLATE = "common.SELECT_MOVE_TEMPLATE",
  ONLY_FILE_TYPE = "common.ONLY_FILE_TYPE",
  SELECTED_FILE = "common.SELECTED_FILE",
  UPLOAD_FILE = "common.UPLOAD_FILE",
  UPLOAD_EVIDENCE = "common.UPLOAD_EVIDENCE",
  DATE = "common.DATE",
  REPORTED_AMOUNT = "common.REPORTED_AMOUNT",
  SEND_TO_VALIDATION = "common.SEND_TO_VALIDATION",
  TO_AUTHORIZE = "common.TO_AUTHORIZE",
  REPORT = "common.REPORT",
  FOR_VALIDATE = "common.FOR_VALIDATE",
  CLOSE = "common.CLOSE",
  SAVE_CHANGES = "common.SAVE_CHANGES",
  WEEK = "common.WEEK",
  AMOUNT_SET_ASIDE = "common.AMOUNT_SET_ASIDE",
  TRANSFER_DATE = "common.TRANSFER_DATE",
  PROGRAMMED_KITCHENS = "common.PROGRAMMED_KITCHENS",
  TOTAL_SUPPLIES = "common.TOTAL_SUPPLIES",
  PROGRAMMED_BY = "common.PROGRAMMED_BY",
  PACKING_PROGRESS = "common.PACKING_PROGRESS",
  PACKING_PROGRESS_SUPPLIES = "common.PACKING_PROGRESS_SUPPLIES",
  PACKING_PROGRESS_KITCHENS = "common.PACKING_PROGRESS_KITCHENS",
  SCANNED = "common.SCANNED",
  SCANNED_SUCCESSFULLY = "common.SCANNED_SUCCESSFULLY",
  SCANNING_ERROR = "common.SCANNING_ERROR",
  SEARCH_RESULT = "common.SEARCH_RESULT",
  RESULTS_FOR = "common.RESULTS_FOR",
  READING_BARCODE_SCANNER = "common.READING_BARCODE_SCANNER",
  PRODUCTION_ALREADY_ENTERED = "common.PRODUCTION_ALREADY_ENTERED",
  ADD = "common.ADD",
  DOCUMENT_CODE = "common.DOCUMENT_CODE",
  PRINT_LABELS = "common.PRINT_LABELS",
  PACK = "common.PACK",
  PRINT = "common.PRINT",
  SUPPLIES_FILTER = "common.SUPPLIES_FILTER",
  VIEW_MODES = "common.VIEW_MODES",
  FOR_SUPPLY = "common.FOR_SUPPLY",
  FOR_KITCHEN = "common.FOR_KITCHEN",
  EMAIL_LABEL = "common.EMAIL_LABEL",
  ORDER_DETAILS = "common.ORDER_DETAILS",
  ENTER_INVOICE_OR_REMISSION_NO = "common.ENTER_INVOICE_OR_REMISSION_NO",
  DECLINE = "common.DECLINE",
  FILE_TOO_BIG = "common.FILE_TOO_BIG",
  ORDER_RECEIPT = "common.ORDER_RECEIPT",
  RECEIPT = "common.RECEIPT",
  CORRECTED = "commons.CORRECTED",
  CHANGE_STATUS = "common.CHANGE_STATUS",
  SELECT_A_NEW_STATUS = "common.SELECT_A_NEW_STATUS",
  RECIVED_QUANTITY = "common.RECIVED_QUANTITY",
  VIEW_PDF = "common.VIEW_PDF",
  PDF = "common.PDF",
  MODIFY = "common.MODIFY",
  COMMENT = "common.COMMENT",
  PRODUCTIONS = "common.PRODUCTIONS",
  MONDAY = "common.MONDAY",
  TUESDAY = "common.TUESDAY",
  WEDNESDAY = "common.WEDNESDAY",
  THURSDAY = "common.THURSDAY",
  FRIDAY = "common.FRIDAY",
  SATURDAY = "common.SATURDAY",
  SUNDAY = "common.SUNDAY",
  TODAY = "common.TODAY",
  TO_THE = "common.TO_THE",
  JANUARY = "common.JANUARY",
  FEBRUARY = "common.FEBRUARY",
  MARCH = "common.MARCH",
  APRIL = "common.APRIL",
  MAY = "common.MAY",
  JUNE = "common.JUNE",
  JULY = "common.JULY",
  AUGUST = "common.AUGUST",
  SEPTEMBER = "common.SEPTEMBER",
  OCTOBER = "common.OCTOBER",
  NOVEMBER = "common.NOVEMBER",
  DECEMBER = "common.DECEMBER",
  IMPORT = "common.IMPORT",
  MASSIVE = "common.MASSIVE",
  INDIVIDUAL = "common.INDIVIDUAL",
  TIME = "common.TIME",
  HOUR = "common.HOUR",
  PRODUCTION = "common.PRODUCTION",
  NO_SEARCH_RESULTS = "common.NO_SEARCH_RESULTS",
  ID = "common.ID",
  PREPARE = "common.PREPARE",
  QUANTITY_ERROR = "common.QUANTITY_ERROR",
  CODE = "common.CODE",
  ERROR = "common.ERROR",
  ACTIVATE = "common.ACTIVATE",
  INACTIVATE = "common.INACTIVATE",
  EXIT = "common.exit",
  ARCHIVE = "common.ARCHIVE",
  RESOURCES = "common.RESOURCES",
  ROUTES = "common.ROUTES",
  COUNTRY = "common.COUNTRY",
  CITY = "common.CITY",
  FILTER_BY_CITY = "common.FILTER_BY_CITY",
  LOCATION = "common.LOCATION",
  LABELS = "common.LABELS",
  VIEW_LABELS = "common.VIEW_LABELS",
  RETURNED = "common.RETURNED",
  CATEGORY = "common.CATEGORY",
  ADDRESS = "common.ADDRESS",
  PHONE = "common.PHONE",
  YES = "common.YES",
  NO = "common.NO",
  REQUIRED_FIELD = "common.REQUIRED_FIELD",
  SUCCESSFUL_PROCESS = "common.SUCCESSFUL_PROCESS",
  FAILED_PROCESS = "common.FAILED_PROCESS",
  SKU_TYPE_CITY_ALREADY_EXIST = "common.SKU_TYPE_CITY_ALREADY_EXIST",
  CONTENT_IS_EMPTY = "common.CONTENT_IS_EMPTY",
  SEND = "common.SEND",
  BETTER = "common.BETTER",
  BUYER = "common.BUYER",
  DOWNLOAD_PDFS = "common.DOWNLOAD_PDFS",
  THE_PROCESS_CONTINUES = "common.THE_PROCESS_CONTINUES",
  A_SIMILAR_RECORD_ALREADY_EXIST = "common.A_SIMILAR_RECORD_ALREADY_EXIST",
  BULK_LOAD = "common.BULK_LOAD",
  TEMPLATE_VIEW = "common.TEMPLATE_VIEW",
  ERROR_LINE = "common.ERROR_LINE",
  EMPTY_ERROR = "common.EMPTY_ERROR",
  RESET = "common.RESET",
  SUCCESSFUL = "common.SUCCESSFUL",
  FAILED = "common.FAILED",
  RESULT = "common.RESULT",
  DOCUMENT = "common.DOCUMENT",
  VENDOR_INFO = "common.VENDOR_INFO",
  RECORD = "common.RECORD",
  OF = "common.OF",
  SEEKER = "common.SEEKER",
  FINISHED_PRODUCTS = "common.FINISHED_PRODUCTS",
  AUTOGENERATE = "common.AUTOGENERATE",
  LINK = "common.LINK",
  SUBTOTAL = "common.SUBTOTAL",
  INVOICE = "common.INVOICE",
  WITH_INVOICE = "common.WITH_INVOICE",
  ITEMS = "common.ITEMS",
  UNLINK = "common.UNLINK",
  VENDOR = "common.VENDOR",
  OPS_REVIEW = "commons.OPS_REVIEW",
  PAID = "commons.PAID",
  PAYMENT_DATE = "commons.PAYMENT_DATE",
  LINKED = "commons.LINKED",
  FREEZING = "commons.Freezing",
  REFRIGERATION = "commons.Refrigeration",
  ROOM_TEMPERATURE = "commons.RoomTemperature",
  PRODUCTS = "commons.PRODUCTS",
  FINISHED_PLURAL = "commons.FINISHED_PLURAL",
  ORIGIN = "commons.ORIGIN",
  SELECT_STATION = "commons.SELECT_STATION",
}

export enum measureUnits {
  UN = "measureUnits.un",
  G = "measureUnits.g",
  ML = "measureUnits.ml",
  GR = "measureUnits.gr",
  KG = "measureUnits.kg",
  PCS = "measureUnits.pcs",
}

export enum adjustmentType {
  LOSS = "adjustmentType.LOSS",
  LOSSES = "adujustmentType.LOSSES",
  INTERNAL_CONSUMPTION = "adjustmentType.INTERNAL_CONSUMPTION",
  FINISHED_PRODUCT = "adjustmentType.FINISHED_PRODUCT",
  PRODUCTION_REJECTED = "adjustmentType.PRODUCTION_REJECTED",
  EVENT = "adjustmentType.EVENT",
  EVENTS = "adjustmentType.EVENTS",
  DISCARDED = "adjustmentType.DISCARDED",
  DISCARDEDS = "adjustmentType.DISCARDEDS",
  SALE = "adjustmentType.SALE",
  PRODUCTION = "adjustmentType.PRODUCTION",
  PURCHASE_ORDER = "adjustmentType.PURCHASE_ORDER",
  COUNTING = "adjustmentType.COUNTING",
  INITIAL = "adjustmentType.INITIAL",
  ADJUSTMENT = "adjustmentType.ADJUSTMENT",
  MANUAL_ADJUSTMENT = "adjustmentType.MANUAL_ADJUSTMENT",
  VALIDATION_ADJUSTMENT = "adjustmentType.VALIDATION_ADJUSTMENT",
  COUNTING_ADJUSTMENT = "adjustmentType.COUNTING_ADJUSTMENT",
  SALE_CANCELLATION = "adjustmentType.SALE_CANCELLATION",
  UPDATE_PURCHASE_ORDER_LINE = "adjustmentType.UPDATE_PURCHASE_ORDER_LINE",
  DAILY_COUNTING_ADJUSTMENT = "adjustmentType.DAILY_COUNTING_ADJUSTMENT",
  TRANSFER = "adjustmentType.TRANSFER",
  POSITIVE = "adjustmentType.POSITIVE",
  NEGATIVE = "adjustmentType.NEGATIVE",
  TRANSFORM = "adjustmentType.TRANSFORM",
}

export enum inventoryMovementType {
  ENTRY = "inventoryMovementType.entry",
  EGRESS = "inventoryMovementType.egress",
}

export enum inventoryMovement {
  EMPTY_TABLE = "inventoryMovement.EMPTY_TABLE",
}

export enum EventReasonType {
  DAMAGED = "EventReasonType.damaged_product",
  EXPIRED = "EventReasonType.expired_product",
  OBSOLETE = "EventReasonType.obsolete_product",
  TRAINING = "EventReasonType.training",
  MARKETING = "EventReasonType.marketing",
  QUALITY_TESTS = "EventReasonType.quality_tests",
}

export enum receiptType {
  PURCHASE_ORDER = "commons.receiptType.PURCHASE_ORDER",
  INBOUND_TRANSFER = "commons.receiptType.TRANSFER_ORDER",
  ALL = "commons.receiptType.ALL",
  PENDING = "receiptType.PENDING",
  RECEIVED = "receiptType.RECEIVED",
  DETAIL_TITLE = "receiptType.DETAIL_TITLE",
}

export enum physicalCountModule {
  TITLE = "module.physicalCount.title",
  SUBTITLE = "module.physicalCount.subtitle",
  HISTORY_SUBTITLE = "module.physicalCount.historySubtitle",
  CONFIRMATION_FINISHED_TITLE = "module.physicalCount.confirmItemModal.title",
  COUNTING_FINISHED_TITLE = "module.physicalCount.counting_finished.title",
  COUNTING_FINISHED_SUBTITLE = "module.physicalCount.counting_finished.subtitle",
  FILTER_SEARCH_PLACEHOLDER = "module.physicalCount.filter_search_text",
  DISARD_ITEM_MODAL_TITLE = "module.physicalCount.discardModal.title",
  DISARD_ITEM_MODAL_DESCRIPTION = "module.physicalCount.discardModal.description",
  EMPTY_COUNTINGS = "module.physicalCount.empty_count",
  CONSOLIDATED_EXPORT_TITLE = "module.physicalCountConsolidated.title",
  CONSOLIDATED_EXPORT_SUBTITLE = "module.physicalCountConsolidated.subtitle",
  FINISH_ITEM_MODAL_TITLE = "module.physicalCount.finishItemModal.title",
  FINISH_ITEM_MODAL_DESCRIPTION = "module.physicalCount.finishItemModal.description",
  CONFIRMATION_MODAL_DESCRIPTION = "module.physicalCount.confirmItemModal.description",
  CONFIRM_FINISH_COUNTING_TITLE = "module.physicalCount.finish.confirm.title",
  CONFIRM_FINISH_COUNTING_MESSAGE = "module.physicalCount.finish.confirm.message",
  SCHEDULED = "module.physicalCount.SCHEDULED",
  IN_PROCESS = "module.physicalCount.IN_PROCESS",
  FINISHED = "module.physicalCount.FINISHED",
  RELOAD_PAGE = "module.physicalCount.RELOAD_PAGE",
  REPLACE_VALUE = "module.physicalCount.REPLACE_VALUE",
  PRODUCT_EDIT_WARNING = "module.physicalCount.PRODUCT_EDIT_WARNING",
  COUNTING_CONFLICT = "module.physicalCount.COUNTING_CONFLICT",
  ARCHIVE_COUNTING_TITLE = "module.physicalCount.ARCHIVE_COUNTING_TITLE",
  ARCHIVE_COUNTING_MESSAGE = "module.physicalCount.ARCHIVE_COUNTING_MESSAGE",
  SEARCH_ITEM_NAME = "module.physicalCount.SEARCH_ITEM_NAME",
  SEARCH_ACTION = "module.physicalCount.SEARCH_ACTION",
  SELECT_ALL = "module.physicalCount.SELECT_ALL",
  FILTER_UNCOUNTED_ITEMS = "module.physicalCount.FILTER_UNCOUNTED_ITEMS",
  HISTORIAL = "module.physicalCount.HISTORIAL",
  ADD_QUANTITY_DESCRIPTION = "module.physicalCount.ADD_QUANTITY_DESCRIPTION",
  ADD_QUANTITY = "module.physicalCount.ADD_QUANTITY",
}

export enum purchaseOrderModule {
  RECORDS_PAGE_TITLE = "module.pruchaseOrder.records.title",
  RECORDS_PAGE_SUBTITLE = "module.pruchaseOrder.records.subtitle",
  DETAIL_PAGE_TITLE = "module.pruchaseOrder.details.title",
  FIND_ORDERS = "module.pruchaseOrder.find.title",
  PROGRESS = "module.pruchaseOrder.progress",
  ORDER_ID = "module.pruchaseOrder.find.order_id",
  RECEPTION = "module.pruchaseOrder.find.reception",
  TYPE = "module.pruchaseOrder.find.type",
  REQUEST_DATE = "module.pruchaseOrder.find.request.date",
  ITMES = "module.pruchaseOrder.find.items",
  UNIT = "module.pruchaseOrder.find.unit",
  FIND_ITEM = "module.pruchaseOrder.find.item",
  STATUS_RECEIPT = "module.pruchaseOrder.status.receipt",
  ADD_DOCUMENTS = "module.pruchaseOrder.ADD_DOCUMENTS",
  DOCUMENTS_LIST_TITLE = "module.pruchaseOrder.DOCUMENTS_LIST_TITLE",
  DOCUMENTS_LIST_INFO = "module.pruchaseOrder.DOCUMENTS_LIST_INFO",
  MARK_ASRECEIVED_TITLE = "module.pruchaseOrder.MARK_ASRECEIVED_TITLE_INFO",
  MARK_ASRECEIVED_MESSAGE = "module.pruchaseOrder.MARK_ASRECEIVED_TITLE_MESSAGE",
  DELETE_RECEIPT_TITLE = "module.pruchaseOrder.DELETE_RECEIPT_TITLE",
  DELETE_RECEIPT_MESSAGE = "module.pruchaseOrder.DELETE_RECEIPT_MESSAGE",
  RECEIPT = "module.pruchaseOrder.RECEIPT",
  TITLE = "purchaseOrder.TITLE",
  DESCRIPTION = "purchaseOrder.DESCRIPTION",
  FILTER_ORDERS = "purchaseOrder.FILTER_ORDERS",
  FIND_ORDER_LABEL = "purchaseOrder.FIND_ORDER_LABEL",
  PARTIAL_ORDERS = "purchaseOrder.PARTIAL_ORDERS",
  PENDING_ORDERS = "purchaseOrder.PENDING_ORDERS",
  COMPLETE_ORDERS = "purchaseOrder.COMPLETE_ORDERS",
  PERCENTAGE_TO_ORDERS = "purchaseOrder.PERCENTAGE_TO_ORDERS",
  ADD_DOCUMENTS_LABEL_INFO = "purchaseOrder.ADD_DOCUMENTS_LABEL_INFO",
  INVOICES = "purchaseOrder.INVOICES",
  DOWNLOAD_OCS = "purchaseOrder.DOWNLOAD_OCS",
  DOWNLOAD_LINES = "purchaseOrder.DOWNLOAD_LINES",
  INVOICE_ID = "purchaseOrder.INVOICE_ID",
  VENDOR_CODE = "purchaseOrder.VENDOR_CODE",
  NOTES = "purchaseOrder.NOTES",
  INVOICE_TITLE = "purchaseOrder.invoice.TITLE",
  INVOICE_DESCRIPTION = "purchaseOrder.invoice.DESCRIPTION",
  FILTER_INVOICES = "purchaseOrder.invoice.FILTER_INVOICES",
  IMPORT_TITLE = "purchaseOrder.invoice.IMPORT_TITLE",
  WATCH_ORDER = "purchaseOrder.invoice.WATCH_ORDER",
  LINK_ORDER = "purchaseOrder.invoice.LINK_ORDER",
  INVOICE_REPORT = "purchaseOrder.invoice.INVOICE_REPORT",
  INVOICE_REPORT_DETAILED = "purchaseOrder.invoice.INVOICE_REPORT_DETAILED",
  CONCILLIATION_CREATE_ADJUSTMENT = "purchaseOrder.invoice.CONCILLIATION_CREATE_ADJUSTMENT",
  CONCILLIATION_CREATE_ADJUSTMENT_DESCRIPTION = "purchaseOrder.invoice.CONCILLIATION_CREATE_ADJUSTMENT_DESCRIPTION",
  CONCILLIATION_CREATE_ADJUSTMENT_MAX = "purchaseOrder.invoice.CONCILLIATION_CREATE_ADJUSTMENT_MAX",
  CONCILLIATION_CREATE_ADJUSTMENT_VALUE = "purchaseOrder.invoice.CONCILLIATION_CREATE_ADJUSTMENT_VALUE",
  ACCOUNT_TITLE = "purchaseOrder.ACCOUNT_TITLE",
  ACCOUNT_DESCRIPTION = "purchaseOrder.ACCOUNT_DESCRIPTION",
  CHANGE_TO_PAID_TITLE = "purchaseOrder.CHANGE_TO_PAID_TITLE",
  CHANGE_TO_PAID_DESCRIPTION = "purchaseOrder.CHANGE_TO_PAID_DESCRIPTION",
  CHANGE_TO_ACCOUNTED_TITLE = "purchaseOrder.CHANGE_TO_ACCOUNTED_TITLE",
  CHANGE_TO_ACCOUNTED_DESCRIPTION = "purchaseOrder.CHANGE_TO_ACCOUNTED_DESCRIPTION",
}

export enum suppliesManagerModule {
  TITLE = "module.suppliesManager.records.title",
  SUBTITLE = "module.suppliesManager.records.subtitle",
  MASSIVE_SUCCESS_TITLE = "module.suppliesManager.massive.success.title",
  MASSIVE_SUCCESS_MESSAGE = "module.suppliesManager.massive.success.message",
}

export enum adjustmentsModule {
  TITLE = "module.adjustments.records.title",
  SUBTITLE = "module.adjustments.records.subtitle",
  INSTRUCTIONS = "module.adjustments.records.instructions",
  INSTRUCTIONS_DETAILS = "module.adjustments.records.instructions_details",
  BOX_DETAILS = "module.adjustments.records.box_details",
  MSG_OK_START_PROCCESS = "module.adjustments.records.msg_ok_start_proccess",
  MSG_FAIL_START_PROCCESS = "module.adjustments.records.msg_fail_start_proccess",
  SUCCESSFULLY_SAVE = "module.adjustments.records.SUCCESSFULLY_SAVE",
}

export enum dailyAdjustmentsModule {
  TITLE = "dailyAdjustmentsModule.title",
  TITLE_DISCARDED = "dailyAdjustmentsModule.waste.title",
  SUBTITLE_DISCARDED = "dailyAdjustmentsModule.waste.subtitle",
  INSTRUCTIONS_DISCARDED = "dailyAdjustmentsModule.waste.instructions",
  TITLE_LOSS = "dailyAdjustmentsModule.lowes.title",
  SUBTITLE_LOSS = "dailyAdjustmentsModule.lowes.subtitle",
  INSTRUCTIONS_LOSS = "dailyAdjustmentsModule.lowes.instructions",
  TITLE_CONSUMPTION = "dailyAdjustmentsModule.consumption.title",
  SUBTITLE_CONSUMPTION = "dailyAdjustmentsModule.consumption.subtitle",
  INSTRUCTIONS_CONSUMPTION = "dailyAdjustmentsModule.consumption.instructions",
  TITLE_EVENT = "dailyAdjustmentsModule.event.title",
  SUBTITLE_EVENT = "dailyAdjustmentsModule.event.subtitle",
  INSTRUCTIONS_EVENT = "dailyAdjustmentsModule.event.instructions",
  TITLE_FINISHED_PRODUCT = "dailyAdjustmentsModule.FINISHED_PRODUCT.title",
  SUBTITLE_FINISHED_PRODUCT = "dailyAdjustmentsModule.FINISHED_PRODUCT.subtitle",
  INSTRUCTIONS_FINISHED_PRODUCT = "dailyAdjustmentsModule.FINISHED_PRODUCT.instructions",
  REGISTER_SUPPLY_OR_PRODUCT_INSTRUCTION = "dailyAdjustmentsModule.REGISTER_SUPPLY_OR_PRODUCT_INSTRUCTION.instructions",
  MOVEMENTS_AUTHORIZATION = "dailyAdjustmentsModule.movements.authorization",
  VALIDATE_AUTHORIZATION = "dailyAdjustmentsModule.movements.validate_authorization",
  CONFIRM_MESSAGE = "dailyAdjustmentsModule.movements.CONFIRM_MESSAGE",
  AUTHORIZE_CONFIRM_MESSAGE = "dailyAdjustmentsModule.movements.AUTHORIZE_CONFIRM_MESSAGE",
  AUTHORIZE_CONFIRM_MESSAGE_PLURAL = "dailyAdjustmentsModule.movements.AUTHORIZE_CONFIRM_MESSAGE_PLURAL",
  AUTHORIZE_DELETE_TITLE = "dailyAdjustmentsModule.movements.AUTHORIZE_DELETE_TITLE",
  AUTHORIZE_DELETE_MESSAGE = "dailyAdjustmentsModule.movements.AUTHORIZE_DELETE_MESSAGE",
  AUTHORIZE_DELETE_MESSAGE_PLURAL = "dailyAdjustmentsModule.movements.AUTHORIZE_DELETE_MESSAGE_PLURAL",
  AUTHORIZE_VALIDATE_TITLE = "dailyAdjustmentsModule.movements.AUTHORIZE_VALIDATE_TITLE",
  AUTHORIZE_VALIDATE_MESSAGE = "dailyAdjustmentsModule.movements.AUTHORIZE_VALIDATE_MESSAGE",
  AUTHORIZE_VALIDATE_MESSAGE_PLURAL = "dailyAdjustmentsModule.movements.AUTHORIZE_VALIDATE_MESSAGE_PLURAL",
  REPORT_EVENT_TITLE = "dailyAdjustmentsModule.movements.REPORT_EVENT_TITLE",
  SHOW_FINISHED_PRODUCTS = "dailyAdjustmentsModule.movements.SHOW_FINISHED_PRODUCTS",
  PRODUCT_DRAWER_INFO = "dailyAdjustmentsModule.movements.PRODUCT_DRAWER_INFO",
  PRE_AUTHORIZE_ADJUSMENT_BUTTON = "dailyAdjustmentsModule.movements.PRE_AUTHORIZE_ADJUSMENT_BUTTON",
}

export enum theoricalInventoryModule {
  TITLE = "module.theoretical.records.title",
  SUBTITLE = "module.theoretical.records.subtitle",
  NO_ELEMENTS = "module.theoretical.no.elements",
  SUPPLIES_EXCLUDED = "module.theoretical.SUPPLIES_EXCLUDED",
  DEVIATION_ZERO = "module.theoretical.DEVIATION_ZERO",
  DEVIATION_LOW = "module.theoretical.DEVIATION_LOW",
  DEVIATION_HIGH = "module.theoretical.DEVIATION_HIGH",
  REPORT_TITLE = "module.theoretical.REPORT_TITLE",
  REPORT_SUBTITLE = "module.theoretical.REPORT_SUBTITLE",
  REPORT_FILTER = "module.theoretical.REPORT_FILTER",
  ERROR_DATA = "module.theoretical.ERROR_DATA",
  ERROR_EXPORT = "module.theoretical.ERROR_EXPORT",
  THEORETICAL_AMOUNT = "module.theoretical.THEORETICAL_AMOUNT",
  ACTUAL_AMOUNT = "module.theoretical.ACTUAL_AMOUNT",
  DIFFERENCE = "module.theoretical.DIFFERENCE",
  DEVIATION = "module.theoretical.DEVIATION",
  LAST_COUNTING_LABEL = "module.theoretical.LAST_COUNTING_LABEL",
  DETAILS = "DETAILS",
  HIGH_ACCURACY = "HIGH_ACCURACY",
  LOW_ACCURACY = "LOW_ACCURACY",
  MEDIUM_ACCURACY = "MEDIUM_ACCURACY",
  RESULT = "RESULT",
  ACCURACY_RESULT = "ACCURACY_RESULT",
}

export enum kitchenInventoryManagement {
  INVENTORY_MODULE = "module.kitchen.inventory.management.title.inventory",
  INVENTORY_MODULE_DESCRIPTION = "module.kitchen.inventory.management.title.inventory.module.description",
  INVENTORY_ADMIN_MODULE = "module.kitchen.inventory.management.title.inventory.admin",
  INVENTORY_ADMIN_MODULE_DESCRIPTION = "module.kitchen.inventory.management.title.inventory.admin.module.description",
  SUPPLY_ADMIN_MODULE = "module.kitchen.inventory.management.title.supply.admin",
  SUPPLY_ADMIN_MODULE_DESCRIPTION = "module.kitchen.inventory.management.title.supply.admin.module.description",
  KITCHEN_INVENTORY_TITLE = "module.kitchen.inventory.management.kitchen.inventory.title",
  KITCHEN_INVENTORY_DESCRIPTION = "module.kitchen.inventory.management.kitchen.inventory.description",
  INVENTORY_INVENTORY_TITLE = "module.kitchen.inventory.management.inventory.inventory.title",
  INVENTORY_INVENTORY_TITLE_DESCRIPTION = "module.kitchen.inventory.management.inventory.inventory.description",
  RECEPTION_INVENTORY_TITLE = "module.kitchen.inventory.management.reception_inventory_title",
  RECEPTION_INVENTORY_DESCRIPTION = "module.kitchen.inventory.management.reception_inventory_description",
  AUTHORIZATION_RECOUNTING_TITLE = "module.kitchen.inventory.management.AUTHORIZATION_RECOUNTING_TITLE",
  AUTHORIZATION_RECOUNTING_DESCRIPTION = "module.kitchen.inventory.management.AUTHORIZATION_RECOUNTING_DESCRIPTION",
  AUTHORIZATION_INVENTORY_TITLE = "module.kitchen.inventory.management.authorization.title",
  AUTHORIZATION_INVENTORY_DESCRIPTION = "module.kitchen.inventory.management.authorization.description",
  PRODUCT_INVENTORY_TITLE = "module.kitchen.inventory.management.product.title",
  PRODUCT_INVENTORY_DESCRIPTION = "module.kitchen.inventory.management.product.description",
  PRODUCT_INVENTORY_DESCRIPTION_2 = "module.kitchen.inventory.management.product.description.two",
  PRODUCT_INVENTORY_MODULE = "module.kitchen.inventory.management.product.module",
  PRODUCT_INVENTORY_SCHEDULE_PRODUCTIONS = "module.kitchen.inventory.management.product.SCHEDULE",
  PRODUCT_INVENTORY_ACTIVE_PRODUCTIONS = "module.kitchen.inventory.management.product.active",
  PRODUCT_INVENTORY_ENDED_PRODUCTIONS = "module.kitchen.inventory.management.product.ended",
  PRODUCT_INVENTORY_NEW_PRODUCTIONS = "module.kitchen.inventory.management.product.new",
  PRODUCT_INVENTORY_ADD_PRODUCTIONS = "module.kitchen.inventory.management.product.ADD",
  PRODUCT_INVENTORY_NAME_PRODUCTIONS = "module.kitchen.inventory.management.product.name",
  PRODUCT_INVENTORY_MODAL_TITLE = "module.kitchen.inventory.management.product.modal.title",
  PRODUCT_INVENTORY_MODAL_INSTRUCTION = "module.kitchen.inventory.management.product.modal.instruction",
  INVENTORY_INVENTORY_TITLE_CP = "module.kitchen.inventory.management.inventory.title.cp",
  INVENTORY_INVENTORY_TITLE_DESCRIPTION_CP = "module.kitchen.inventory.management.inventory.description.cp",
  TRANSFER_LOAD_FILE_TITLE = "module.kitche.inventory.management.transfer.load.title",
  TRANSFER_LOAD_FILE_DESCRIPTION = "module.kitche.inventory.management.transfer.load.description",
  TRANSFER_PICKING_PACKING_TITLE = "module.kitche.inventory.management.transfer.picking.title",
  TRANSFER_PICKING_PACKING_DESCRIPTION = "module.kitche.inventory.management.transfer.picking.description",
  TRANSFER_AUTHORIZATION_TITLE = "module.kitche.inventory.management.transfer.authorization.title",
  TRANSFER_AUTHORIZATION_DESCRIPTION = "module.kitche.inventory.management.transfer.authorization.description",
  INVENTORYREVIEW_MODULE = "module.kitchen.inventory.management.INVENTORYREVIEW_TITLE",
  INVENTORYREVIEW_MODULE_DESCRIPTION = "module.kitchen.inventory.management.INVENTORYREVIEW_DESCRIPTION",
  COUNTDOWNLOAD_MODULE = "module.kitchen.inventory.management.COUNTDOWNLOAD_TITLE",
  COUNTDOWNLOAD_MODULE_DESCRIPTION = "module.kitchen.inventory.management.COUNTDOWNLOAD_DESCRIPTION",
  SUPPLIESMANAGEMENT_MODULE = "module.kitchen.inventory.management.SUPPLIESMANAGEMENT_TITLE",
  SUPPLIESMANAGEMENT_MODULE_DESCRIPTION = "module.kitchen.inventory.management.SUPPLIESMANAGEMENT_DESCRIPTION",
  MASSIVEADJUSTS_MODULE = "module.kitchen.inventory.management.MASSIVEADJUSTS_TITLE",
  MASSIVEADJUSTS_MODULE_DESCRIPTION = "module.kitchen.inventory.management.MASSIVEADJUSTS_DESCRIPTION",
  THEORETICALINVENTORYCOMPARISON_MODULE = "module.kitchen.inventory.management.THEORETICALINVENTORYCOMPARISON_TITLE",
  THEORETICALINVENTORYCOMPARISON_MODULE_DESCRIPTION = "module.kitchen.inventory.management.THEORETICALINVENTORYCOMPARISON_DESCRIPTION",
  SINGLEADJUSTS_MODULE = "module.kitchen.inventory.management.SINGLEADJUSTS_TITLE",
  SINGLEADJUSTS_MODULE_DESCRIPTION = "module.kitchen.inventory.management.SINGLEADJUSTS_DESCRIPTION",
  DAILYMOVS_MODULE = "module.kitchen.inventory.management.DAILYMOVS_TITLE",
  DAILYMOVS_MODULE_DESCRIPTION = "module.kitchen.inventory.management.DAILYMOVS_DESCRIPTION",
  SELECT_MODULE_TITLE = "module.kitchen.inventory.management.SELECT_MODULE_TITLE",
  SELECT_MODULE_SUBTITLE = "module.kitchen.inventory.management.SELECT_MODULE_SUBTITLE",
  FOR_SUPPLY_INFO = "module.kitchen.inventory.management.FOR_SUPPLY_INFO",
  FOR_KITCHEN_INFO = "module.kitchen.inventory.management.FOR_KITCHEN_INFO",
  PRODUCTION_SUPPLY_RECEPTION_TITLE = "module.kitchen.inventory.management.PRODUCTION_SUPPLY_RECEPTION_TITLE",
  PRODUCTION_SUPPLY_RECEPTION_DESCRIPTION = "module.kitchen.inventory.management.PRODUCTION_SUPPLY_RECEPTION_DESCRIPTION",
  PRODUCTION_LIBERATION_TITLE = "module.kitchen.inventory.management.PRODUCTION_LIBERATION_TITLE",
  PRODUCTION_LIBERATION_DESCRIPTION = "module.kitchen.inventory.management.PRODUCTION_LIBERATION_DESCRIPTION",
  BC_INTEGRATION_MODULE = "module.kitchen.inventory.management.BC_INTEGRATION_MODULE",
  BC_INTEGRATION_MODULE_DESCRIPTION = "module.kitchen.inventory.management.BC_INTEGRATION_MODULE_DESCRIPTION",
}
export enum productionManagement {
  PRODUCT_INSTRUCTION = "module.productions.product.instruction",
  START_PRODUCTION = "module.productions.start.production",
  FINISH_PRODUCTION = "module.productions.finish.production",
  FINISH_PRODUCTION_MODAL = "module.productions.finish.production.modal",
  FINISH_REAL_PERFORMANCE = "module.productions.finish.real.performance",
  PRODUCTION_USER = "module.productions.user",
  PRODUCTION_PACKED_QUANTITY = "module.productions.packed.quantity",
  PRODUCTION_CALCULATOR_TITLE = "productions.calculator.title",
  PRODUCTION_CALCULATOR_DESCRIPTION = "productions.calculator.description",
  PRODUCTION_CALCULATOR_ESTIMATED = "productions.calculator.estimated",
  EDIT_PRODUCTION = "productions.EDIT_PRODUCTION",
  EDIT_PRODUCTION_MODAL_SUCCESS_TITLE = "productions.EDIT_PRODUCTION_MODAL_SUCCESS_TITLE",
  EDIT_PRODUCTION_MODAL_SUCCESS_MESSAGE = "productions.EDIT_PRODUCTION_MODAL_SUCCESS_MESSAGE",
  REPORT_PRODUCTION_ERROR_MESSAGE_HIGH = "productions.REPORT_PRODUCTION_ERROR_MESSAGE_HIGH",
  REPORT_PRODUCTION_ERROR_MESSAGE_LOW = "productions.REPORT_PRODUCTION_ERROR_MESSAGE_LOW",
  REPORT_PRODUCTION_ERROR_VALIDATION_MESSAGE = "productions.REPORT_PRODUCTION_ERROR_VALIDATION_MESSAGE",
  REPORT_PRODUCTION_ERROR_PRODUCTION_VALIDATION_MESSAGE = "productions.REPORT_PRODUCTION_ERROR_PRODUCTION_VALIDATION_MESSAGE",
  DELETE_DIALOG_TITLE = "productions.DELETE_DIALOG_TITLE",
  DELETE_DIALOG_MESSAGE = "productions.DELETE_DIALOG_MESSAGE",
  PACKAGING_COMPLETE = "productions.PACKAGING_COMPLETE",
  PACKAGING_PARTIAL = "productions.PACKAGING_PARTIAL",
  LABELS_HISTORY = "productions.LABELS_HISTORY",
  LABELS_HISTORY_EMPTY = "productions.LABELS_HISTORY_EMPTY",
  PACK_PRODUCTION = "productions.PACK_PRODUCTION",
  SELECT_LABELS_TO_PRINT = "productions.SELECT_LABELS_TO_PRINT",
  REPRINT_LABELS = "productions.REPRINT_LABELS",
  LABELS_OF = "productions.LABELS_OF",
  LABELS_GENERATED = "productions.LABELS_GENERATED",
  LABEL = "productions.LABEL",
  USER_CREATED_LABELS = "productions.USER_CREATED_LABELS",
  SELECT_USER_CREATED_LABELS = "productions.SELECT_USER_CREATED_LABELS",
  LABELS_GENERATED_INFO = "productions.LABELS_GENERATED_INFO",
  TRIGGER = "productions.TRIGGER",
  QUANTITY_TO_PACK = "productions.QUANTITY_TO_PACK",
  QUANTITY_OF_PACKAGING = "productions.QUANTITY_OF_PACKAGING",
  LOG_TITLE = "productions.LOG_TITLE",
  LOG_DESCRIPTION = "productions.LOG_DESCRIPTION",
  LOG_TITLE_INPUT = "productions.LOG_TITLE_INPUT",
  LOG_LABEL_INPUT = "productions.LOG_LABEL_INPUT",
  LOG_TRACK = "productions.LOG_TRACK",
  LOG_EMPTY_LABEL_1 = "productions.LOG_EMPTY_LABEL_1",
  LOG_EMPTY_LABEL_2 = "productions.LOG_EMPTY_LABEL_2",
  LOG_DETECTED_MOVS = "productions.LOG_DETECTED_MOVS",
  LOG_MOV = "productions.LOG_MOV",
  LOG_VALIDITY_LABEL = "productions.LOG_VALIDITY_LABEL",
  LOG_INVALIDITY_LABEL = "productions.LOG_INVALIDITY_LABEL",
  SCHEDULED = "productions.SCHEDULED",
  IN_PROCESS = "productions.IN_PROCESS",
  FINISHED = "productions.FINISHED",
  APPROVED = "productions.APPROVED",
  APPROVED_WITH_NOVELTY = "productions.APPROVED_WITH_NOVELTY",
  PRODUCTION_AND_QUANTITY = "productions.PRODUCTION_AND_QUANTITY",
  CHRONOMETER = "productions.CHRONOMETER",
  RESPONSIBLE = "productions.RESPONSIBLE",
  SCHEDULED_QUANTITY = "productions.SCHEDULED_QUANTITY",
  PRODUCED_QUANTITY = "productions.PRODUCED_QUANTITY",
  STARTED_PRODUCTION = "productions.STARTED_PRODUCTION",
  FINISHED_PRODUCTION = "productions.FINISHED_PRODUCTION",
  PRODUCING_FOR = "productions.PRODUCING_FOR",
  PRODUCTION_TIME = "productions.PRODUCTION_TIME",
  PRODUCTION_USER_ASSIGNED_REQUIRED = "production.USER_ASSIGNED_REQUIRED",
  PRODUCTION_SCHEDULED_DATE_REQUIRED = "production.SCHEDULED_DATE_REQUIRED",
  PRODUCTION_QUANTITY_REQUIRED = "production.QUANTITY_REQUIRED",
  PRODUCTION_QUANTITY_TYPE_ERROR = "production.QUANTITY_TYPE_ERROR",
  PRODUCTION_SKU_REQUIRED = "production.SKU_REQUIRED",
  DOWNLOAD_EXAMPLE_TEMPLATE = "production.DOWNLOAD_EXAMPLE_TEMPLATE",
  INVALID_DOCUMENT_TITLES = "production.INVALID_DOCUMENT_TITLES",
  INVALID_DOCUMENT_DATA = "production.INVALID_DOCUMENT_DATA",
  DRAG_AND_DROP_FILE = "production.DRAG_AND_DROP_FILE",
  FILL_IN_THE_FORM = "production.FILL_IN_THE_FORM",
  ADD_PRODUCTION_TITLE = "production.ADD_PRODUCTION_TITLE",
  EDIT_PRODUCTION_TITLE = "production.EDIT_PRODUCTION_TITLE",
  VALIDATE_ADDED_DATA = "production.VALIDATE_ADDED_DATA",
  PRODUCTION_NAME = "production.PRODUCTION_NAME",
  QUANTITY = "production.QUANTITY",
  SCHEDULED_DATE = "production.SCHEDULED_DATE",
  TIME_SLOT = "production.TIME_SLOT",
  USER_ASSIGNED = "production.USER_ASSIGNED",
  PRINT_INGREDIENTS = "production.PRINT_INGREDIENTS",
  GENERATED_QUANTITY = "production.GENERATED_QUANTITY",
  PPRODUCTION_ORDER = "production.PPRODUCTION_ORDER",
  VALIDATION_REQUIRED_INFO = "production.VALIDATION_REQUIRED_INFO",
  SELECT_STATION = "production.SELECT_STATION",
}

export enum transferModule {
  ERROR_SKU = "transferModule.error.sku",
  ERROR_MEASURE_UNIT = "transferModule.error.measureUnit",
  ERROR_LINE = "transferModule.error.line",
  DELETE_TRANSFER = "transferModule.delete",
  DELETE_TRANSFER_CONFIRM = "transferModule.delete.confirm",
  USER_CREATE = "transferModule.user.create",
  USER_RECIBE = "transferModule.user.recibe",
  DESTINATION = "transferModule.destination",
  RECEIPT_NO = "transferModule.receipt.no",
  ORDER_NO = "transferModule.order.no",
  RECEIVED_AT = "transferModule.received.at",
  RECEIVED_BY = "transferModule.received.by",
  STATUS = "transferModule.state",
  CREATED_AT = "transferModule.created.at",
  NOT_PENDIG = "transferModule.not.pending",
  ERROR_SINGLE_TRANSFER = "transferModule.error.same.origin",
  TITLE_ERROR_PENDING_TRANSFER = "transferModule.error.pending.transfer",
  DESCRIPCTION_ERROR_PENDING_TRANSFER = "transferModule.error.pending.transfer.description",
  STORAGE = "transferModule.STORAGE",
  EDIT_TRANSFER = "transferModule.EDIT_TRANSFER",
  PREPARE_TRANSFER = "transferModule.PREPARE_TRANSFER",
  ENLISTED = "transferModule.ENLISTED",
  PENDING = "transferModule.PENDING",
  UNSPECIFIED = "transferModule.UNSPECIFIED",
  CONFIRM = "transferModule.CONFIRM",
  SUCCESS = "transferModule.SUCCESS",
  DELETE_LABELS = "transferModule.DELETE_LABELS",
  SCHEDULE = "transferModule.SCHEDULE",
  MASSIVE = "transferModule.MASSIVE",
  INDIVIDUAL = "transferModule.INDIVIDUAL",
  TO_SEND = "transferModule.TO_SEND",
  MADE = "transferModule.MADE",
  RETURN_MESSAGE = "transferModule.RETURN_MESSAGE",
  RECEIPT_CONFIRM_MESSAGE = "transferModule.RECEIPT_CONFIRM_MESSAGE",
  RECEIPT_CONFIRM_TITLE = "transferModule.RECEIPT_CONFIRM_TITLE",
  RECEIPT_MESSAGE = "transferModule.RECEIPT_MESSAGE",
  RECEIPT_TITLE = "transferModule.RECEIPT_TITLE",
  EXTRA = "transferModule.type.EXTRA",
  TRANSFER = "transferModule.type.TRANSFER",
  RETURN = "transferModule.type.RETURN",
  HAVE_EXTRA_OR_LEFTOVER_QUESTION = "transferModule.HAVE_EXTRA_OR_LEFTOVER_QUESTION",
  HAVE_EXTRA_OR_LEFTOVER_COMMENT = "transferModule.HAVE_EXTRA_OR_LEFTOVER_COMMENT",
  NUMBER_OF_BASKETS = "transferModule.NUMBER_OF_BASKETS",
  NUMBER_OF_BASKETS_QUESTION = "transferModule.NUMBER_OF_BASKETS_QUESTION",
  SUCCESS_AUTHORIZE_TITLE = "transferModule.SUCCESS_AUTHORIZE_TITLE",
  SUCCESS_AUTHORIZE_DESCRIPTION = "transferModule.SUCCESS_AUTHORIZE_DESCRIPTION",
  ERROR_AUTHORIZE_TITLE = "transferModule.ERROR_AUTHORIZE_TITLE",
  RECEIPT = "transferModule.RECEIPT",
  SCHEDULE_SUCCESSFULLY = "transferModule.SCHEDULE_SUCCESSFULLY",
  SKU = "transferModule.SKU",
  DESCRIPTION = "transferModule.DESCRIPTION",
  QUANTITY = "transferModule.QUANTITY",
  UNIT = "transferModule.UNIT",
}

export enum transferPackingDialog {
  SUBTITLE = "transferPackingDialog.SUBTITLE",
  SUBTITLE_FOR_KITCHEN = "transferPackingDialog.SUBTITLE_FOR_KITCHEN",
  SUBTITLE_DELETE_LABELS = "transferPackingDialog.SUBTITLE_DELETE_LABELS",
  RESUME = "transferPackingDialog.RESUME",
  SUPPLY = "transferPackingDialog.SUPPLY",
  TOTAL_QUANTITY = "transferPackingDialog.TOTAL_QUANTITY",
  CONTINUE = "transferPackingDialog.CONTINUE",
  CANCEL = "transferPackingDialog.CANCEL",
  REQUESTED_QUANTITY = "transferPackingDialog.REQUESTED_QUANTITY",
  SERVICE_LEVEL = "transferPackingDialog.SERVICE_LEVEL",
  QUANTITY_SENT = "transferPackingDialog.QUANTITY_SENT",
  CONFIRMED_TRANSFERS_TITLE = "transferPackingDialog.CONFIRMED_TRANSFERS_TITLE",
  CONFIRMED_TRANSFERS_MESSAGE = "transferPackingDialog.CONFIRMED_TRANSFERS_MESSAGE",
}

export enum transferOrdersPackedReviewTable {
  SKU = "transferOrdersPackedReviewTable.SKU",
  NAME = "transferOrdersPackedReviewTable.NAME",
  UNIT = "transferOrdersPackedReviewTable.UNIT",
  ESTIMATED_QUANTITY = "transferOrdersPackedReviewTable.ESTIMATED_QUANTITY",
  PACKED_QUANTITY = "transferOrdersPackedReviewTable.PACKED_QUANTITY",
  QUANTITY_TO_SEND = "transferOrdersPackedReviewTable.QUANTITY_TO_SEND",
  SERVICE_LEVEL = "transferOrdersPackedReviewTable.SERVICE_LEVEL",
  STATUS = "transferOrdersPackedReviewTable.STATUS",
  COMPLETE = "transferOrdersPackedReviewTable.COMPLETE",
  PARTIAL = "transferOrdersPackedReviewTable.PARTIAL",
  NO_SENT = "transferOrdersPackedReviewTable.NO_SENT",
  ALL_TITLE = "transferOrdersPackedReviewTable.ALL_TITLE",
  COMPLETE_TITLE = "transferOrdersPackedReviewTable.COMPLETE_TITLE",
  PARTIAL_TITLE = "transferOrdersPackedReviewTable.PARTIAL_TITLE",
  NO_SENT_TITLE = "transferOrdersPackedReviewTable.NO_SENT_TITLE",
}

export enum countingTypeSelector {
  LABEL = "countingTypeSelector.LABEL",
}

export enum physicalCountSchedule {
  HEADER_PAGE_TITLE = "physicalCountSchedule.HEADER_PAGE_TITLE",
  HEADER_PAGE_SUBTITLE = "physicalCountSchedule.HEADER_PAGE_SUBTITLE",
  STEP_LABEL_1 = "physicalCountSchedule.STEP_LABEL_1",
  STEP_LABEL_2 = "physicalCountSchedule.STEP_LABEL_2",
  IMPORT = "physicalCountSchedule.IMPORT",
  STEP_LABEL_3 = "physicalCountSchedule.STEP_LABEL_3",
  STEP_LABEL_4 = "physicalCountSchedule.STEP_LABEL_4",
  STEP_LABEL_FINAL = "physicalCountSchedule.STEP_LABEL_FINAL",
  PREVIOUS = "physicalCountSchedule.PREVIOUS",
  CONTINUE = "physicalCountSchedule.CONTINUE",
  FINISH = "physicalCountSchedule.FINISH",
  ADD_LOCATION = "physicalCountSchedule.ADD_LOCATION",
  ACTIONS = "physicalCountSchedule.ACTIONS",
  STEP_2_TABLE_EMPTY = "physicalCountSchedule.STEP_2_TABLE_EMPTY",
  SINGLE_DATE = "physicalCountSchedule.SINGLE_DATE",
  DATE_RANGE = "physicalCountSchedule.DATE_RANGE",
  COUNT_DATE = "physicalCountSchedule.COUNT_DATE",
  START_DATE = "physicalCountSchedule.START_DATE",
  END_DATE = "physicalCountSchedule.END_DATE",
  SCHEDULE_DATE = "physicalCountSchedule.SCHEDULE_DATE",
  SCHEDULE_DATES = "physicalCountSchedule.SCHEDULE_DATES",
  DATE = "physicalCountSchedule.DATE",
  STEP_3_TABLE_EMPTY = "physicalCountSchedule.STEP_3_TABLE_EMPTY",
  STEP_4_TABLE_EMPTY = "physicalCountSchedule.STEP_4_TABLE_EMPTY",
  STEPS_COMPLETED = "physicalCountSchedule.STEPS_COMPLETED",
  STEPS_RESET = "physicalCountSchedule.STEPS_RESET",
  STEP_1_INFO = "physicalCountSchedule.STEP_1_INFO",
  STEP_2_INFO = "physicalCountSchedule.STEP_2_INFO",
  STEP_IMPORT_INFO = "physicalCountSchedule.STEP_IMPORT_INFO",
  STEP_3_INFO = "physicalCountSchedule.STEP_3_INFO",
  STEP_4_INFO = "physicalCountSchedule.STEP_4_INFO",
  STEP_FINAL_INFO = "physicalCountSchedule.STEP_FINAL_INFO",
  SUCCESS_TITLE = "physicalCountSchedule.SUCCESS_TITLE",
  SUCCESS_SUBTITLE = "physicalCountSchedule.SUCCESS_SUBTITLE",
  ERROR_TITLE = "physicalCountSchedule.ERROR_TITLE",
  ERROR_SUBTITLE = "physicalCountSchedule.ERROR_SUBTITLE",
  ARCHIVE_ERROR = "physicalCountSchedule.ARCHIVE_ERROR",
  ARCHIVE_SUCCESS = "physicalCountSchedule.ARCHIVE_SUCCESS",
}

export enum productionPreparation {
  INSTRUCTIONS_FOR_PREPARATION = "productionPreparation.INSTRUCTIONS_FOR_PREPARATION",
  NO_PREPARATION_INSTRUCTIONS_FOUND = "productionPreparation.NO_PREPARATION_INSTRUCTIONS_FOUND",
  PRINT_PREAPARATION = "productionPreparation.PRINT_PREAPARATION",
}

export enum purchaseOrderLine {
  TITLE = "purchaseOrderLine.TITLE",
  DELETE_RECEIPT = "purchaseOrderLine.DELETE_RECEIPT",
  DIALOG_CONFIRM_TITLE = "purchaseOrderLine.DIALOG_CONFIRM_TITLE",
  DIALOG_CONFIRM_MESSAGE = "purchaseOrderLine.DIALOG_CONFIRM_MESSAGE",
  DIALOG_SUCCESS_TITLE = "purchaseOrderLine.DIALOG_SUCCESS_TITLE",
  DIALOG_SUCCESS_MESSAGE = "purchaseOrderLine.DIALOG_SUCCESS_MESSAGE",
  DIALOG_ERROR_TITLE = "purchaseOrderLine.DIALOG_ERROR_TITLE",
  DIALOG_ERROR_MESSAGE = "purchaseOrderLine.DIALOG_ERROR_MESSAGE",
  FIND_PRODUCT_TITLE = "purchaseOrderLine.FIND_PRODUCT_TITLE",
  FIND_PRODUCT_LABEL = "purchaseOrderLine.FIND_PRODUCT_LABEL",
  RECEIPT_PENDING = "purchaseOrderLine.RECEIPT_PENDING",
  RECEIPT_COMPLETE = "purchaseOrderLine.RECEIPT_COMPLETE",
  UPDATE_RECEIVED_QUANTITY_ERROR = "purchaseOrderLine.UPDATE_RECEIVED_QUANTITY_ERROR",
}

export enum purchaseOrderCreation {
  TITLE = "purchaseOrderCreation.TITLE",
  DESCRIPTION = "purchaseOrderCreation.DESCRIPTION",
  CREATE_TITLE = "purchaseOrderCreation.CREATE_TITLE",
  CREATE_MASSIVE_BUTTON = "purchaseOrderCreation.CREATE_MASSIVE_BUTTON",
  CREATE_INDIVIDUAL_BUTTON = "purchaseOrderCreation.CREATE_INDIVIDUAL_BUTTON",
  CONFIRM_BUTTON = "purchaseOrderCreation.COFNFIRM_BUTTON",
  DETECTED_ORDERS_TITLE = "purchaseOrderCreation.DETECTED_ORDERS_TITLE",
  NO_ORDENES_DETECTED = "purchaseOrderCreation.NO_ORDENES_DETECTED",
  NO_ORDERS_FOR_SEARCH = "purchaseOrderCreation.NO_ORDERS_FOR_SEARCH",
  CREATE_MASSIVE_MODAL_TITLE = "purchaseOrderCreation.CREATE_MASSIVE_MODAL_TITLE",
  CONFIRM_MULTIPLE_MODAL_TITLE = "purchaseOrderCreation.CONFIRM_MULTIPLE_MODAL_TITLE",
  CONFIRM_MULTIPLE_MODAL_SUBTITLE = "purchaseOrderCreation.CONFIRM_MULTIPLE_MODAL_SUBTITLE",
  ESTIMATED_DELIVERY_DATE_LABEL = "purchaseOrderCreation.ESTIMATED_DELIVERY_DATE_LABLE",
  VALIDATE_ITEM_MESSAGE = "purchaseOrderCreation.VALIDATE_ITEM_MESSAGE",
  CONFIRM_AND_SEND_BUTTON = "purchaseOrderCreation.CONFIRM_SINGLE_BUTTON",
  DELETE_ORDER = "purchaseOrderCreation.DELETE_ORDER",
  UPDATE_ORDER_MODAL_TITLE = "purchaseOrderCreation.UPDATE_ORDER_MODAL_TITLE",
  UPDATE_ORDER_MODAL_INFO = "purchaseOrderCreation.UPDATE_ORDER_MODAL_INFO",
  UPDATE_ORDER_MODAL_BUTTON = "purchaseOrderCreation.UPDATE_ORDER_MODAL_BUTTON",
  ESTIMATED_DATE_LABEL = "purchaseOrderCreation.ESTIMATED_DATE_LABEL",
  INITIAL_ESTIMATED_DATE_LABEL = "purchaseOrderCreation.INITIAL_ESTIMATED_DATE_LABEL",
  RECEIPT_DATE_LABEL = "purchaseOrderCreation.RECEIPT_DATE_LABEL",
  DELETE_LINE_MODAL_TITLE = "purchaseOrderCreation.DELETE_LINE_MODAL_TITLE",
  DELETE_PURCHASE_ORDER_TITLE = "purchaseOrderCreation.DELETE_PURCHASE_ORDER_TITLE",
  DELETE_PURCHASE_ORDER_MESSAGE = "purchaseOrderCreation.DELETE_PURCHASE_ORDER_MESSAGE",
  DELETE_LINE_MODAL_SUBTITLE = "purchaseOrderCreation.DELETE_LINE_MODAL_SUBTITLE",
  ADD_LINE_MODAL_TITLE = "purchaseOrderCreation.ADD_LINE_MODAL_TITLE",
  ADD_LINE_MODAL_INFO = "purchaseOrderCreation.ADD_LINE_MODAL_INFO",
  ADD_LINE_MODAL_BUTTON = "purchaseOrderCreation.ADD_LINE_MODAL_BUTTON",
  SEND_ORDER_MODAL_TITLE = "purchaseOrderCreation.SEND_ORDER_MODAL_TITLE",
  SEND_ORDER_MODAL_SUBTITLE = "purchaseOrderCreation.SEND_ORDER_MODAL_SUBTITLE",
  SEND_ORDER_MODAL_INFO = "purchaseOrderCreation.SEND_ORDER_MODAL_INFO",
  SEND_LOADING_MODAL = "purchaseOrderCreation.SEND_LOADING_MODAL",
  SEND_ORDER_MODAL_BUTTON = "purchaseOrderCreation.SEND_ORDER_MODAL_BUTTON",
  ORDER_CONFIRMED = "purchaseOrderCreation.ORDER_CONFIRMED",
  ORDERD_CONFIRMED_OK = "purchaseOrderCreation.ORDERD_CONFIRMED_OK",
  CREATE_ORDER_MODAL_TITLE = "purchaseOrderCreation.CREATE_ORDER_MODAL_TITLE",
  CREATE_ORDER_MODAL_INFO = "purchaseOrderCreation.CREATE_ORDER_MODAL_INFO",
  CREATE_ORDER_MODAL_BUTTON = "purchaseOrderCreation.CREATE_ORDER_MODAL_BUTTON",
  CREATE_ORDER_MODAL_LOCATION_LABEL = "purchaseOrderCreation.CREATE_ORDER_MODAL_LOCATION_LABEL",
  CREATE_ORDER_MODAL_VENDOR_LABEL = "purchaseOrderCreation.CREATE_ORDER_MODAL_VENDOR_LABEL",
  CREATE_ORDER_MODAL_INVOICE_LABEL = "purchaseOrderCreation.CREATE_ORDER_MODAL_INVOICE_LABEL",
  DOCUMENT_LIST_BUTTON_LABEL = "purchaseOrderCreation.DOCUMENT_LIST_BUTTON_LABEL",
  RESULT_DIALOG_TITLE = "purchaseOrderCreation.RESULT_DIALOG_TITLE",
  RESULT_DIALOG_ERROR_VENDOR = "purchaseOrderCreation.RESULT_DIALOG_ERROR_VENDOR",
  RESULT_DIALOG_ERROR_SKU = "purchaseOrderCreation.RESULT_DIALOG_ERROR_SKU",
  RESULT_NO_ERRORS = "purchaseOrderCreation.RESULT_NO_ERRORS",
}

export enum productionDialog {
  WEIGHT = "production.dialog.technical.wight",
  TECHNICAL_NAME = "production.dialog.technical.technocal_name",
  NAME = "production.dialog.technical.name",
  PRODUCTION_DATE = "production.dialog.technical.PRODUCTION_DATE",
  EXPIRATION_DATE = "production.dialog.technical.EXPIRATION_DATE",
  CONSERVATION = "production.dialog.technical.CONSERVATION",
  MAXIMUM_DURATION = "production.dialog.technical.MAXIMUM_DURATION",
  RESPONSIBLE = "production.dialog.technical.RESPONSIBLE",
  MANUFACTURER = "production.dialog.technical.MANUFACTURER",
  ADDRESS = "production.dialog.technical.ADDRESS",
  WEEK = "production.dialog.technical.week",
  DAYS = "production.dialog.technical.days",
  BATCH = "production.dialog.technical.BATCH",
}

export enum errorCode {
  TRANSFER_WITHOUT_STOCK = "TRANSFER_WITHOUT_STOCK",
  PARSING_EXCEPTION = "error.PARSING_EXCEPTION",
  VENDOR_NOT_FOUND = "error.VENDOR_NOT_FOUND",
}

export enum massiveTransfer {
  SELECT_MOVE_FILE = "massiveTransfer.SELECT_MOVE_FILE",
  REVIEW_PENDING_TRANSFER_ALERT_BUTTON = "massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_BUTTON",
  REVIEW_PENDING_TRANSFER_ALERT_TITLE = "massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_TITLE",
  REVIEW_PENDING_TRANSFER_ALERT_SUBTITLE = "massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_SUBTITLE",
  LOAD_TRANSFER_SUCCESS_TITLE = "massiveTransfer.LOAD_TRANSFER_SUCCESS_TITLE",
  LOAD_TRANSFER_SUCCESS_MESSAGE = "massiveTransfer.LOAD_TRANSFER_SUCCESS_MESSAGE",
}

export enum dailyAdjustmentsReport {
  FILTERS_LABEL = "dailyAdjustmentsReport.FILTERS_LABEL",
  SEND_TO_VALIDATION_MESSAGE = "dailyAdjustmentsReport.SEND_TO_VALIDATION_MESSAGE",
  APPROVED = "dailyAdjustmentsReport.APPROVED",
  VALIDATED = "dailyAdjustmentsReport.VALIDATED",
}

export enum productionScannedError {
  LABEL_NOT_FOUND = "LABEL_NOT_FOUND",
  ALREADY_SCANNED = "ALREADY_SCANNED",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  WITHOUT_HISTORIAL = "WITHOUT_HISTORIAL",
  CAN_NOT_DELETE = "CAN_NOT_DELETE",
  SELECT_THE_KITCHEN = "SELECT_THE_KITCHEN",
  SELECT_THE_COUNTING = "SELECT_THE_COUNTING",
  SELECT_ORDER_AND_KITCHEN = "SELECT_ORDER_AND_KITCHEN",
}

export enum productionLogReason {
  CREATED = "productionLogReason.CREATED",
  COUNTING = "productionLogReason.COUNTING",
  TRANSFER_RECEIPT = "productionLogReason.TRANSFER_RECEIPT",
  TRANSFER_PACKING = "productionLogReason.TRANSFER_PACKING",
}

export enum transferOrderModule {
  TITLE = "transferOrderModule.TITLE",
  DESCRIPTION = "transferOrderModule.DESCRIPTION",
  FILTER_ORDERS = "transferOrderModule.FILTER_ORDER",
  FIND_ORDERS = "transferOrderModule.FIND_ORDERS",
  FIND_ORDER_LABEL = "transferOrderModule.FIND_ORDER_LABEL",
  TRANSFER_ID = "transferOrderModule.TABLE_ORDER_ID",
  DESTINATION_NAME = "transferOrderModule.TABLE_DESTINATION_NAME",
  ALL = "transferOrderModule.ALL",
  RELEASED = "transferOrderModule.RELEASED",
  IN_PROCESS = "transferOrderModule.IN_PROCESS",
  PACKED = "transferOrderModule.PACKED",
  IN_TRANSIT = "transferOrderModule.IN_TRANSIT",
  RECEIVED = "transferOrderModule.RECEIVED",
}

export enum transferOrderDetails {
  TITLE = "transferOrderDetails.TITLE",
  FIND_SUPPLY = "transferOrderDetails.FIND_SUPPLY",
  FIND_SUPPLY_LABEL = "transferOrderDetails.FIND_SUPPLY_LABEL",
}

export enum takePhotoLocale {
  RETRY = "take.photo.retry",
  CONTINUE = "take.photo.continue",
  TAKE_PHOTO = "take.photo.take",
  TAKE_PHOTO_AGAIN = "take.photo.take_again",
  BAG_PRODUCTS = "take.photo.bag_products",
  TITLE = "take.photo.TITLE",
}

export enum purchaseOrderConciliation {
  TITLE = "purchaseOrderConciliation.TITLE",
  DESCRIPTION = "purchaseOrderConciliation.DESCRIPTION",
}

export enum productionSchedule {
  EMPTY_TITLE = "productionSchedule.EMPTY_TITLE",
  EMPTY_DESCRIPTION = "productionSchedule.EMPTY_DESCRIPTION",
  DELETE_TITLE = "productionSchedule.DELETE_TITLE",
  DELETE_MESSAGE = "productionSchedule.DELETE_MESSAGE",
  MODIFY_MESSAGE = "productionSchedule.MODIFY_MESSAGE",
  TITLE_FINISHED = "productionSchedule.TITLE_FINISHED",
  TITLE_IN_PROCESS = "productionSchedule.TITLE_IN_PROCESS",
  TITLE_SCHEDULED = "productionSchedule.TITLE_SCHEDULED",
}

export enum supplyingManagement {
  MODULE_TITLE = "supplyingManagement.MODULE_TITLE",
  MODULE_DESCRIPTION = "supplyingManagement.MODULE_DESCRIPTION",
  RESUME = "supplyingManagement.RESUME",
  TOTAL = "supplyingManagement.TOTAL",
  PARTIAL = "supplyingManagement.PARTIAL",
  COMPLETED = "supplyingManagement.COMPLETED",
  SEARCH = "supplyingManagement.SEARCH",
  SEARCH_LABEL = "supplyingManagement.SEARCH_LABEL",
  COMPLETE = "supplyingManagement.COMPLETE",
  PENDING = "supplyingManagement.PENDING",
  CP_NAME = "supplyingManagement.CP_NAME",
  SUPPLY_VIEW = "supplyingManagement.SUPPLY_VIEW",
  SLOT_VIEW = "supplyingManagement.SLOT_VIEW",
  ALL_SLOTS = "supplyingManagement.ALL_SLOTS",
  ALL_ITEMS = "supplyingManagement.ALL_ITEMS",
  ADD_EXTRA_SUPPLY = "supplyingManagement.ADD_EXTRA_SUPPLY",
  ADD_EXTRA_SUPPLY_INFO = "supplyingManagement.ADD_EXTRA_SUPPLY_INFO",
  OVER_QUANTITY = "supplyingManagement.OVER_QUANTITY",
  SUGGESTED_QUANTITY = "supplyingManagement.SUGGESTED_QUANTITY",
}

export enum supplyReception {
  TITLE = "supplyReception.TITLE",
  RECEIVE_SUPPLIES_TAB = "supplyReception.RECEIVE_SUPPLIES_TAB",
  REQUEST_SUPPLIES_TAB = "supplyReception.REQUEST_SUPPLIES_TAB",
  REQUISITION_NUMBER_LABEL = "supplyReception.REQUISITION_NUMBER_LABEL",
  TOTAL_REQUISITION_LABEL = "supplyReception.TOTAL_REQUISITION_LABEL",
  PACKED_REQUISITION_LABEL = "supplyReception.PACKED_REQUISITION_LABEL",
  COMPLETED_REQUISITION_LABEL = "supplyReception.COMPLETED_REQUISITION_LABEL",
  LOCATION_NAME_LABEL = "supplyReception.LOCATION_NAME_LABEL",
  DATE_LABEL = "supplyReception.DATE_LABEL",
  PACKED = "supplyReception.PACKED",
  RECEIVED_QUANTITY = "supplyReception.RECEIVED_QUANTITY",
  REQUESTED_QUANTITY = "supplyReception.REQUESTED_QUANTITY",
  SENT_QUANTITY = "supplyReception.SENT_QUANTITY",
  NOVELTY = "supplyReception.NOVELTY",
  CONFIRM_SUPPLY = "supplyReception.CONFIRM_SUPPLY",
  NOVELTY_MODAL_TITLE = "supplyReception.NOVELTY_MODAL_TITLE",
  NOVELTY_MODAL_INFO = "supplyReception.NOVELTY_MODAL_INFO",
  MISSING_REASON_LABEL = "supplyReception.MISSING_REASON_LABEL",
  CONFIRM_SUPPLY_MODAL_TITLE = "supplyReception.CONFIRM_SUPPLY_MODAL_TITLE",
  CONFIRM_SUPPLY_MODAL_SUBTITLE = "supplyReception.CONFIRM_SUPPLY_MODAL_SUBTITLE",
  ITEMS = "supplyReception.ITEMS",
  MISSING_PRODUCTS = "supplyReception.MISSING_PRODUCTS",
  PARTIAL_PRODUCTS = "supplyReception.PARTIAL_PRODUCTS",
  SPARE_PRODUCTS = "supplyReception.SPARE_PRODUCTS",
  COMPLETE_PRODUCTS = "supplyReception.COMPLETE_PRODUCTS",
  NO_SUPPLIES = "supplyReception.NO_SUPPLIES",
  NO_SUPPLIES_INFO = "supplyReception.NO_SUPPLIES_INFO",
  CONFIRM_SUPPLY_CREATION_INFO = "supplyReception.CONFIRM_SUPPLY_CREATION_INFO",
}

export enum missingReason {
  MISSING_PRODUCT = "missingReason.MISSING_PRODUCT",
  PRODUCT_EXPIRED = "missingReason.PRODUCT_EXPIRED",
  DAMAGED_PRODUCT = "missingReason.DAMAGED_PRODUCT",
  SPARE_PRODUCT = "missingReason.SPARE_PRODUCT",
}

export enum productionLiberation {
  TITLE = "productionLiberation.TITLE",
  FINISH_DATE = "productionLiberation.FINISH_DATE",
  LIBERATE_ACTION_BUTTON = "productionLiberation.LIBERATE_ACTION_BUTTON",
  RESULT_APPROVEMENT_LABEL = "productionLiberation.RESULT_APPROVEMENT_LABEL",
  APPROVED = "productionLiberation.APPROVED",
  APPROVED_WITH_NOVELTY = "productionLiberation.APPROVED_WITH_NOVELTY",
  REJECTED = "productionLiberation.REJECTED",
  NOVELTY_LABEL = "productionLiberation.NOVELTY_LABEL",
  EVALUATE_BUTTON = "productionLiberation.EVALUATE_BUTTON",
  ORGANOLEPTIC_MODAL_TITLE = "productionLiberation.ORGANOLEPTIC_MODAL_TITLE",
  ORGANOLEPTIC_MODAL_SUBTITLE = "productionLiberation.ORGANOLEPTIC_MODAL_SUBTITLE",
  ORGANOLEPTIC_MODAL_SUBTITLE_2 = "productionLiberation.ORGANOLEPTIC_MODAL_SUBTITLE_2",
  PRODUCTION_ORGANOLEPTIC_ERROR = "productionLiberation.PRODUCTION_ORGANOLEPTIC_ERROR",
}

export enum organoleptics {
  APPEARENCE = "organoleptics.appearance",
  COLOR = "organoleptics.color",
  SMELL = "organoleptics.smell",
  TASTE = "organoleptics.taste",
  TEXTURE = "organoleptics.texture",
  VISCOSITY = "organoleptics.viscosity",
}

export enum bcIntegrations {
  INVENTORY_ADJUSTMENTS = "bcIntegrations.INVENTORY_ADJUSTMENTS",
  BATCHES = "bcIntegrations.BATCHES",
  PURCHASE_ORDERS = "bcIntegrations.PURCHASE_ORDERS",
  PURCHASE_ORDERS_RECEIPT = "bcIntegrations.PURCHASE_ORDERS_RECEIPT",
  TRANSFER = "bcIntegrations.TRANSFER",
  TRANSFER_RECEIPT = "bcIntegrations.TRANSFER_RECEIPT",
  SALES = "bcIntegrations.SALES",
  CONCILIATION = "bcIntegrations.CONCILIATION",
  PURCHASE_ORDER_MODIFY = "bcIntegrations.PURCHASE_ORDER_MODIFY",
  VENDORS = "bcIntegrations.VENDORS",
  PRODUCTS = "bcIntegrations.PRODUCTS",
  ARCHIVE_DIALOG_MESSAGE = "bcIntegrations.ARCHIVE_DIALOG_MESSAGE",
}

export enum vendor {
  VENDOR_SUPPLY_MODULE_TITLE = "vendor.VENDOR_SUPPLY_MODULE_TITLE",
  VENDOR_SUPPLY_MODULE_DESCRIPTION = "vendor.VENDOR_SUPPLY_MODULE_DESCRIPTION",
  PAYMENT_DAYS = "vendor.PAYMENT_DAYS",
  PAYMENT_METHOD = "vendor.PAYMENT_METHOD",
  DOCUMENT_TYPE = "vendor.DOCUMENT_TYPE",
  SUPPLIES_QUANTITY = "vendor.SUPPLIES_QUANTITY",
  VENDORS_QUANTITY = "vendor.VENDORS_QUANTITY",
  SHOW_SUPPLIES = "vendor.SHOW_SUPPLIES",
  SHOW_VENDORS = "vendor.SHOW_VENDORS",
  VENDOR_SUPPLIES_TITLE = "vendor.VENDOR_SUPPLIES_TITLE",
  SUPPLY_VENDORS_TITLE = "vendor.SUPPLY_VENDORS_TITLE",
  SUPPLY_NAME = "vendor.SUPPLY_NAME",
  VENDOR_SUPPLY_NAME = "vendor.VENDOR_SUPPLY_NAME",
  VENDOR_UNIT = "vendor.VENDOR_UNIT",
  SUPPLY_QUANTITY = "vendor.SUPPLY_QUANTITY",
  SUPPLY_UNIT = "vendor.SUPPLY_UNIT",
  VENDOR_UNIT_PRICE = "vendor.VENDOR_UNIT_PRICE",
  SUPPLY_UNIT_PRICE = "vendor.SUPPLY_UNIT_PRICE",
  VENDOR_MIN_ORDER_QUANTITY = "vendor.VENDOR_MIN_ORDER_QUANTITY",
  VENDOR_ORDER_PER_WEEK = "vendor.VENDOR_ORDER_PER_WEEK",
  CATEGORY = "vendor.CATEGORY",
  VIEW_BY_VENDORS = "vendor.VIEW_BY_VENDORS",
  VIEW_BY_SUPPLIES = "vendor.VIEW_BY_SUPPLIES",
  LEAD_TIME = "vendor.LEAD_TIME",
  SUPPLY_VALIDATOR_LABEL_LONG = "vendor.SUPPLY_VALIDATOR_LABEL_LONG",
  SUPPLY_VALIDATOR_LABEL_SHORT = "vendor.SUPPLY_VALIDATOR_LABEL_SHORT",
  SUPPLY_VALIDATOR_LABEL_SHORT_2 = "vendor.SUPPLY_VALIDATOR_LABEL_SHORT_2",
  SUPPLY_VALIDATOR_CHIP_LABEL = "vendor.SUPPLY_VALIDATOR_CHIP_LABEL",
  PURCHASE_EMAIL = "vendor.PURCHASE_EMAIL",
  ACCOUNTING_EMAIL = "vendor.ACCOUNTING_EMAIL",
}

export enum vendorMeasureUnit {
  KG = "vendorMeasureUnit.KG",
  PZ = "vendorMeasureUnit.PZ",
  BOX = "vendorMeasureUnit.BOX",
  BAG = "vendorMeasureUnit.BAG",
  BUCKET = "vendorMeasureUnit.BUCKET",
  CAN = "vendorMeasureUnit.CAN",
  GALLON = "vendorMeasureUnit.GALLON",
  PACKAGE = "vendorMeasureUnit.PACKAGE",
  DRUM = "vendorMeasureUnit.DRUM",
  TIN = "vendorMeasureUnit.TIN",
  ML = "vendorMeasureUnit.ML",
  LITER = "vendorMeasureUnit.LITER",
  BAG_SMALL = "vendorMeasureUnit.BAG_SMALL",
  UN = "vendorMeasureUnit.UN",
  BED = "vendorMeasureUnit.BED",
  THOUSAND = "vendorMeasureUnit.THOUSAND",
  G = "vendorMeasureUnit.G",
  ROLL = "vendorMeasureUnit.ROLL",
  JUG = "vendorMeasureUnit.JUG",
  JAR = "vendorMeasureUnit.JAR",
  SACK = "vendorMeasureUnit.SACK",
  BOTTLE = "vendorMeasureUnit.BOTTLE",
  CRATE = "vendorMeasureUnit.CRATE",
  DOME = "vendorMeasureUnit.DOME",
  COVER = "vendorMeasureUnit.COVER",
  TETRAPACK = "vendorMeasureUnit.TETRAPACK",
  ATADO = "vendorMeasureUnit.ATADO",
}

export enum commentArea {
  TITLE = "commentArea.TITLE",
  SHOW_ANSWER = "commentArea.SHOW_ANSWER",
  HIDE_ANSWER = "commentArea.HIDE_ANSWER",
  COMMENT_LABEL = "commentArea.COMMENT_LABEL",
  ANSWER_LABEL = "commentArea.ANSWER_LABEL",
}

export enum bulkLoadAction {
  CREATE = "bulkLoadAction.CREATE",
  MODIFY = "bulkLoadAction.MODIFY",
  NOTHING = "bulkLoadAction.NOTHING",
  NOTHING_BY_ERROR = "bulkLoadAction.NOTHING_BY_ERROR",
}

export enum bulkLoadModule {
  TABLE_INFO = "bulkLoadActionModule.TABLE_INFO",
  REQUIRED_COLUMNS = "bulkLoadActionModule.REQUIRED_COLUMS",
}

export enum bulkLoadError {
  VENDOR_CODE_NOT_FOUND = "bulkLoadError.VENDOR_CODE_NOT_FOUND",
  PRODUCT_NOT_FOUND = "bulkLoadError.PRODUCT_NOT_FOUND",
  KITCHEN_NOT_FOUND = "bulkLoadError.KITCHEN_NOT_FOUND",
  VENDOR_TYPE_NOT_FOUND = "bulkLoadError.VENDOR_TYPE_NOT_FOUND",
  ALL_FIELDS_MUST_BE_FILLED_TO_INSERT = "bulkLoadError.ALL_FIELDS_MUST_BE_FILLED_TO_INSERT",
  VENDOR_UNIT_NOT_FOUND = "bulkLoadError.VENDOR_UNIT_NOT_FOUND",
  UNIDENTIFIED = "bulkLoadError.UNIDENTIFIED",
}

export enum replacementModule {
  TITLE = "replacementModule.TITLE",
  DESCRIPTION = "replacementModule.DESCRIPTION",
  REPLACE = "replacementModule.REPLACE",
  QUANTITY_INITIAL = "replacementModule.QUANTITY_INITIAL",
  QUANTITY_FINAL = "replacementModule.QUANTITY_FINAL",
  AUTHORIZE_TITLE = "replacementModule.AUTHORIZE_TITLE",
  AUTHORIZE_DESCRIPTION = "replacementModule.AUTHORIZE_DESCRIPTION",
}

export enum general {
  ALL = "ALL",
}

export enum productionModifyReason {
  LABEL_SELECT = "productionModifyReason.LABEL_SELECT",
  PLACEHOLDER_SELECT = "productionModifyReason.PLACEHOLDER_SELECT",
  LACK_OF_SUPPLIES = "productionModifyReason.LACK_OF_SUPPLIES",
  YIELD = "productionModifyReason.YIELD",
  LAUNCH = "productionModifyReason.LAUNCH",
  TRAINING = "productionModifyReason.TRAINING",
  SUGGESTED_TOO_LOW = "productionModifyReason.SUGGESTED_TOO_LOW",
  PRODUCTION_ABOVE_PLAN = "productionModifyReason.PRODUCTION_ABOVE_PLAN",
  STOCKOUTS = "productionModifyReason.STOCKOUTS",
  SUGGESTED_TOO_HIGH = "productionModifyReason.SUGGESTED_TOO_HIGH",
  POOR_PLANNING = "productionModifyReason.POOR_PLANNING",
  LACK_OF_PERSONNEL = "productionModifyReason.LACK_OF_PERSONNEL",
  DAMAGED_EQUIPMENT = "productionModifyReason.DAMAGED_EQUIPMENT",
  WATER_RATIONING = "productionModifyReason.WATER_RATIONING",
  INVENTORY_EXPIRATION = "productionModifyReason.INVENTORY_EXPIRATION",
}

export enum supplyManagement {
  DRAWER_TITLE = "supplyManagement.DRAWER_TITLE",
  KITCHEN_STATUS = "supplyManagement.KITCHEN_STATUS",
  SELECT_CITY = "supplyManagement.SELECT_CITY",
  EMPTY_STATE_MSG = "supplyManagement.EMPTY_STATE_MSG",
  EMPTY_STATE_INST = "supplyManagement.EMPTY_STATE_INST",
  UPDATE_DIALOG_TITLE = "supplyManagement.UPDATE_DIALOG_TITLE",
  UPDATE_DIALOG_CONTENT = "supplyManagement.UPDATE_DIALOG_CONTENT",
  UPDATE_DIALOG_FOOTER = "supplyManagement.UPDATE_DIALOG_FOOTER",
  UPDATE_DIALOG_FOOTER_ENABLE = "supplyManagement.UPDATE_DIALOG_FOOTER_ENABLE",
  UPDATE_DIALOG_FOOTER_DISABLE = "supplyManagement.UPDATE_DIALOG_FOOTER_DISABLE",
}

export type Locales = {
  [key in
    | status
    | inventoryGroups
    | commons
    | measureUnits
    | adjustmentType
    | receiptType
    | EventReasonType
    | physicalCountModule
    | purchaseOrderModule
    | suppliesManagerModule
    | theoricalInventoryModule
    | adjustmentsModule
    | inventoryMovementType
    | kitchenInventoryManagement
    | productionManagement
    | dailyAdjustmentsModule
    | transferModule
    | transferPackingDialog
    | transferOrdersPackedReviewTable
    | countingTypeSelector
    | physicalCountSchedule
    | productionPreparation
    | purchaseOrderCreation
    | productionDialog
    | purchaseOrderLine
    | errorCode
    | massiveTransfer
    | dailyAdjustmentsReport
    | productionScannedError
    | productionLogReason
    | transferOrderModule
    | transferOrderDetails
    | takePhotoLocale
    | purchaseOrderConciliation
    | productionSchedule
    | supplyingManagement
    | supplyReception
    | missingReason
    | productionLiberation
    | organoleptics
    | bcIntegrations
    | vendor
    | vendorMeasureUnit
    | commentArea
    | bulkLoadAction
    | bulkLoadModule
    | bulkLoadError
    | replacementModule
    | inventoryMovement
    | general
    | productionModifyReason
    | supplyManagement]: string;
};
